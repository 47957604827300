import { editUser, getUsers } from "./user.action";

export { userReducer } from "./user.reducer";
export { watchUser } from "./user.saga";

export const userActions = {
  /** Fundraiser Actions */
  getUsers,
  editUser,
};
