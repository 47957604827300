import {
  DateRow,
  DrawerMain,
  MobileCardBody,
  MobileCardFooter,
  PriceRow,
  TextRow,
} from "components";
import { MobileCardHeadTitleName } from "../../../components/table/tableRows";
import * as React from "react";
import { DetailModal } from "./detailModal";
import { TransferStatusesRow } from "./transferStatusesRow";

export const ActionType = "RE_TRANSFER";

export const tabList = [
  { label: "Charities", value: "CHARITY" },
  { label: "Users", value: "USER" },
];

export const TransferStatus = {
  SUCCESS: "SUCCESS",
  NO_ACCOUNT: "NO_ACCOUNT",
  ACCOUNT_TRANSFER_ERROR: "ACCOUNT_TRANSFER_ERROR",
  BANK_TRANSFER_ERROR: "BANK_TRANSFER_ERROR",
};

export const TransferStatusError = {
  NO_ACCOUNT:
    "You don’t have a payment or a bank account connected. Please add an account to proceed with the transfer.",
  ACCOUNT_TRANSFER_ERROR:
    "Problem with the payment account information you provided. Please check the account details, make any necessary corrections, and try again.",
  BANK_TRANSFER_ERROR:
    "Problem with the bank account information you provided. Please check the account details, make any necessary corrections, and try again.",
};

export const transferStatuses = {
  CHARITY: "CHARITY",
  USER: "USER",
};

export const transfersHead = (width, ifAdmin, tab) => {
  if (width > 1279) {
    if (ifAdmin) {
      return [
        { title: "Date" },
        { title: tab === "CHARITY" ? "Charity Name" : "User Name" },
        { title: "Event Name" },
        { title: "Amount" },
        { title: "Stripe transfer ID", width: "170px" },
        { title: "Bank transfer ID", width: "170px" },
        { title: "Status" },
      ];
    } else {
      return [
        { title: "Date", width: "25%" },
        { title: "Event Name", width: "25%" },
        { title: "Amount", width: "25%" },
        { title: "Status", width: "25%" },
      ];
    }
  } else {
    return [
      { title: "Event Name", width: "33%" },
      { title: "Amount", width: "33%" },
      { title: "Status", width: "33%" },
    ];
  }
};

export const transfersBody = (width, ifAdmin, tab) => {
  if (width > 1279) {
    if (ifAdmin) {
      return [
        {
          rowText: (item) => {
            return <DateRow date={item?.createdAt || new Date()} />;
          },
        },
        {
          rowText: (item) => (
            <TextRow
              text={
                tab === "CHARITY" ? item?.charity?.name : item?.user?.username
              }
            />
          ),
        },
        {
          rowText: (item) => <TextRow text={item?.fundraiser?.name} />,
        },
        {
          rowText: (item) => <PriceRow info={item?.amount} />,
        },
        {
          rowText: (item) => (
            <TextRow text={item?.accountTransferId} textWidth={11} />
          ),
          width: "170px",
        },
        {
          rowText: (item) => (
            <TextRow text={item?.bankTransferId} textWidth={11} />
          ),
          width: "170px",
        },
        {
          rowText: (item) => <TransferStatusesRow item={item} />,
        },
      ];
    } else {
      return [
        {
          rowText: (item) => <DateRow date={item?.createdAt || new Date()} />,
          width: "25%",
        },

        {
          rowText: (item) => <TextRow text={item?.fundraiser?.name} />,
          width: "25%",
        },
        {
          rowText: (item) => <PriceRow info={item?.amount} />,
          width: "25%",
        },
        {
          rowText: (item) => <TransferStatusesRow item={item} />,
          width: "25%",
        },
      ];
    }
  } else {
    return [
      {
        rowText: (item) => <TextRow text={item?.fundraiser?.name} />,
        width: "33%",
      },
      {
        rowText: (item) => <PriceRow info={item?.amount} />,
        width: "33%",
      },
      {
        rowText: (item) => <TransferStatusesRow item={item} />,
        width: "33%",
      },
    ];
  }
};

export const transferAccordionDetails = (item, ifAdmin) => {
  return (
    <div>
      <p className="accordion-detail-title">Other Details</p>

      <div className="accordion-detail-box">
        <div className="accordion-detail-item">
          <span>Creation Date:</span>
          <p>
            <DateRow date={item?.createdAt} />
          </p>
        </div>

        {ifAdmin && (
          <div className="accordion-detail-item">
            <span>Stripe transfer ID:</span>
            <p>{item?.accountTransferId ? item?.accountTransferId : "-"}</p>
          </div>
        )}

        {ifAdmin && (
          <div className="accordion-detail-item">
            <span>Bank transfer ID:</span>
            <p>{item?.bankTransferId ? item?.bankTransferId : "-"}</p>
          </div>
        )}
      </div>

      {item?.status !== "PENDING" && item?.status !== "SUCCESS" && (
        <div
          className="drawer-detail-error-box"
          style={{ background: "transparent" }}
        >
          <p>
            {item?.status === "NO_ACCOUNT"
              ? "No Account"
              : "Error: Transfer to Connected Account Failed"}
          </p>
        </div>
      )}
    </div>
  );
};

export const GET_TRANSFERS = "GET_TRANSFERS";
export const CREATE_ACTION_TYPE = "CREATE_FUNDRAISER";
export const UPLOAD_FUNDRAISER_IMAGE = "UPLOAD_FUNDRAISER_IMAGE";

export const fundraiserCategories = [
  { title: "Birthday", id: "Birthday" },
  { title: "Holiday", id: "Holiday" },
  { title: "Wedding", id: "Wedding" },
  { title: "Graduation", id: "Graduation" },
  { title: "Other", id: "Other" },
];

export const transfersMobileCard = [
  {
    rowText: (item) => (
      <MobileCardHeadTitleName
        title="Event Name:"
        value={item?.fundraiser?.name}
      />
    ),
  },
  {
    rowText: (item) => (
      <MobileCardBody title="Date" value={<DateRow date={item?.createdAt} />} />
    ),
  },
  {
    rowText: (item) => (
      <MobileCardBody
        title="Charity Name"
        value={<TextRow text={item?.charity?.name} />}
      />
    ),
  },
  {
    rowText: (item) => (
      <MobileCardBody title="Amount" value={<PriceRow info={item?.amount} />} />
    ),
  },
  {
    rowText: (item) => (
      <MobileCardFooter
        title="Status"
        value={<TransferStatusesRow item={item} />}
      />
    ),
  },
  {
    rowText: (item) => (
      <DrawerMain item={item} anchor="bottom" ContentDrawer={DetailModal} />
    ),
    // <div onClick={() => fn("detail")} className="transfer-mobile-card-box">
    //   <p>Details</p>
    //   <Svg.ArrowDown />
    // </div>
    // ),
  },
];
