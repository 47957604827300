import {
  changeCharityStatus,
  createCharity,
  deleteCharity,
  deletePendingCharities,
  editCharity,
  getAllPendingCharities,
  getCharities,
  getCharityById,
  getPendingCharities,
} from "./charity.action";

export { charityReducer } from "./charity.reducer";
export { watchCharity } from "./charity.saga";

export const charityActions = {
  /** Fundraiser Actions */
  createCharity,
  editCharity,
  getCharities,
  getPendingCharities,
  getAllPendingCharities,
  deletePendingCharities,
  getCharityById,
  deleteCharity,
  changeCharityStatus,
};
