import {
  GET_ALL_PENDING_CHARITIES_SUCCESS,
  GET_CHARITIES_SUCCESS,
  GET_CHARITY_BY_ID_SUCCESS,
  GET_PENDING_CHARITIES_SUCCESS,
} from "./charity.types";

const initialState = {
  charities: null,
  pendingCharities: null,
  allPendingCharities: null,
  charityById: null,
};

export const charityReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CHARITIES_SUCCESS:
      return {
        ...state,
        charities: action.payload,
      };

    case GET_PENDING_CHARITIES_SUCCESS:
      return {
        ...state,
        pendingCharities: action.payload,
      };

    case GET_ALL_PENDING_CHARITIES_SUCCESS:
      return {
        ...state,
        allPendingCharities: action.payload,
      };

    case GET_CHARITY_BY_ID_SUCCESS:
      return {
        ...state,
        charityById: action.payload,
      };

    default:
      return state;
  }
};
