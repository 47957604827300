import React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { FindLoad, ProfileInfo, SaveParams } from "utils";
import { PaginationItem, PageLoader, TextRow, PriceRow } from "components";
import { Svg } from "assets";
import { useLocation, useNavigate } from "react-router";
import { NotYet } from "./notYet";
import { useSelector } from "react-redux";

export const FullTableAccordion = ({
  head,
  body,
  list,
  mobileCard,
  loadingType,
  handleClick,
  listCount,
}) => {
  const loader = FindLoad(loadingType ? loadingType : "");
  const location = useLocation();
  const navigator = useNavigate();
  const info = location?.state;
  const { ifAdmin } = ProfileInfo();

  const { fundraiserById } = useSelector((state) => ({
    fundraiserById: state.fundraiser.fundraiserById,
  }));

  const isMemory = fundraiserById?.type === "MEMORY";

  const renderItems = (i, item) => {
    if (item) {
      if (item?.button) {
        return item?.button((name) => handleClick(i, name), i);
      } else {
        return item?.rowText(i);
      }
    }
  };

  const handleSort = (current) => {
    const params = {
      ...info,
    };
    params.sortType = current;
    if (info?.orderType === "DESC") {
      params.orderType = "ASC";
    } else {
      params.orderType = "DESC";
    }
    SaveParams(location?.pathname, navigator, params);
  };

  const handleClickRow = (item, i) => {
    if (item?.notClickable !== true && handleClick) {
      handleClick(i);
    }
  };
  if (loader?.length) {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <PageLoader />
      </div>
    );
  }

  return (
    <div
      className={`full-table-accordion ${
        isMemory ? "full-table-accordion_memory" : ""
      }`}
    >
      <div className="full-table-accordion-wrapper">
        <div className="full-table-accordion-container">
          <div>
            <div
              className={`accordion-table-head ${
                isMemory ? "accordion-table-head_memory" : ""
              }`}
            >
              <div className="space-between">
                {head?.map((i, j) => (
                  <div
                    key={j}
                    className="accordion-table-cell-box"
                    style={{ width: i?.width }}
                  >
                    <p>{i?.title}</p>
                    {i?.sortType && (
                      <button
                        style={
                          info?.orderType && info?.sortType === i?.sortType
                            ? { opacity: 1 }
                            : { opacity: 0.5 }
                        }
                        onClick={() => handleSort(i.sortType)}
                      >
                        <Svg.SortSvg
                          className="table-cell_sort-svg"
                          style={
                            info?.sortType === i?.sortType &&
                            info?.orderType === "DESC"
                              ? { transform: "rotate(180deg)" }
                              : {}
                          }
                        />
                      </button>
                    )}
                  </div>
                ))}
              </div>
            </div>

            {list?.length ? (
              list?.map((i, j) => (
                <Accordion key={j}>
                  <AccordionSummary
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className={isMemory ? "accordion-summary_memory" : ""}
                  >
                    {body?.map((item, ke) => {
                      if (item) {
                        return (
                          <div
                            style={{ width: item?.width }}
                            className={`accordion-table-row ${
                              isMemory ? "accordion-table-row_memory" : ""
                            }`}
                            key={ke}
                            onClick={() => handleClickRow(item, i)}
                          >
                            {renderItems(i, item) || "Not Set"}
                          </div>
                        );
                      }
                    })}
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="charity-header-box">
                      <div>
                        <p>Charity Name</p>
                      </div>
                      <div>
                        <p> Net Amount</p>
                      </div>
                      <div>
                        <p> Service Fee</p>
                      </div>
                    </div>
                    {!!i?.charities.length && (
                      <>
                        {i?.charities?.map((item, ke) => (
                          <div className="charity-body-box" key={ke}>
                            <div className="charity-body-item">
                              <TextRow text={item?.charityName} />
                            </div>
                            <div className="charity-body-item">
                              <PriceRow info={+item?.netAmount} />
                            </div>
                            <div className="charity-body-item">
                              <PriceRow info={+item?.serviceFee} />
                            </div>
                          </div>
                        ))}
                        <div className="owner-part-info">
                          <p className="owner-part-info-title">Owner part</p>
                          <div className="owner-part-info-amount">
                            <div className="owner-part-info-amount-single">
                              <span>Net Amount:</span>
                              <PriceRow info={+i.keepAmount?.netAmount} />
                            </div>
                            {!ifAdmin && (
                              <div className="owner-part-info-amount-single">
                                <span>Service Fee: </span>
                                <PriceRow info={+i.keepAmount?.serviceFee} />
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </AccordionDetails>
                </Accordion>
              ))
            ) : (
              <NotYet height={"60vh"} isMemory={isMemory} />
            )}
          </div>
        </div>

        {listCount > 0 && (
          <PaginationItem isMemory={isMemory} count={listCount} list={list} />
        )}
      </div>
      <div className="table-wrapper-mobile">
        <div className="table-wrapper-mobile-wrapper">
          {list?.length ? (
            list?.map((i, j) => (
              <div
                className={`table-mobile-card ${
                  isMemory ? "table-mobile-card-memory" : ""
                }`}
                key={j}
              >
                {mobileCard?.map((item, ke) => {
                  if (item) {
                    return (
                      <div key={ke} onClick={() => handleClickRow(item, i)}>
                        {renderItems(i, item) || "Not Set"}
                      </div>
                    );
                  }
                })}
              </div>
            ))
          ) : (
            <div
              style={{
                height: "100%",
                width: "100%",
                position: "absolute",
              }}
            >
              <NotYet isMemory={isMemory} height={"60vh"} />
            </div>
          )}
        </div>
        {listCount > 0 && (
          <PaginationItem isMemory={isMemory} count={listCount} list={list} />
        )}
      </div>
    </div>
  );
};
