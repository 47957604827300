import React from "react";
import { useSelector } from "react-redux";
import { HeaderDrawerMain, PriceRow } from "components";
import { FundraiserDetailsCard } from "../fundraiserDetailsCard";
import { ProfileInfo } from "utils";

export const DetailDonationsModal = ({ item, closeDrawer }) => {
  const { fundraiserById } = useSelector((state) => ({
    fundraiserById: state.fundraiser.fundraiserById,
  }));

  const isMemory = fundraiserById?.type === "MEMORY";
  const { ifAdmin } = ProfileInfo();

  return (
    <div>
      <HeaderDrawerMain
        title="Transfer Details"
        isMemory={isMemory}
        closeModal={closeDrawer}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          padding: "16px",
          background: isMemory ? "#1C212D" : "",
        }}
      >
        {item?.charities?.map((i, k) => (
          <FundraiserDetailsCard
            isMemory={isMemory}
            headerColor={isMemory ? "#0D111D" : "#F4F4F4"}
            title={i?.charityName}
            list={[
              {
                name: "Net Amount",
                description: <PriceRow info={+i?.netAmount} />,
              },

              {
                name: "Service Fee",
                description: <PriceRow info={+i?.serviceFee} />,
              },
            ]}
            key={k}
          />
        ))}
        {ifAdmin ? (
          <FundraiserDetailsCard
            isMemory={isMemory}
            headerColor={isMemory ? "#FFF" : "#DBEDDB"}
            titleColor={isMemory ? "#1C212D" : "#1C212D"}
            borderColor={isMemory ? "#FFF" : "#E0E1E2"}
            title="Owner part"
            list={[
              {
                name: "Net Amount",
                description: <PriceRow info={+item?.keepAmount?.netAmount} />,
              },
            ]}
          />
        ) : (
          <FundraiserDetailsCard
            isMemory={isMemory}
            headerColor={isMemory ? "#FFF" : "#DBEDDB"}
            titleColor={isMemory ? "#1C212D" : "#1C212D"}
            borderColor={isMemory ? "#FFF" : "#E0E1E2"}
            title="Owner part"
            list={[
              {
                name: "Net Amount",
                description: <PriceRow info={+item?.keepAmount?.netAmount} />,
              },

              {
                name: "Service Fee",
                description: <PriceRow info={+item?.keepAmount?.serviceFee} />,
              },
            ]}
          />
        )}
      </div>
    </div>
  );
};
