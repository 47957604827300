import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { InputMain, ModalActions } from "components";
import { Svg } from "assets";
import {
  authActions,
  httpRequestsOnErrorsActions,
  httpRequestsOnSuccessActions,
} from "store";
import {
  ACCOUNT_ACTION_TYPE,
  PROFILE_ACTION_TYPE,
  settingsTabList,
} from "./constants";
import { FindError, FindSuccess, Notify, SaveParams } from "utils";
import { useLocation, useNavigate } from "react-router";

export const Account = () => {
  const dispatch = useDispatch();
  const [showPasswords, setShowPasswords] = useState({
    password: false,
    newPassword: false,
    confirmPassword: false,
  });
  const { handleSubmit, control, setError } = useForm({});
  const success = FindSuccess(ACCOUNT_ACTION_TYPE);
  const backError = FindError(ACCOUNT_ACTION_TYPE);
  const location = useLocation();
  const navigator = useNavigate();

  useEffect(() => {
    if (success) {
      dispatch(httpRequestsOnSuccessActions.removeSuccess(ACCOUNT_ACTION_TYPE));
      Notify("Profile Updated Successfully");
    }
  }, [success]);

  useEffect(() => {
    if (backError?.error === "User password does not match") {
      setError("password", {
        type: "required",
        message: "User password does not match",
      });
      dispatch(httpRequestsOnErrorsActions.removeError(PROFILE_ACTION_TYPE));
    }
  }, [backError]);

  const handleReset = () => {
    SaveParams(location?.pathname, navigator, {
      tab: settingsTabList[0]?.value,
    });
  };

  const onSubmit = (data) => {
    if (data?.newPassword === data?.confirmation) {
      dispatch(authActions.changePassword(data));
    } else {
      setError("confirmation", {
        type: "required",
        message: "New Password does not match with the confirmation",
      });
    }
  };

  const handleClickIcon = (name) => {
    const params = {
      ...showPasswords,
    };
    params[name] = !params[name];
    setShowPasswords(params);
  };

  return (
    <div className="profile-account-wrapper">
      <div className="profile-account-header-box">
        <p className="profile-account-header-title">Change Password</p>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="profile-account-body-box">
          <div className="profile-account-box">
            <InputMain
              name="password"
              type="password"
              showPassword={showPasswords.password}
              control={control}
              placeholder="Write Old Password"
              labelHeader="Current Password"
              rules={{
                required: "This Field is required",
              }}
              onClickIconEnd={() => handleClickIcon("password")}
              startAdornment={<Svg.Lock />}
            />
            <InputMain
              name="newPassword"
              type="password"
              showPassword={showPasswords.newPassword}
              control={control}
              placeholder="Write New Password"
              labelHeader="New Password"
              rules={{
                required: "This Field is required",
              }}
              onClickIconEnd={() => handleClickIcon("newPassword")}
              startAdornment={<Svg.Lock />}
            />
            <InputMain
              name="confirmation"
              type="password"
              showPassword={showPasswords.confirmPassword}
              control={control}
              placeholder="Confirm New Password"
              labelHeader="Confirm New Password"
              rules={{
                required: "This Field is required",
              }}
              onClickIconEnd={() => handleClickIcon("confirmPassword")}
              startAdornment={<Svg.Lock />}
            />
          </div>
        </div>

        <div className="profile-account-actions-box">
          <div className="profile-account-box">
            <ModalActions
              closeFunction={handleReset}
              defaultStyle={true}
              type="submit"
              loadAction={ACCOUNT_ACTION_TYPE}
              saveTitle="Change"
            />
          </div>
        </div>
      </form>
    </div>
  );
};
