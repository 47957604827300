import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { charityActions, fundraiserActions } from "store";
import { FindLoad } from "utils";
import {
  BackBtn,
  // Breadcrumb,
  PageLoader,
  SearchInput,
  SimpleTabs,
} from "components";
import {
  FUNDRAISER_BY_ID,
  // fundraiserDetailBreadcrumb,
  fundraiserDetailTabs,
  FundraiserDetailScreen,
  FundraiserDonation,
} from "./fragments";
import { fundraiserTabsEnum } from "./fragments/constants";

export const FundraiserDetail = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const loader = FindLoad(FUNDRAISER_BY_ID);
  const location = useLocation();
  const info = location?.state;
  const { fundraiserById } = useSelector((state) => ({
    fundraiserById: state.fundraiser.fundraiserById,
  }));

  const isMemory = fundraiserById?.type === "MEMORY";

  useEffect(() => {
    dispatch(fundraiserActions.getFundraiserById(params?.id));
    dispatch(charityActions.getCharities({ status: "ACTIVE" }));
  }, [dispatch]);

  if (loader) {
    return <PageLoader isMemory={isMemory} />;
  }

  return (
    <div
      className={`fundraiser-details-wrapper ${
        isMemory ? "fundraiser-details-memory" : ""
      }`}
      style={{ background: isMemory ? "#0D111D" : "" }}
    >
      <div className="fundraiser-details-page">
        {/*<Breadcrumb list={fundraiserDetailBreadcrumb} />*/}

        <BackBtn
          className="event-details-back-btn_memory"
          handleClick={() => navigate("/events")}
        />

        <div className="fundraiser-details-tab">
          <SimpleTabs
            className={isMemory ? "simple-tabs-memory" : ""}
            list={fundraiserDetailTabs}
            defaultTab={fundraiserTabsEnum.DETAILS}
          />
          {info?.tab === fundraiserTabsEnum.DONATION && (
            <SearchInput
              className="fundraiser-details-search-memory"
              placeholder={"Search by name..."}
              searchKey={"donorName"}
            />
          )}
        </div>

        {(!info?.tab || info?.tab === fundraiserTabsEnum.DETAILS) && (
          <FundraiserDetailScreen isMemory={isMemory} />
        )}
        {info?.tab === fundraiserTabsEnum.DONATION && (
          <FundraiserDonation isMemory={isMemory} />
        )}
      </div>
    </div>
  );
};
