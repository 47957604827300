import { Svg, Images } from "assets";

export const bannerCards = [
  {
    title: "Join an Event",
    buttonText: "Join",
    backColor: "#004080",
    link: "/find-event",
    items: [
      { text: "Support fundraisers from friends and family" },
      { text: "Choose from various reputable charities" },
      { text: "Let your gift become a legacy of giving" },
    ],
  },
  {
    title: "Create an Event",
    buttonText: "Create",
    backColor: "#34A853",
    link: "/start-event",
    items: [
      { text: "Set up fundraisers for any occasion" },
      { text: "Select charities for our list" },
      { text: "Invite guests to donate instead of giving gifts" },
    ],
  },
];

export const howItWorkTabs = [
  {
    title: "How Create Fundraiser",
    items: [
      {
        title: "Create a free account",
        Icon: Svg.CreateFreeSvg,
        description:
          "Sign up with your email and start your journey towards impactful giving.",
      },

      {
        title: "Create an Event",
        Icon: Svg.CreateEventSvg,
        description:
          "Provide details about your event and select charities to support.",
      },

      {
        title: "Share your Event link",
        Icon: Svg.ShareEventSvg,
        description:
          "Invite friends and family to contribute by sharing your event link.",
      },
    ],
  },

  {
    title: "How to Join an Event",

    items: [
      {
        title: "Find an Event",
        Icon: Svg.FindAnEventSvg,
        description: "Browse or search for the event you want to support.",
      },

      {
        title: "Select Charities",
        Icon: Svg.SelectsCharitiesSvg,
        description:
          "Choose which charities you’d like to support to from the event.",
      },

      {
        title: "Participate",
        Icon: Svg.ParticipateSvg,
        description: "It’s time to join an event and make your contribution.",
      },
    ],
  },
];

export const joinWithCards = [
  {
    title: "Secure Transactions",
    description:
      "We use advanced encryption and secure payment gateways to ensure your donations are safe and protected at all times.",
    Icon: Svg.LockSvg,
  },

  {
    title: "Trusted Charities",
    description:
      "All our partnered charities are thoroughly vetted for legitimacy and impact, ensuring your contributions go to reputable organizations.",
    Icon: Svg.TrustedSvg,
  },

  {
    title: "Transparent Process",
    description:
      "Track your donations and see exactly where your money goes. We provide real-time updates and detailed reports for every fundraiser.",
    Icon: Svg.EthicsSvg,
  },

  {
    title: "Privacy Protection",
    description:
      "Your personal information is safeguarded with the highest standards of privacy protection. We never share your data without your consent.",
    Icon: Svg.ShieldSvg,
  },
];

export const makingDifferenceInfo = [
  {
    title: "International Rescue Committee (IRC)",
    secondDescription:
      "Instead of traditional gifts, your contribution will make a lasting impact, supporting a cause close to our hearts. ",
    img: Images.joinImage,
  },

  {
    title: "International Rescue Committee (IRC)",
    secondDescription:
      "Instead of traditional gifts, your contribution will make a lasting impact, supporting a cause close to our hearts.",
    img: Images.joinImage,
  },
  {
    title: "International Rescue Committee (IRC)",
    secondDescription:
      "Instead of traditional gifts, your contribution will make a lasting impact, supporting a cause close to our hearts.",
    img: Images.joinImage,
  },
];
