import { useDispatch, useSelector } from "react-redux";
import {
  DateRow,
  DetailPageWrapper,
  EditBtn,
  PhoneRow,
  StatusPopper,
  TitleValue,
  Upload,
} from "components";
import { charityActions } from "store";
import { CHANGE_CHARITY_STATUS } from "./constants";

export const CharityInfo = ({ setEditMode }) => {
  const { charityById } = useSelector((state) => ({
    charityById: state.charity.charityById,
  }));
  const dispatch = useDispatch();

  const handleUpload = (item) => {
    const params = {
      ...charityById,
      mainImage: item?.mainImage,
    };
    params.imagesToAdd = [item?.file];
    dispatch(charityActions.editCharity(charityById?.id, params, "noLoad"));
  };

  const handleSetMain = (main) => {
    const params = {
      ...charityById,
    };
    params.mainImage = main;
    dispatch(charityActions.editCharity(charityById?.id, params, "noLoad"));
  };

  const handleDeleteImage = (item) => {
    const params = {
      ...charityById,
    };
    params.imagesToRemove = [item];
    dispatch(charityActions.editCharity(charityById?.id, params, "noLoad"));
  };

  const changeStatus = (status) => {
    const params = {
      status: status,
    };
    dispatch(charityActions.changeCharityStatus(charityById?.id, params));
  };

  return (
    <div className="fundraiser-detail-screen-wrapper">
      <DetailPageWrapper
        head={
          <>
            <p className="detail-page-title">Basic Information</p>
            <div className="fundraiser-detail-actions">
              <EditBtn handleClick={() => setEditMode(true)} />
              <div className="charity-status-box">
                <StatusPopper
                  status={charityById?.status}
                  handleSelect={changeStatus}
                  actionTye={CHANGE_CHARITY_STATUS}
                />
              </div>
            </div>
          </>
        }
        body={
          <div>
            <div>
              <TitleValue title="Charity Name" value={charityById?.name} />
              <TitleValue
                title="Creation date"
                value={<DateRow date={charityById?.createdAt} />}
              />
              <TitleValue
                title="Phone Number"
                value={<PhoneRow phone={charityById?.phoneNumber} />}
              />
              <TitleValue title="Email" value={charityById?.email} />
            </div>

            {charityById?.description && (
              <div className="fundraiser-detail-description">
                <p>Description</p>
                <span>{charityById?.description}</span>
              </div>
            )}

            <div className="charity-status-mobile-box">
              <StatusPopper
                status={charityById?.status}
                handleSelect={changeStatus}
                actionTye={CHANGE_CHARITY_STATUS}
              />
            </div>
          </div>
        }
      />

      <DetailPageWrapper
        body={
          <div>
            <p className="detail-page-title">Add Photo</p>
            <Upload
              handleSetMain={handleSetMain}
              handleChange={handleUpload}
              handleDelete={handleDeleteImage}
              backImages={charityById}
            />
          </div>
        }
      />
    </div>
  );
};
