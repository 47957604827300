import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { PaginationItem, PageLoader } from "components";
import { NotYet } from "./notYet";
import { FindLoad, ProfileInfo, SaveParams } from "utils";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Svg } from "assets";
import { useSelector } from "react-redux";

export const FullTableAccordionMain = ({
  classNameWrapper,
  head,
  body,
  list,
  accordionDetails,
  loadingType,
  handleClick,
  listCount,
}) => {
  const [expanded, setExpanded] = useState(null);
  const loader = FindLoad(loadingType ? loadingType : "");
  const location = useLocation();
  const navigator = useNavigate();
  const { ifAdmin } = ProfileInfo();
  const info = location?.state;

  const { fundraiserById } = useSelector((state) => ({
    fundraiserById: state.fundraiser.fundraiserById,
  }));

  const isMemory = fundraiserById?.type === "MEMORY";

  const handleOpening = (id) => {
    const checkId = expanded === id ? null : id;
    setExpanded(checkId);
  };

  const renderItems = (i, item) => {
    if (item) {
      if (item?.button) {
        return item?.button((name) => handleClick(i, name), i);
      } else {
        return item?.rowText(i);
      }
    }
  };

  const handleSort = (current) => {
    const params = {
      ...info,
    };
    params.sortType = current;
    if (info?.orderType === "DESC") {
      params.orderType = "ASC";
    } else {
      params.orderType = "DESC";
    }
    SaveParams(location?.pathname, navigator, params);
  };

  const handleClickRow = (item, i) => {
    if (item?.notClickable !== true && handleClick) {
      handleClick(i);
    }
  };
  if (loader?.length) {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <PageLoader />
      </div>
    );
  }

  const classesWrapper = ["full-table-accordion"];
  if (classNameWrapper) classesWrapper.push(classNameWrapper);

  return (
    <div className={classesWrapper.join(" ")}>
      <div className="full-table-accordion-wrapper">
        <div className="full-table-accordion-container">
          <div>
            <div className="accordion-table-head">
              <div className="space-between">
                {head?.map((i, j) => (
                  <div
                    key={j}
                    className="accordion-table-cell-box"
                    style={{ width: i?.width }}
                  >
                    <p>{i?.title}</p>
                    {i?.sortType && (
                      <button
                        style={
                          info?.orderType && info?.sortType === i?.sortType
                            ? { opacity: 1 }
                            : { opacity: 0.5 }
                        }
                        onClick={() => handleSort(i.sortType)}
                      >
                        <img
                          style={
                            info?.sortType === i?.sortType &&
                            info?.orderType === "DESC"
                              ? { transform: "rotate(180deg)" }
                              : {}
                          }
                          src={Svg.Sort}
                          alt="sort"
                        />
                      </button>
                    )}
                  </div>
                ))}
              </div>
            </div>

            {list?.length ? (
              list?.map((i, j) => (
                <Accordion
                  key={j}
                  expandIcon={<ExpandMoreIcon />}
                  expanded={expanded === i?.id}
                  onChange={() => handleOpening(i?.id)}
                >
                  <AccordionSummary
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    {body?.map((item, ke) => {
                      if (item) {
                        return (
                          <div
                            style={{ width: item?.width }}
                            className="accordion-table-row"
                            key={ke}
                            onClick={() => handleClickRow(item, i)}
                          >
                            {renderItems(i, item) || "Not Set"}
                          </div>
                        );
                      }
                    })}
                  </AccordionSummary>
                  <AccordionDetails>
                    {accordionDetails(i, ifAdmin)}
                  </AccordionDetails>
                </Accordion>
              ))
            ) : (
              <NotYet isMemory={isMemory} height={"60vh"} />
            )}
          </div>
        </div>

        {listCount > 0 && (
          <PaginationItem isMemory={isMemory} count={listCount} list={list} />
        )}
      </div>
    </div>
  );
};
