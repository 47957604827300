import React, { useState } from "react";
import { PriceRow } from "components";
import moment from "moment/moment";

export const ContributionCard = ({ item }) => {
  const [showMessages, setShowMessages] = useState(false);
  return (
    <div className="contribution-card-wrapper">
      <p className="contribution-card-name">{item?.donorName}</p>
      <div className="contribution-card-date-amount">
        <p className="contribution-card-date">
          {moment(item?.createdAt).format("MM/DD/YYYY")}
        </p>
        <p className="contribution-card-amount">
          <PriceRow info={item?.amount} />
        </p>
      </div>
      <button
        className="contribution-card-button"
        onClick={() => setShowMessages((prevState) => !prevState)}
      >
        {!showMessages ? "See Message" : "Hide Message"}
      </button>
      {showMessages && (
        <p className="contribution-card-messages">{item?.description}</p>
      )}
    </div>
  );
};
