import {
  DateRow,
  DrawerMain,
  MobileCardBody,
  PriceRow,
  TextRow,
} from "components";
import { MobileCardHeadTitleName } from "../../../components/table/tableRows";
import { DetailDonationsModal } from "./modals";

export const fundraiserTabsEnum = {
  DETAILS: "DETAILS",
  DONATION: "DONATION",
};

export const eventTypes = {
  CELEBRATION: "In Celebration",
  MEMORY: "In Memory of",
};
export const fundraiserDetailTabs = [
  { label: "Details", value: fundraiserTabsEnum.DETAILS },
  { label: "Donation", value: fundraiserTabsEnum.DONATION },
];

export const FUNDRAISER_BY_ID = "GET_FUNDRAISER_BY_ID";

export const fundraiserDetailBreadcrumb = [
  { title: "Events", to: "/events" },
  { title: "Events Details" },
];

export const COMPLETED_FUNDRAISER = "COMPLETED_FUNDRAISER";
export const EDIT_FUNDRAISER_ACTION = "EDIT_FUNDRAISER";
export const DELETE_FUNDRAISER_ACTION = "DELETE_FUNDRAISER";
export const DONATION_TABLE_ACTION_TYPE = "GET_DONATIONS";

export const fundraiserDonationHead = (width) => {
  return width > 1279
    ? [
        { title: "Donor Name", sortType: "donorName", width: "25%" },
        { title: "Date", sortType: "createdAt", width: "25%" },
        { title: "Email", sortType: "email", width: "25%" },
        { title: "Amount", sortType: "amount", width: "25%" },
      ]
    : [
        { title: "Donor Name", sortType: "donorName", width: "33%" },
        { title: "Date", sortType: "createdAt", width: "33%" },
        { title: "Amount", sortType: "amount", width: "33%" },
      ];
};

export const fundraiserDonationBody = (width) => {
  return width > 1279
    ? [
        {
          rowText: (item) => <TextRow text={item?.donorName} />,
          width: "25%",
        },
        {
          rowText: (item) => <DateRow date={item?.createdAt} />,
          width: "25%",
        },
        {
          rowText: (item) => <TextRow text={item?.email} />,
          width: "25%",
        },
        {
          rowText: (item) => <PriceRow info={item?.amount} />,
          width: "25%",
        },
      ]
    : [
        {
          rowText: (item) => <TextRow text={item?.donorName} />,
          width: "33%",
        },
        {
          rowText: (item) => <DateRow date={item?.createdAt} />,
          width: "33%",
        },
        {
          rowText: (item) => <PriceRow info={item?.amount} />,
          width: "33%",
        },
      ];
};

export const donationsMobileCard = [
  {
    rowText: (item) => <MobileCardHeadTitleName title={item?.donorName} />,
  },
  {
    rowText: (item) => (
      <MobileCardBody title="Date" value={<DateRow date={item?.createdAt} />} />
    ),
  },
  {
    rowText: (item) => (
      <MobileCardBody title="Email" value={<TextRow text={item?.email} />} />
    ),
  },
  {
    rowText: (item) => (
      <MobileCardBody title="Amount" value={<PriceRow info={item?.amount} />} />
    ),
  },
  {
    rowText: (item) => (
      <DrawerMain
        item={item}
        anchor="bottom"
        ContentDrawer={DetailDonationsModal}
      />
    ),
  },
];
