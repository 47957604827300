import {
  createDonations,
  editDonations,
  getDonationById,
  getDonations,
  getDonationsFundraiser,
} from "./donation.action";

export { donationReducer } from "./donation.reducer";
export { watchDonation } from "./donation.saga";

export const donationActions = {
  /** Donation Actions */
  createDonations,
  editDonations,
  getDonations,
  getDonationById,
  getDonationsFundraiser,
};
