import {
  signUp,
  logIn,
  logOut,
  getRecoveryLink,
  resetPassword,
  changePassword,
  getMyProfile,
  deleteAccount,
  editAccount,
  inviteUser,
  search,
} from "./auth.action";

export { authReducer } from "./auth.reducer";
export { watchAuth } from "./auth.saga";

export const authActions = {
  /** Auth Actions */
  signUp,
  logIn,
  logOut,
  getMyProfile,
  getRecoveryLink,
  resetPassword,
  changePassword,
  editAccount,
  deleteAccount,
  inviteUser,
  search,
};
