import { useLocation, useNavigate } from "react-router";
import { FindLoad, ProfileInfo, SaveParams } from "utils";
import { Svg } from "assets";
import { ButtonMain } from "components";

const ACTION_TYPE = "GET_MY_PROFILE";

export const Stepper = ({ loading, disabled, isCompleted = false }) => {
  const location = useLocation();
  const navigator = useNavigate();
  const { token } = ProfileInfo();
  const getProfileLoad = FindLoad(ACTION_TYPE);
  const info = location?.state;
  const checkFirstPage = info?.step !== 1 && info?.step;

  const handleBack = () => {
    let backStep = info?.step > 1 ? info?.step - 1 : info?.step;

    if (token && info?.step === 4) {
      backStep = 2;
    }

    // if (info?.step === 6 && myProfile?.pmtAccount?._id) {
    //   backStep = 4;
    // }
    //
    // if (info?.step === 7 && myProfile?.pmtAccount) {
    //   backStep = 4;
    // }
    //
    // if (info?.step === 7 && !myProfile?.pmtAccount && ifAdmin) {
    //   backStep = 4;
    // }

    const params = {
      ...info,
      step: backStep,
    };

    SaveParams(location?.pathname, navigator, params);
  };

  return (
    <div className="in-celebration-footer-wrapper">
      <div className="in-celebration-footer-box">
        <div className="in-celebration-footer-svg_step">
          <p className="in-celebration-footer-steps">
            Step {info?.step || 1}/5
          </p>
        </div>

        {info?.step !== 3 && (
          <div className="in-celebration-footer-buttons">
            {checkFirstPage && (
              <div className="in-celebration-footer-svg" onClick={handleBack}>
                <Svg.BeckArrow />
              </div>
            )}
            <ButtonMain
              load={loading}
              disabled={disabled || getProfileLoad}
              classNameWrapper="in-celebration-footer-button-confirm"
              text={info?.step === 7 || isCompleted ? "Completed" : "Next Step"}
              type="submit"
              endIcon={<Svg.ArrowRight />}
            />
          </div>
        )}
      </div>
    </div>
  );
};
