import {
  DateRow,
  ImageAndName,
  MobileCardBody,
  MobileCardFooter,
  MobileCardHead,
  PhoneRow,
  StatusPopper,
  TextRow,
} from "components";
import { Svg } from "assets";

export const USER_TABLE_ACTION_TYPE = "GET_USERS";

export const usersHead = (width) => {
  return [
    { title: "User Name", sortType: "username" },
    { title: "Creation Date", sortType: "createdAt" },
    width > 1279 && { title: "Phone Number" },
    { title: "Email", sortType: "email" },
    { title: "Status", sortType: "status", width: "100px" },
  ];
};

export const usersBody = (width) => {
  return [
    {
      rowText: (item) => (
        <ImageAndName
          text={item?.username}
          src={item?.avatar?.url ? item?.avatar?.url : null}
          noSrc={<Svg.NoUserImage />}
        />
      ),
    },
    {
      rowText: (item) => <DateRow date={item?.createdAt} />,
    },
    width > 1279 && {
      rowText: (item) => <PhoneRow phone={item?.phoneNumber} />,
    },
    {
      rowText: (item) => <TextRow text={item?.email} />,
    },
    {
      rowText: (item) => (
        <StatusPopper
          status={item?.isDeleted === false ? "ACTIVE" : "INACTIVE"}
        />
      ),
    },
  ];
};

export const userMobileCard = [
  {
    rowText: (item) => (
      <MobileCardHead
        src={item?.avatar?.url ? item?.avatar?.url : null}
        noSrc={<Svg.NoUserImage />}
        value={item?.username}
      />
    ),
  },
  {
    rowText: (item) => (
      <MobileCardBody
        title="Creation Date"
        value={<DateRow date={item?.createdAt} />}
      />
    ),
  },
  {
    rowText: (item) => (
      <MobileCardBody title="Email" value={<TextRow text={item?.email} />} />
    ),
  },
  {
    rowText: (item) => (
      <MobileCardBody
        title="Phone Number"
        value={<PhoneRow phone={item?.phoneNumber} />}
      />
    ),
  },
  {
    rowText: (item) => (
      <MobileCardFooter
        title="Status"
        value={
          <StatusPopper
            status={item?.isDeleted === false ? "ACTIVE" : "INACTIVE"}
          />
        }
      />
    ),
  },
];
