import { Svg } from "assets";

export const DeleteButton = ({ label, handleClick, disabled }) => {
  return (
    <button
      disabled={disabled}
      className="delete-btn-box"
      onClick={handleClick}
      style={disabled ? { opacity: 0.5, cursor: "auto" } : {}}
    >
      <Svg.RedTrash />
      <p>{label}</p>
    </button>
  );
};
