import React, { forwardRef } from "react";
import { Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const getNestedError = (obj, path) => {
  return path.split(".").reduce((acc, part) => acc && acc[part], obj);
};

export const DateInput = forwardRef(
  ({ label, rules, control, name, disabled, min, max, className }, ref) => {
    return (
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field, formState: { errors } }) => {
          const error = getNestedError(errors, name);

          // eslint-disable-next-line react/display-name
          const ExampleCustomInput = forwardRef(
            ({ onClick, className }, ref) => {
              return (
                <button
                  type="button"
                  className={className}
                  onClick={onClick}
                  ref={ref}
                >
                  {field?.value ? (
                    <p
                      style={{ color: "#000624" }}
                      className="date-input-value"
                    >
                      {moment(field?.value).format("MM/DD/YYYY")}
                    </p>
                  ) : (
                    <p
                      style={{ color: "#C4C4C4" }}
                      className="date-input-placeholder"
                    >
                      MM/DD/YYYY
                    </p>
                  )}
                </button>
              );
            }
          );

          return (
            <div
              className={`date-input-box ${className ? className : ""} ${
                error ? "date-input-box-error" : ""
              } `}
            >
              <div style={disabled ? { opacity: "0.8" } : {}}>
                <p
                  style={
                    disabled
                      ? { color: "rgba(0, 0, 0, 0.26)" }
                      : error
                      ? { color: "#D03325" }
                      : field?.value
                      ? { color: "#000624" }
                      : {}
                  }
                  className="date-input-title"
                >
                  {label && `${label}${rules?.required ? "*" : ""}`}
                </p>
                <DatePicker
                  customInputRef={ref}
                  showYearDropdown
                  className={`date-picker-box ${
                    disabled
                      ? "date-picker-disabled"
                      : error
                      ? "date-picker-error"
                      : ""
                  }`}
                  isClearable={!!field?.value}
                  showIcon
                  disabled={disabled}
                  onChange={(date) => {
                    if (date) {
                      const converted = moment(date);
                      converted.hour(10);
                      const momentConverted = moment(converted).format(
                        "YYYY-MM-DD"
                      );
                      field.onChange(momentConverted);
                    } else {
                      field.onChange(null);
                    }
                  }}
                  selected={field?.value}
                  customInput={
                    <ExampleCustomInput
                      className="example-custom-input"
                      value={field?.value}
                    />
                  }
                  minDate={min}
                  maxDate={max}
                />
                <p className="error-message">{error?.message}</p>
              </div>
            </div>
          );
        }}
      />
    );
  }
);
DateInput.displayName = "DateInput";
