import axios from "axios";

export const bankAccountService = {
  connectNonProfitService: (body) =>
    axios.post("/payments/connect", body, { auth: true }),

  connectBankAccountService: (body, id) =>
    axios.post(`/payments/connect/bank/${id}`, body, { auth: true }),

  getMyBankService: (id) =>
    axios.get(`/payments/connect/account/${id}`, { auth: true }),

  deleteConnectStripeService: (id) =>
    axios.delete(`/payments/connect/account/${id}`, { auth: true }),

  updateConnectBankService: (id, body) =>
    axios.post(`/payments/connect/bank/update/${id}`, body, { auth: true }),
};
