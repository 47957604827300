import React from "react";
import { Svg } from "assets";

export const HeaderDrawerMain = ({ closeModal, title, isMemory }) => {
  return (
    <div
      className="header-drawer-main-wrapper"
      style={{
        background: isMemory ? "#1C212D" : "",
        borderBottom: isMemory ? "1px solid #555768" : "",
      }}
    >
      <p style={{ color: isMemory ? "#E0E1E2" : "" }}> {title}</p>
      <button onClick={closeModal}>
        <Svg.Close
          className={`${isMemory ? "header-drawer-close_svg-memory" : ""}`}
        />
      </button>
    </div>
  );
};
