import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { userActions } from "store";
import { TitleAndAction, SearchInput, FullTable } from "components";
import {
  USER_TABLE_ACTION_TYPE,
  usersBody,
  usersHead,
  userMobileCard,
} from "./fragments";
import { useWindowDimensions } from "utils";

export const Users = () => {
  const { users } = useSelector((state) => ({
    users: state.user.users,
  }));
  const dispatch = useDispatch();
  const location = useLocation();
  const info = location?.state;
  const { width } = useWindowDimensions();

  useEffect(() => {
    const params = {
      ...info,
      limit: 10,
    };
    params.skip = info?.skip ? info?.skip : 0;
    delete params.page;
    delete params.tab;

    dispatch(userActions.getUsers(params));
  }, [dispatch, info]);

  return (
    <div className="users-page">
      <TitleAndAction
        title="Users"
        customComponent={
          <div className="search-box-tablet">
            <SearchInput
              placeholder={"Search by name..."}
              searchKey={"username"}
            />
          </div>
        }
      />
      <div className="search-box-mobile">
        <SearchInput placeholder={"Search by name..."} searchKey={"username"} />
      </div>

      <FullTable
        head={usersHead(width)}
        body={usersBody(width)}
        mobileCard={userMobileCard}
        loadingType={USER_TABLE_ACTION_TYPE}
        list={users?.users}
        listCount={users?.count}
      />
    </div>
  );
};
