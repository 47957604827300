import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { ButtonMain, InputMain } from "components";
import { EmailValidator, FindError, FindSuccess, useModal } from "utils";
import {
  authActions,
  httpRequestsOnErrorsActions,
  httpRequestsOnSuccessActions,
} from "store";
import { ForgotPassword } from "./forgotPassword";
import { Svg } from "assets";
import { SignUp } from "./signUp";
import { AuthModalHeaders } from "./fragments";

const REQUEST_TYPE = "LOG_IN";

export const LogIn = ({
  classNameWrapper,
  classNameContent,
  classNameInput,
  classNameButton,
  onCreateAccount,
  icon,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const { openModal, close } = useModal();
  const backError = FindError(REQUEST_TYPE);
  const successLogin = FindSuccess(REQUEST_TYPE);

  const { handleSubmit, control, setError } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  useEffect(() => {
    if (successLogin) {
      close();
      dispatch(httpRequestsOnSuccessActions.removeSuccess(REQUEST_TYPE));
    }
  }, [successLogin]);

  useEffect(() => {
    if (backError?.type === REQUEST_TYPE) {
      if (backError?.error === "Such user does not exist in our records") {
        setError("email", {
          type: "required",
          message: "Such user does not exist in our records",
        });
      }
      if (
        backError?.error === "User password does not match" ||
        backError?.error === "user password does not match"
      ) {
        setError("password", {
          type: "required",
          message: "User password does not match",
        });
      }
      dispatch(httpRequestsOnErrorsActions.removeError(REQUEST_TYPE));
    }
  }, [dispatch, backError]);

  const onSubmit = (data) => {
    dispatch(authActions.logIn(data));
  };

  const handleClickIcon = () => {
    setShowPassword((prev) => !prev);
  };

  const openForgotModal = () => {
    openModal(<ForgotPassword />);
  };

  return (
    <div className={`auth-modals ${classNameWrapper ? classNameWrapper : ""}`}>
      <AuthModalHeaders title={"Log In"} icon={icon || <Svg.SignIn />} />
      <form
        className={`form-box ${classNameContent ? classNameContent : ""}`}
        onSubmit={handleSubmit(onSubmit)}
      >
        <InputMain
          className={classNameInput ? classNameInput : ""}
          name="email"
          type="email"
          control={control}
          placeholder="Email"
          labelHeader="Email"
          rules={{
            required: "This Field is required",
            pattern: {
              value: EmailValidator,
              message: "Must be valid email",
            },
          }}
          startAdornment={<Svg.Sms />}
        />
        <InputMain
          className={classNameInput ? classNameInput : ""}
          name="password"
          type="password"
          showPassword={showPassword}
          control={control}
          placeholder="Password"
          labelHeader="Password"
          rules={{
            required: "This Field is required",
          }}
          onClickIconEnd={handleClickIcon}
          startAdornment={<Svg.Lock />}
        />
        <ButtonMain
          actionTye={REQUEST_TYPE}
          text="Log In"
          type="submit"
          classNameWrapper={classNameButton ? classNameButton : ""}
        />
        <div className="reset-or-log" style={{ margin: "24px 0 8px 0" }}>
          <p> Forgot Password?</p>
          <button onClick={openForgotModal} className="forgot-btn">
            Reset Your Password
          </button>
        </div>

        <div className="reset-or-log">
          <p> Don’t have an account</p>
          <button
            onClick={() =>
              onCreateAccount ? onCreateAccount() : openModal(<SignUp />)
            }
          >
            Create Account
          </button>
        </div>
      </form>
    </div>
  );
};
