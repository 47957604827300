import Button from "@mui/material/Button";
import { useLocation, useNavigate } from "react-router";
import { SaveParams } from "utils";

export const SimpleTabs = ({ list, defaultTab, className }) => {
  const location = useLocation();
  const navigator = useNavigate();
  const info = location?.state;
  const tab = info?.tab ? info?.tab : defaultTab;
  const changeTab = (selectedTab) => {
    const params = {
      ...info,
    };
    params["tab"] = selectedTab;
    params.skip = 0;
    params.page = 1;
    SaveParams(location?.pathname, navigator, params);
  };

  return (
    <div className={`stepper-wrapper ${className ? className : ""}`}>
      {list?.map((i, j) => (
        <Button
          key={j}
          onClick={() => changeTab(i?.value)}
          className={tab === i?.value ? "active-tab" : "passive-tab"}
        >
          {i?.label}
          {i?.count && (
            <div
              className={`count-box ${tab === i?.value ? "active" : "passive"}`}
            >
              {i?.count}
            </div>
          )}
        </Button>
      ))}
    </div>
  );
};
