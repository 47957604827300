import React from "react";
import { Controller } from "react-hook-form";
import { FormControlLabel, Radio } from "@mui/material";

export const RadioMain = ({
  control,
  name,
  rules,
  label,
  value,
  styles,
  onClick,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field }) => {
        return (
          <FormControlLabel
            {...field}
            control={
              <Radio
                onClick={onClick}
                checked={field.value === value}
                sx={{
                  color: styles?.color, // Default color
                  "&.Mui-checked": {
                    color: styles?.checkedColor, // Color when checked
                  },
                }}
              />
            }
            label={label}
            value={value}
          />
        );
      }}
    />
  );
};
