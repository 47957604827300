import { ButtonMain } from "../buttons";

export const TitleAndAction = ({
  title,
  btnText,
  startIcon,
  handleClick,
  customComponent,
  mobileBtnText,
}) => {
  return (
    <div className="title-and-action-box">
      <div className="title-and-action">
        <h1 className="page-global-title">{title}</h1>
        {btnText && (
          <ButtonMain
            onClick={handleClick}
            text={btnText}
            startIcon={startIcon}
            mobileBtnText={mobileBtnText}
          />
        )}
        {customComponent}
      </div>
      <hr />
    </div>
  );
};
