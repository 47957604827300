export const Transform = () => {
  return (
    <div className="transform-wrapper">
      <div className="transform-section">
        <div className="transform-section-box">
          <p className="transform-title">Transform Gifts into Blessings</p>
          <p className="transform-subtitle">
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            Whether it's a birthday, wedding, or honoring a loved one's memory,
            invite your guests to donate to a cause close to your heart instead
            of traditional gifts or flowers. Join our community of givers and
            celebrate with purpose.
          </p>
        </div>
      </div>
    </div>
  );
};
