import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { ButtonMain, CancelBtn, InputMain } from "components";
import {
  bankAccountActions,
  httpRequestsOnErrorsActions,
  httpRequestsOnSuccessActions,
} from "store";
import { CONNECT_BANK, UPDATE_CONNECT_BANK } from "./constants";
import { FindError, FindSuccess, useModal } from "utils";

export const BankAccount = ({ info }) => {
  const { handleSubmit, control, reset, setError, clearErrors } = useForm({});
  const dispatch = useDispatch();
  const { close } = useModal();
  const ActionType = info ? UPDATE_CONNECT_BANK : CONNECT_BANK;
  const findError = FindError(ActionType);
  const findSuccess = FindSuccess(ActionType);

  const { charityById } = useSelector((state) => ({
    charityById: state.charity.charityById,
  }));
  useEffect(() => {
    if (info) {
      reset(info);
    }
  }, []);

  useEffect(() => {
    if (findError?.error) {
      if (
        findError?.error ===
        "You must use a test bank account number in test mode. Try 000123456789 or see more options at https://stripe.com/docs/connect/testing#account-numbers."
      ) {
        setError("accountNumber", {
          message: findError?.error,
        });
      }

      if (findError?.error === "Invalid routing number") {
        setError("routingNumber", {
          message: findError?.error,
        });
      }
    }
  }, [findError]);

  useEffect(() => {
    if (findSuccess) {
      close();
      dispatch(httpRequestsOnSuccessActions.removeSuccess(ActionType));
    }
  }, [findSuccess]);

  useEffect(() => {
    return () => {
      dispatch(httpRequestsOnErrorsActions.removeError(ActionType));
      clearErrors();
    };
  }, []);

  const onSubmit = (data) => {
    if (info) {
      dispatch(
        bankAccountActions.updateConnectBank(charityById?.pmtAccount?._id, data)
      );
    } else {
      dispatch(
        bankAccountActions.connectBankAccount(
          data,
          charityById?.pmtAccount?._id
        )
      );
    }
  };

  const handleClose = () => {
    close();
  };

  return (
    <div className="settings-connect-modal-wrapper">
      {info && (
        <div className="settings-connect-modal-header">
          <h2 className="settings-connect-modal-title">
            Connect Your Bank Account{" "}
          </h2>
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="settings-connect-modal-body">
          <InputMain
            name="accountNumber"
            type="number"
            control={control}
            placeholder="Enter your bank account number"
            labelHeader="Bank Account Number"
            maxLength={17}
            rules={{
              required: "This Field is required",
              minLength: {
                value: 8,
                message:
                  "Bank Account Number must be minimum 8 characters long",
              },
              maxLength: {
                value: 17,
                message:
                  "SSN last 4 numbers must be maximum 17 characters long",
              },
            }}
          />
          <InputMain
            name="routingNumber"
            type="number"
            control={control}
            placeholder="Provide your bank's routing number"
            labelHeader="Bank Routing Number"
            maxLength={9}
            rules={{
              required: "This Field is required",
              minLength: {
                value: 9,
                message: "Bank Routing numbers must be 9 characters long",
              },
              maxLength: {
                value: 9,
                message: "Bank Routing numbers must be 9 characters long",
              },
            }}
          />
        </div>
        <div className="settings-connect-modal-footer">
          <CancelBtn text={"Cancel"} closeFunction={handleClose} />
          <ButtonMain
            actionTye={ActionType}
            type={"submit"}
            text={info ? "Edit" : "Next"}
          />
        </div>
      </form>
    </div>
  );
};
