import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import { DrawerOpening } from "./drawerOpening";

export const DrawerMain = ({
  OpeningComponent,
  ContentDrawer,
  anchor,
  item,
}) => {
  const [state, setState] = useState({
    [anchor]: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <>
      {OpeningComponent ? (
        <OpeningComponent
          anchor={anchor}
          openDrawer={toggleDrawer(anchor, true)}
          closeDrawer={toggleDrawer(anchor, false)}
        />
      ) : (
        <DrawerOpening title="Details" onClick={toggleDrawer(anchor, true)} />
      )}

      <Drawer
        anchor={anchor}
        open={state[anchor]}
        onClose={toggleDrawer(anchor, false)}
      >
        <ContentDrawer
          anchor={anchor}
          item={item}
          openDrawer={toggleDrawer(anchor, true)}
          closeDrawer={toggleDrawer(anchor, false)}
        />
      </Drawer>
    </>
  );
};
