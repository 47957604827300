import React from "react";
import { Svg } from "assets";
import { useModal } from "utils";

export const CloseBtn = () => {
  const { close } = useModal();

  return (
    <button type="button" className="close-modal-btn" onClick={() => close()}>
      <Svg.Close />
    </button>
  );
};
