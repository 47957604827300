/** Types */

export const CREATE_CHARITY = "CREATE_CHARITY";
export const EDIT_CHARITY = "EDIT_CHARITY";
export const GET_CHARITIES = "GET_CHARITIES";
export const GET_CHARITIES_SUCCESS = "GET_CHARITIES_SUCCESS";
export const DELETE_PENDING_CHARITIES = "DELETE_PENDING_CHARITIES";
export const GET_PENDING_CHARITIES = "GET_PENDING_CHARITIES";
export const GET_PENDING_CHARITIES_SUCCESS = "GET_PENDING_CHARITIES_SUCCESS";
export const GET_ALL_PENDING_CHARITIES = "GET_ALL_PENDING_CHARITIES";
export const GET_ALL_PENDING_CHARITIES_SUCCESS =
  "GET_ALL_PENDING_CHARITIES_SUCCESS";
export const GET_CHARITY_BY_ID = "GET_CHARITY_BY_ID";
export const GET_CHARITY_BY_ID_SUCCESS = "GET_CHARITY_BY_ID_SUCCESS";
export const DELETE_CHARITY = "DELETE_CHARITY";
export const CHANGE_CHARITY_STATUS = "CHANGE_CHARITY_STATUS";
