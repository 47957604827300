import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { ButtonMain, CancelBtn, InputMain } from "components";
import {
  bankAccountActions,
  httpRequestsOnErrorsActions,
  httpRequestsOnSuccessActions,
} from "store";
import { CONNECT_BANK, UPDATE_CONNECT_BANK } from "../constants";
import { FindError, FindSuccess, ProfileInfo, useModal } from "utils";

export const BankAccountUser = ({ info }) => {
  const { handleSubmit, control, reset, setError, clearErrors } = useForm({});
  const dispatch = useDispatch();
  const { myProfile } = ProfileInfo();
  const { close } = useModal();
  const ActionType = info ? UPDATE_CONNECT_BANK : CONNECT_BANK;
  const findError = FindError(ActionType);
  const findSuccess = FindSuccess(ActionType);

  useEffect(() => {
    if (findError?.error) {
      if (
        findError?.error ===
        "You must use a test bank account number in test mode. Try 000123456789 or see more options at https://stripe.com/docs/connect/testing#account-numbers."
      ) {
        setError("accountNumber", {
          message: findError?.error,
        });
      }

      if (findError?.error === "Invalid routing number") {
        setError("routingNumber", {
          message: findError?.error,
        });
      }
    }
  }, [findError]);

  useEffect(() => {
    if (info) {
      reset(info);
    }
  }, []);

  useEffect(() => {
    if (findSuccess) {
      close();
      dispatch(httpRequestsOnSuccessActions.removeSuccess(ActionType));
    }
  }, [findSuccess]);

  useEffect(() => {
    return () => {
      dispatch(httpRequestsOnErrorsActions.removeError(ActionType));
      clearErrors();
    };
  }, []);

  const onSubmit = (data) => {
    if (info) {
      dispatch(
        bankAccountActions.updateConnectBank(myProfile?.pmtAccount?._id, data)
      );
    } else {
      dispatch(
        bankAccountActions.connectBankAccount(data, myProfile?.pmtAccount?._id)
      );
    }
  };

  return (
    <div className="settings-connecting-bank-wrapper settings-personal-detail">
      <div className="settings-connecting-bank-header">
        <p>Connect Your Bank Account </p>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="settings-connecting-bank-body">
          <InputMain
            name="accountNumber"
            type="number"
            control={control}
            placeholder="Enter your bank account number"
            labelHeader="Bank Account Number"
            rules={{
              required: "Bank account number  is required",
              minLength: {
                value: 8,
                message:
                  "Bank Account Number must be minimum 8 characters long",
              },
              maxLength: {
                value: 17,
                message:
                  "SSN last 4 numbers must be maximum 17 characters long",
              },
            }}
          />
          <InputMain
            name="routingNumber"
            type="number"
            control={control}
            placeholder="Provide your bank's routing number"
            labelHeader="Bank Routing Number"
            maxLength={9}
            rules={{
              required: "Bank routing number is required",
              minLength: {
                value: 9,
                message: "Bank Routing numbers must be 9 characters long",
              },
              maxLength: {
                value: 9,
                message: "Bank Routing numbers must be 9 characters long",
              },
            }}
          />
        </div>
        <div className="settings-connecting-bank-buttons">
          <CancelBtn
            text={"Cancel"}
            closeFunction={() => {
              if (!info) {
                dispatch(
                  bankAccountActions.getMyBank(myProfile?.pmtAccount?._id)
                );
              }

              close();
            }}
          />
          <ButtonMain
            actionTye={ActionType}
            type={"submit"}
            text={info ? "Edit" : "Connect"}
          />
        </div>
      </form>
    </div>
  );
};
