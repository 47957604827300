import React from "react";
import { useModal } from "utils";

export const CancelBtn = ({ text, closeFunction }) => {
  const { close } = useModal();

  const handleClose = () => {
    if (closeFunction) {
      closeFunction();
    } else {
      close();
    }
  };

  return (
    <button onClick={handleClose} className="close-btn" type={"button"}>
      <span>{text ? text : "Cancel"}</span>
    </button>
  );
};
