import { EDIT_USER, GET_USERS } from "./user.types";

export const getUsers = (params) => {
  return {
    type: GET_USERS,
    payload: { params },
  };
};

export const editUser = (id, body) => {
  return {
    type: EDIT_USER,
    payload: { id, body },
  };
};
