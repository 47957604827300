import { call, put, takeLatest } from "redux-saga/effects";
import {
  CONNECT_BANK_ACCOUNT,
  CONNECT_NON_PROFIT,
  DELETE_CONNECT_STRIPE,
  GET_MY_BANK,
  GET_MY_BANK_SUCCESS,
  UPDATE_CONNECT_BANK,
} from "./bankAccount.types";
import { httpRequestsOnErrorsActions } from "../http_requests_on_errors";
import { httpRequestsOnLoadActions } from "../http_requests_on_load";
import { httpRequestsOnSuccessActions } from "../http_requests_on_success";
import { bankAccountService } from "./bankAccount.service";

function* connectNonProfit({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    yield call(bankAccountService.connectNonProfitService, payload?.body);
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(
      httpRequestsOnErrorsActions.appendError(type, err?.data?.message)
    );
  } finally {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* connectBankAccount({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    yield call(
      bankAccountService.connectBankAccountService,
      payload?.body,
      payload?.charity
    );

    yield put({
      type: GET_MY_BANK,
      payload: {
        id: payload?.charity,
      },
    });
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(
      httpRequestsOnErrorsActions.appendError(type, err?.data?.message)
    );
  } finally {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* getMyBank({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    const res = yield call(bankAccountService.getMyBankService, payload?.id);

    yield put({
      type: GET_MY_BANK_SUCCESS,
      payload: res?.data,
    });

    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put({
      type: GET_MY_BANK_SUCCESS,
      payload: [],
    });
    yield put(
      httpRequestsOnErrorsActions.appendError(type, err?.data?.message)
    );
  } finally {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* deleteConnectStripe({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    yield call(bankAccountService.deleteConnectStripeService, payload?.id);

    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
  } catch (err) {
    yield put(
      httpRequestsOnErrorsActions.appendError(type, err?.data?.message)
    );
  } finally {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* updateConnectBank({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    yield call(
      bankAccountService.updateConnectBankService,
      payload?.id,
      payload?.body
    );

    yield put({
      type: GET_MY_BANK,
      payload: {
        id: payload?.id,
      },
    });

    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
  } catch (err) {
    yield put(
      httpRequestsOnErrorsActions.appendError(type, err?.data?.message)
    );
  } finally {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

export const watchBankAccount = function* () {
  yield takeLatest(CONNECT_NON_PROFIT, connectNonProfit);
  yield takeLatest(CONNECT_BANK_ACCOUNT, connectBankAccount);
  yield takeLatest(GET_MY_BANK, getMyBank);
  yield takeLatest(DELETE_CONNECT_STRIPE, deleteConnectStripe);
  yield takeLatest(UPDATE_CONNECT_BANK, updateConnectBank);
};
