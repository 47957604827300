import React from "react";
import { Svg } from "assets";
import { ButtonMain } from "components";

export const NoConnectedBank = ({ onClick }) => {
  return (
    <div className="charity-bank-account">
      <div className="connect-bank-account">
        <Svg.Bank />
        <div className="connect-bank-account-info">
          <p>Connect Bank Account</p>
          <span>Select your account type and provide relevant details.</span>
        </div>
        <ButtonMain
          onClick={onClick}
          startIcon={<Svg.AddWhiteCircle />}
          text="Connect Bank Account"
          type="submit"
        />
      </div>
    </div>
  );
};
