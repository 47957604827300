import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useLocation, useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { ButtonMain } from "components";
import { LogIn } from "fragments";
import { LoggedUser } from "./fragments/loggedUser";
import { ProfileInfo, useModal, useWindowSize } from "utils";
import { authActions, fundraiserActions } from "store";
import { Svg } from "assets";

export const Layout = ({ isMemory }) => {
  const [isScrolled, setScrolled] = useState(false);

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { openModal } = useModal();
  const navigate = useNavigate();
  const { myProfile, token } = ProfileInfo();
  const [width] = useWindowSize();

  let headerColorBack = "";
  let headerItemsColor = "";

  const checkHeader = ["/start-event", "/in-memory-of", "/in-celebration"];
  const dontShowLogedInfo = ["/in-memory-of", "/in-celebration"];

  if (!isScrolled) {
    if (
      (location?.pathname === "/in-celebration" ||
        location?.pathname === "/in-memory-of") &&
      width < 1289
    ) {
      headerColorBack = "#FFF";
      headerItemsColor = "#000624";
    } else if (location?.pathname === "/in-memory-of" || isMemory) {
      headerColorBack = "transparent";
      headerItemsColor = "#E0E1E2";
    } else if (location?.pathname === "/in-celebration") {
      headerColorBack = "rgba(0, 0, 0, 0.15)";
      headerItemsColor = "#E0E1E2";
    } else if (location?.pathname === "/") {
      headerColorBack = "transparent";
      headerItemsColor = "#E0E1E2";
    } else {
      headerColorBack =
        "linear-gradient(0deg, rgba(244, 244, 244, 0.20) 0%, rgba(244, 244, 244, 0.20) 100%), #FFF";
      headerItemsColor = "#1C212D";
    }
  } else {
    if (location?.pathname === "/in-memory-of" || isMemory) {
      headerColorBack = "#1C212D";
      headerItemsColor = "#E0E1E2";
    } else {
      headerColorBack = "#ffffff";
      headerItemsColor = "#1C212D";
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (token) {
      dispatch(authActions.getMyProfile());
    }
  }, [dispatch]);
  const openSignIn = () => {
    openModal(<LogIn />);
  };

  const handleScroll = () => {
    if (window.scrollY >= 10) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const resetFundraiserById = () => {
    dispatch(fundraiserActions.resetFundraiserById());
    window.scroll(0, 0);
  };

  return (
    <div
      style={{
        background: headerColorBack,
        borderBottom: location?.pathname === "/" ? "1px solid #E0E1E2" : "",
      }}
      className="layout-wrapper "
    >
      <div className="layout-box">
        <div className="align-center">
          <div className="align-center">
            <div className="layout-logo-box">
              <NavLink to={"/"} onClick={resetFundraiserById}>
                <h1
                  style={{
                    color: headerItemsColor,
                  }}
                >
                  MYF
                </h1>
              </NavLink>
            </div>

            <button
              className="search-button"
              onClick={() => {
                navigate("/find-event");
                resetFundraiserById();
              }}
            >
              <Svg.SearchSvg
                className="search-button-svg"
                fill={headerItemsColor}
              />
              <p
                className="search-button-text"
                style={{
                  color: headerItemsColor,
                }}
              >
                Search
              </p>
            </button>
          </div>
        </div>
        {!dontShowLogedInfo.includes(location.pathname) && (
          <>
            {token && myProfile ? (
              <LoggedUser
                headerItemsColor={headerItemsColor}
                isMemory={isMemory}
              />
            ) : !checkHeader.includes(location.pathname) ? (
              <div className="layout-actions-box">
                <button className="layout-login-btn" onClick={openSignIn}>
                  <p style={{ color: headerItemsColor }}>Log In</p>
                </button>
                <ButtonMain
                  wrapperStyles={{ alignItems: "flex-start" }}
                  classNameWrapper={isMemory ? "start_memoryBtn" : ""}
                  onClick={() => navigate("/start-event")}
                  text="Start an Event"
                  endIcon={
                    isMemory ? <Svg.ArrowRightBlack /> : <Svg.ArrowRight />
                  }
                />
              </div>
            ) : (
              <div />
            )}
          </>
        )}
      </div>
    </div>
  );
};
