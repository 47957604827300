/** Types */
export const CREATE_FUNDRAISER = "CREATE_FUNDRAISER";
export const EDIT_FUNDRAISER = "EDIT_FUNDRAISER";
export const GET_FUNDRAISERS = "GET_FUNDRAISERS";
export const GET_FUNDRAISERS_SUCCESS = "GET_FUNDRAISERS_SUCCESS";

export const GET_FUNDRAISERS_SEARCHING = "GET_FUNDRAISERS_SEARCHING";
export const GET_FUNDRAISERS_SEARCHING_SUCCESS =
  "GET_FUNDRAISERS_SEARCHING_SUCCESS";

export const GET_MY_FUNDRAISERS = "GET_MY_FUNDRAISERS";
export const RESET_FUNDRAISER_BY_ID = "RESET_FUNDRAISER_BY_ID";
export const GET_MY_FUNDRAISERS_SUCCESS = "GET_MY_FUNDRAISERS_SUCCESS";
export const GET_FUNDRAISER_BY_ID = "GET_FUNDRAISER_BY_ID";
export const GET_FUNDRAISER_BY_ID_SUCCESS = "GET_FUNDRAISER_BY_ID_SUCCESS";
export const CHANGE_FUNDRAISER_STATUS = "CHANGE_FUNDRAISER_STATUS";
export const DELETE_FUNDRAISER = "DELETE_FUNDRAISER";
export const COMPLETED_FUNDRAISER = "COMPLETED_FUNDRAISER";
