import React from "react";
import { useNavigate } from "react-router";
import { ButtonMain } from "components";
import { useModal } from "utils";
import { AuthModalHeaders } from "./fragments";
import { Svg } from "assets";

export const WelcomeAboard = () => {
  const { close } = useModal();
  const navigate = useNavigate();

  const closeModalAndNavigate = () => {
    navigate("/");
    close();
  };

  return (
    <div className="auth-modals">
      <AuthModalHeaders
        title={"Welcome Aboard!"}
        subtitle={
          "Your password has been successfully reset.You can now log in with your new password."
        }
        icon={<Svg.WelcomeAboard />}
      />
      <ButtonMain
        className="done-button"
        text="Get Start"
        onClick={closeModalAndNavigate}
      />
    </div>
  );
};
