import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { FileUploader } from "react-drag-drop-files";
import { InputMain, MinLoader, ModalActions, PhoneInput } from "components";
import {
  EmailValidator,
  FindError,
  FindSuccess,
  Notify,
  ProfileInfo,
} from "utils";
import {
  httpRequestsOnErrorsActions,
  httpRequestsOnSuccessActions,
  UploadServices,
  userActions,
} from "store";
import { PROFILE_ACTION_TYPE } from "./constants";
import { Svg } from "assets";
export const Profile = () => {
  const { handleSubmit, control, setError, reset } = useForm({});
  const { myProfile } = ProfileInfo();
  const dispatch = useDispatch();
  const backError = FindError(PROFILE_ACTION_TYPE);
  const success = FindSuccess(PROFILE_ACTION_TYPE);
  const [fileError, setFileError] = useState("");
  const [currentFile, setCurrentFile] = useState(null);
  const [uploadLoader, setUploadLoader] = useState(false);

  const handleReset = () => {
    reset({
      firstName: myProfile?.firstName,
      username: myProfile?.username,
      lastName: myProfile?.lastName,
      email: myProfile?.email,
      phoneNumber: myProfile?.phoneNumber,
    });
  };

  useEffect(() => {
    if (myProfile) {
      handleReset();

      if (myProfile?.avatar) {
        setCurrentFile(myProfile?.avatar);
      }
    }
  }, [myProfile]);

  useEffect(() => {
    if (
      backError?.error === "User with this email or phone number exists" ||
      backError?.error === "User with this email address already exists"
    ) {
      setError("email", {
        type: "required",
        message: "User with this email exists",
      });
      dispatch(httpRequestsOnErrorsActions.removeError(PROFILE_ACTION_TYPE));
    }
    if (backError?.error === "User password does not match") {
      setError("password", {
        type: "required",
        message: "User password does not match",
      });
      dispatch(httpRequestsOnErrorsActions.removeError(PROFILE_ACTION_TYPE));
    }
    if (success?.type === PROFILE_ACTION_TYPE) {
      dispatch(httpRequestsOnSuccessActions.removeSuccess(PROFILE_ACTION_TYPE));
      Notify("Profile Updated Successfully");
    }
  }, [success, backError]);

  const onSubmit = (data) => {
    const params = {
      ...data,
    };

    if (data?.phoneNumber) {
      params.phoneNumber = parseInt(data?.phoneNumber.replace(/[^0-9]/g, ""));
    } else {
      params.phoneNumber = null;
    }

    dispatch(userActions.editUser(myProfile?.id, params));
  };

  const handleUpload = async (e) => {
    fileError === "currentFile" && setFileError("");
    if (e) {
      let formData = new FormData();
      formData.append("files", e);
      try {
        setUploadLoader(true);
        const res = await UploadServices.UploadImage(formData);
        setCurrentFile(res?.data);
        const params = {
          ...myProfile,
        };
        params.avatar = res?.data;
        dispatch(userActions.editUser(myProfile?.id, params));
        setUploadLoader(false);
      } catch (e) {
        setUploadLoader(false);
      }
    }
  };

  // const handleRemoveAvatar = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  // };

  return (
    <div className="profile-account-wrapper">
      <div className="profile-account-header-box">
        <p className="profile-account-header-title">Personal Info</p>
      </div>

      <div className="profile-account-box">
        <FileUploader handleChange={handleUpload} name="file">
          <div className="drag-drop-component">
            <div className="profile-avatar-box">
              {uploadLoader ? (
                <MinLoader
                  color="#ffffff"
                  height="30px"
                  width="30px"
                  margin={"0"}
                />
              ) : currentFile?.url ? (
                <img src={currentFile?.url} alt="icon" />
              ) : (
                <Svg.NotUploaded />
              )}
            </div>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="custom-file-upload">
              <input
                onClick={(event) => (event.target.value = null)}
                onChange={handleUpload}
                type="file"
                id="file"
              />
              <i className="fa fa-cloud-upload" />
              <span className="upload-btns-box">
                <button>
                  <Svg.Edit />
                </button>
                {/*<button onClick={handleRemoveAvatar}>*/}
                {/*  <Svg.Trash />*/}
                {/*</button>*/}
              </span>
            </label>
          </div>
        </FileUploader>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="profile-account-body-box">
          <div className="profile-account-box">
            <InputMain
              name="firstName"
              type="text"
              control={control}
              placeholder="First Name"
              labelHeader="First Name"
              maxLength={20}
              rules={{
                required: "This Field is required",
              }}
            />
            <InputMain
              name="username"
              type="text"
              control={control}
              placeholder="User Name"
              labelHeader="User Name"
              maxLength={20}
              rules={{
                required: "This Field is required",
              }}
            />
            <InputMain
              name="email"
              type="email"
              control={control}
              placeholder="Email"
              labelHeader="Email"
              rules={{
                required: "This Field is required",
                pattern: {
                  value: EmailValidator,
                  message: "Must be valid email",
                },
              }}
            />
            <PhoneInput
              placeholder={"ex (000) 000-0000"}
              name="phoneNumber"
              control={control}
              labelHeader="Phone Number"
            />
          </div>
        </div>

        <div className="profile-account-actions-box">
          <div className="profile-account-box">
            <ModalActions
              defaultStyle={true}
              type="submit"
              loadAction={PROFILE_ACTION_TYPE}
              saveTitle="Save"
              closeFunction={handleReset}
            />
          </div>
        </div>
      </form>
    </div>
  );
};
