import { call, put, takeLatest } from "redux-saga/effects";
import { httpRequestsOnErrorsActions } from "../http_requests_on_errors";
import { httpRequestsOnLoadActions } from "../http_requests_on_load";
import { httpRequestsOnSuccessActions } from "../http_requests_on_success";
import { charityRequestService } from "./charityRequest.service";
import { CREATE_CHARITY_REQUEST } from "./charityRequest.types";

function* createCharity({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    yield call(charityRequestService.createCharityRequest, payload?.body);
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(
      httpRequestsOnErrorsActions.appendError(type, err?.data?.message)
    );
  } finally {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

export const watchCharityRequest = function* () {
  yield takeLatest(CREATE_CHARITY_REQUEST, createCharity);
};
