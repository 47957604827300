import React from "react";
import { joinWithCards } from "./constatnts";
import { JoinWithCard } from "./joinWithCard";

export const JoinWith = () => {
  return (
    <div className="join-with-wrapper">
      <div className="oin-with-container">
        <div className="join-with-box">
          <h2 className="join-with-title">Donate with a peace of mind</h2>

          <div className="join-with-cards-wrapper">
            {joinWithCards.map((item, index) => (
              <JoinWithCard
                classNameWrapper="join-with-dingle_card"
                item={item}
                key={index}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
