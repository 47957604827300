import React, { useEffect } from "react";
import { PageLoader, SearchInput } from "components";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { fundraiserActions } from "store";
import { NotYet } from "../../components/table/notYet";
import { JoinMovementCard } from "../home/fragments";
import { fundraiserStatuses } from "../fundraisers";
import { ACTION_TYPES } from "./fragments/constants";
import { FindLoad } from "utils";
import { Images } from "assets";

export const SearchEvent = () => {
  const { fundraisers } = useSelector((state) => ({
    fundraisers: state.fundraiser.fundraisers,
  }));
  const dispatch = useDispatch();
  const loader = FindLoad(ACTION_TYPES);
  const location = useLocation();
  const info = location?.state;

  const firstFundraisers = fundraisers?.fundraisers?.length
    ? fundraisers?.fundraisers[0]
    : null;

  const length = fundraisers?.fundraisers?.length;
  const showMaxFourCard = fundraisers?.fundraisers.slice(1, 5);

  let firstCardClass = "";

  switch (length) {
    case 1:
      firstCardClass = "search-event-first-card_1";
      break;
    case 2:
      firstCardClass = "search-event-first-card_2";
      break;
    case 3:
      firstCardClass = "search-event-first-card_3";
      break;
    default:
      firstCardClass = "search-event-first-card";
      break;
  }

  useEffect(() => {
    const params = {
      statuses: [fundraiserStatuses.LIVE, fundraiserStatuses.COMPETED],
    };
    if (info?.name) {
      params.name = info.name;
    }
    dispatch(fundraiserActions.getFundraisers(params));
  }, [dispatch, info]);

  return (
    <div className="search-event-page">
      <div className="container">
        <div className="search-event-box">
          <div className="search-event-title_search">
            <h2 className="search-event-title">Search an Events </h2>

            <p className="search-event-desc">
              Search Events by title, keyword, or a person’s name
            </p>

            <SearchInput
              placeholder={"Search..."}
              searchKey={"name"}
              className="search-event-search"
            />
          </div>

          <div className="search-event-cards">
            {loader ? (
              <div style={{ height: "100%", width: "100%" }}>
                <PageLoader height={"60vh"} />
              </div>
            ) : (
              <div className="search-event-cards-wrapper">
                {fundraisers?.fundraisers?.length ? (
                  <div className="search-event-cards-box">
                    <JoinMovementCard
                      classNameWrapper={firstCardClass}
                      item={{
                        ...firstFundraisers,
                        img: firstFundraisers?.images?.length
                          ? firstFundraisers?.images[
                              firstFundraisers?.mainImage
                            ]?.url
                          : Images.needCardFrame,
                        title: firstFundraisers?.name,
                      }}
                    />
                    {length < 4 &&
                      showMaxFourCard.map((item, index) => (
                        <JoinMovementCard
                          key={index}
                          classNameWrapper={firstCardClass}
                          item={{
                            ...item,
                            img: item?.images?.length
                              ? item?.images[item?.mainImage]?.url
                              : Images.needCardFrame,
                            title: item?.name,
                          }}
                        />
                      ))}

                    {length > 3 && (
                      <div className="search-event-another-cards">
                        {showMaxFourCard.map((item, index) => (
                          <JoinMovementCard
                            key={index}
                            classNameWrapper={
                              showMaxFourCard.length === 3 && index === 2
                                ? "search-event-card-4"
                                : "search-event-card"
                            }
                            item={{
                              ...item,
                              img: item?.images?.length
                                ? item?.images[item?.mainImage]?.url
                                : Images.needCardFrame,
                              title: item?.name,
                            }}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                ) : (
                  <NotYet height={"60vh"} />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
