import { call, put, takeLatest } from "redux-saga/effects";
import { donationService } from "./donation.service";
import {
  CREATE_DONATION,
  EDIT_DONATION,
  GET_DONATION_BY_ID,
  GET_DONATION_BY_ID_SUCCESS,
  GET_DONATIONS,
  GET_DONATIONS_FUNDRAISER,
  GET_DONATIONS_FUNDRAISER_SUCCESS,
  GET_DONATIONS_SUCCESS,
} from "./donation.types";
import { httpRequestsOnErrorsActions } from "../http_requests_on_errors";
import { httpRequestsOnLoadActions } from "../http_requests_on_load";
import { httpRequestsOnSuccessActions } from "../http_requests_on_success";
import { Notify } from "utils";

function* createDonation({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    yield call(donationService.createDonationService, payload?.body);
    Notify("DONATION created successfully");
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(
      httpRequestsOnErrorsActions.appendError(type, err?.data?.message)
    );
  } finally {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* editDonation({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    yield call(donationService.editDonationService, payload?.id, payload?.body);
    Notify("DONATION edited successfully");
    yield put({
      type: GET_DONATION_BY_ID,
      payload: payload?.id,
    });
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(
      httpRequestsOnErrorsActions.appendError(type, err?.data?.message)
    );
  } finally {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* getDonations({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    const res = yield call(
      donationService.getDonationsService,
      payload?.params
    );

    yield put({
      type: GET_DONATIONS_SUCCESS,
      payload: res.data,
    });
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(
      httpRequestsOnErrorsActions.appendError(type, err?.data?.message)
    );
  } finally {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* getDonationsFundraiser({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    const res = yield call(
      donationService.getDonationsFundraiserService,
      payload?.params
    );

    yield put({
      type: GET_DONATIONS_FUNDRAISER_SUCCESS,
      payload: res.data,
    });
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(
      httpRequestsOnErrorsActions.appendError(type, err?.data?.message)
    );
  } finally {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* getDonationById({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    const res = yield call(donationService.getDonationByIdService, payload);
    yield put({
      type: GET_DONATION_BY_ID_SUCCESS,
      payload: res.data,
    });
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(httpRequestsOnErrorsActions.appendError(type, err?.data.message));
  } finally {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

export const watchDonation = function* () {
  yield takeLatest(CREATE_DONATION, createDonation);
  yield takeLatest(EDIT_DONATION, editDonation);
  yield takeLatest(GET_DONATIONS, getDonations);
  yield takeLatest(GET_DONATIONS_FUNDRAISER, getDonationsFundraiser);
  yield takeLatest(GET_DONATION_BY_ID, getDonationById);
};
