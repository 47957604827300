export const amountEnums = [
  { key: "$500.00", value: 500, color: "rgba(255, 208, 92, 0.15)" },
  { key: "$1,000.00", value: 1000, color: "#DBEDDB" },
  { key: "$2,000.00", value: 2000, color: "rgba(173, 53, 1, 0.15)" },
  { key: "$5,000.00", value: 5000, color: "#DBEDDB" },
];

export const ACTION_TYPE = "CREATE_DONATION";

export const FUNDRAISER_ACTION_TYPE = "GET_FUNDRAISER_BY_ID";
