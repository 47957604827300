import {
  GET_FUNDRAISER_BY_ID_SUCCESS,
  GET_FUNDRAISERS_SEARCHING_SUCCESS,
  GET_FUNDRAISERS_SUCCESS,
  GET_MY_FUNDRAISERS_SUCCESS,
  RESET_FUNDRAISER_BY_ID,
} from "./fundraiser.types";

const initialState = {
  fundraisers: null,
  fundraisersSearching: null,
  myFundraisers: null,
  fundraiserById: null,
};

export const fundraiserReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FUNDRAISERS_SUCCESS:
      return {
        ...state,
        fundraisers: action.payload,
      };

    case GET_MY_FUNDRAISERS_SUCCESS:
      return {
        ...state,
        myFundraisers: action.payload,
      };

    case GET_FUNDRAISERS_SEARCHING_SUCCESS:
      return {
        ...state,
        fundraisersSearching: action.payload,
      };

    case GET_FUNDRAISER_BY_ID_SUCCESS:
      return {
        ...state,
        fundraiserById: action.payload,
      };
    case RESET_FUNDRAISER_BY_ID:
      return {
        ...state,
        fundraiserById: null,
      };

    default:
      return state;
  }
};
