import { getTransfers, reTransfers } from "./transfer.action";

export { transferReducer } from "./transfer.reducer";
export { watchTransfer } from "./transfer.saga";

export const transferActions = {
  /** Transfer Actions */
  getTransfers,
  reTransfers,
};
