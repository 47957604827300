import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import { RadioGroup } from "@mui/material";
import { CheckboxMain, RadioMain } from "components";
import { generateDisplayId, SaveParams } from "utils";
import { Stepper } from "./stepper";
import { Svg } from "../../../assets";

export const DistributeFunds = () => {
  const [iAgree, setIAgree] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const location = useLocation();
  const navigator = useNavigate();
  const info = location?.state;

  const { handleSubmit, control, setValue } = useForm({
    defaultValues: {
      keepPercent: "50",
    },
  });

  useEffect(() => {
    if (info?.keepPercent) {
      setValue("keepPercent", info?.keepPercent);
    }
  }, [info]);

  const handleCreate = (data) => {
    const params = {
      ...info,
      ...data,
    };

    if (!iAgree) {
      setShowWarning(true);
    } else {
      params.step = 5;
      SaveParams(location?.pathname, navigator, params);
    }

    // if (data?.keepPercent !== "50" || myProfile?.pmtAccount) {
    //   if (!myProfile?.pmtAccount?.bankId && data?.keepPercent === "50") {
    //     params.step = 6;
    //   } else {
    //     params.step = 7;
    //   }
    // } else {
    //   if (ifAdmin) {
    //     params.step = 7;
    //   } else {
    //     params.step = 5;
    //   }
    // }
  };

  return (
    <form className="in-celebration-box" onSubmit={handleSubmit(handleCreate)}>
      <div className="in-celebration-content">
        <div className="container">
          <div className="in-celebration-form-wrapper distribute-wrapper-celebration">
            <div className="in-celebration-form-header">
              <p className="in-celebration-form-header-title">
                Choose How You’d Like to Split Your Funds
              </p>

              <p className="in-celebration-form-header-desc">
                Select the option that best suits your goals.
              </p>
            </div>

            <div className="in-celebration-form-content">
              <div className="chips-and-info-wrapper">
                <p className="chips-and-info-title">
                  Event ID: {generateDisplayId(info.deceased)}
                </p>
              </div>

              <RadioGroup>
                <div className="distribute-fund-types-block">
                  {showWarning && !iAgree && (
                    <div className="warning_split_agree">
                      <Svg.Warning1Svg />

                      <p className="warning_split_agree-text">
                        Please, agree to a 5% platform fee deduction from all
                        contributions to proceed.
                      </p>
                    </div>
                  )}

                  <div className="distribute-fund-types-single">
                    <RadioMain
                      control={control}
                      name="keepPercent"
                      value="0"
                      styles={{
                        color: "#34A853",
                        checkedColor: "#34A853",
                      }}
                    />

                    <div className="distribute-fund-types-text_desc">
                      <p className="distribute-fund-types-text">Donate 100%</p>

                      <p className="distribute-fund-types-desc">
                        Donate the entire amount to your chosen charities. You
                        will be redirected to a page with a list of charities.
                      </p>
                    </div>
                  </div>

                  <div className="distribute-fund-types-single">
                    <RadioMain
                      control={control}
                      name="keepPercent"
                      value="50"
                      styles={{
                        color: "#34A853",
                        checkedColor: "#34A853",
                      }}
                    />

                    <div className="distribute-fund-types-text_desc">
                      <p className="distribute-fund-types-text">
                        Split Funds 50/50
                      </p>

                      <p className="distribute-fund-types-desc">
                        Distribute the funds equally between you and charities
                        you will choose on the next page. You will need to
                        provide your personal banking information.
                      </p>
                    </div>
                  </div>

                  <CheckboxMain
                    checked={iAgree}
                    onChange={() => setIAgree((prevState) => !prevState)}
                    label="I agree to a 5% platform fee deduction from all contributions."
                    styles={{
                      color: "#34A853",
                      checkedColor: "#34A853",
                      labelColor: "#000",
                    }}
                  />
                </div>
              </RadioGroup>
            </div>
          </div>
        </div>
      </div>
      <Stepper />
    </form>
  );
};
