import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { BackBtn, PageLoader } from "components";
import { donationActions, fundraiserActions } from "store";
import { FindLoad } from "utils";
import { ACTION_TYPE, EventInfo } from "./fragments";
import { useLocation } from "react-router-dom";

export const EventDetails = () => {
  const { fundraiserById } = useSelector((state) => ({
    fundraiserById: state.fundraiser.fundraiserById,
  }));
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const loader = FindLoad(ACTION_TYPE);
  const location = useLocation();
  const info = location?.state;

  const isMemory = fundraiserById?.type === "MEMORY";

  const navigateBack = () => {
    navigate("/find-event");
  };
  useEffect(() => {
    if (params?.id) {
      dispatch(fundraiserActions.getFundraiserById(params?.id));
    }
  }, [params?.id]);

  useEffect(() => {
    const sendDate = {
      ...info,
      fundraiser: params?.id,
    };
    dispatch(donationActions.getDonationsFundraiser(sendDate));
  }, [params?.id, info]);

  useEffect(() => {
    return () => {
      dispatch(fundraiserActions.resetFundraiserById());
    };
  }, []);

  if (loader) {
    return <PageLoader />;
  }

  return (
    <div
      className={`event-details-details-page ${
        isMemory ? "event-details-details-page-black" : ""
      }`}
    >
      <div className="container">
        <div className="event-details-back-btn">
          <BackBtn
            className="event-details-back-btn_memory"
            handleClick={navigateBack}
          />
        </div>
        <EventInfo />
      </div>
    </div>
  );
};
