import { useSelector } from "react-redux";

export const ProfileInfo = () => {
  const selector = useSelector((state) => state?.auth?.myProfile);
  const local =
    localStorage.getItem("userInfo") &&
    JSON.parse(localStorage.getItem("userInfo"));
  const myProfile = selector || local;

  const accessToke = useSelector((state) => state?.auth?.accessToke);
  const localToken = localStorage.getItem("access-token");
  const token = localToken || accessToke;

  const ifAdmin = myProfile?.userType === "ADMIN";
  return { myProfile, token, ifAdmin };
};
