import { call, put, takeLatest } from "redux-saga/effects";
import { fundraiserService } from "./fundraiser.service";
import {
  CHANGE_FUNDRAISER_STATUS,
  COMPLETED_FUNDRAISER,
  CREATE_FUNDRAISER,
  DELETE_FUNDRAISER,
  EDIT_FUNDRAISER,
  GET_FUNDRAISER_BY_ID,
  GET_FUNDRAISER_BY_ID_SUCCESS,
  GET_FUNDRAISERS,
  GET_FUNDRAISERS_SEARCHING,
  GET_FUNDRAISERS_SEARCHING_SUCCESS,
  GET_FUNDRAISERS_SUCCESS,
  GET_MY_FUNDRAISERS,
  GET_MY_FUNDRAISERS_SUCCESS,
} from "./fundraiser.types";
import { httpRequestsOnErrorsActions } from "../http_requests_on_errors";
import { httpRequestsOnLoadActions } from "../http_requests_on_load";
import { httpRequestsOnSuccessActions } from "../http_requests_on_success";
import { Notify } from "utils";

function* createFundraiser({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    yield call(fundraiserService.createFundraiserService, payload?.body);
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(
      httpRequestsOnErrorsActions.appendError(type, err?.data?.message)
    );
  } finally {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* editFundraiser({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    yield call(
      fundraiserService.editFundraiserService,
      payload?.id,
      payload?.body
    );
    Notify("Fundraiser edited successfully");
    yield put({
      type: GET_FUNDRAISER_BY_ID,
      payload: { id: payload?.id, load: payload?.load },
    });
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(
      httpRequestsOnErrorsActions.appendError(type, err?.data?.message)
    );
  } finally {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* getFundraisers({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    const res = yield call(
      fundraiserService.getFundraisersService,
      payload?.params
    );
    yield put({
      type: GET_FUNDRAISERS_SUCCESS,
      payload: res.data,
    });
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(
      httpRequestsOnErrorsActions.appendError(type, err?.data?.message)
    );
  } finally {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* getFundraisersSearching({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    const res = yield call(
      fundraiserService.getFundraisersSearchService,
      payload?.params
    );
    yield put({
      type: GET_FUNDRAISERS_SEARCHING_SUCCESS,
      payload: res.data,
    });
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(
      httpRequestsOnErrorsActions.appendError(type, err?.data?.message)
    );
  } finally {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* getMyFundraisers({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    const res = yield call(
      fundraiserService.getMyFundraisersService,
      payload?.params
    );
    yield put({
      type: GET_MY_FUNDRAISERS_SUCCESS,
      payload: res.data,
    });
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(
      httpRequestsOnErrorsActions.appendError(type, err?.data?.message)
    );
  } finally {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* getFundraiserById({ payload, type }) {
  if (payload?.load !== "noLoad") {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
  }
  yield put(httpRequestsOnErrorsActions.removeError(type));

  try {
    const res = yield call(
      fundraiserService.getFundraiserByIdService,
      payload?.id
    );

    yield put({
      type: GET_FUNDRAISER_BY_ID_SUCCESS,
      payload: res.data,
    });
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(
      httpRequestsOnErrorsActions.appendError(type, err?.data?.message)
    );
  } finally {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* changeFundraiserStatus({ payload, type }) {
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    yield call(
      fundraiserService.changeFundraiserStatusService,
      payload?.id,
      payload?.status
    );
    window.location.replace("/fundraisers");
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
  } catch (err) {
    Notify(err?.data.message);
  } finally {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* deleteFundraiser({ payload, type }) {
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    yield call(fundraiserService.deleteFundraiserService, payload);
    window.location.replace("/events");
  } finally {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* completedFundraiser({ payload, type }) {
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    yield call(fundraiserService.completedFundraiserService, payload);
    window.location.replace("/events");
  } finally {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

export const watchFundraiser = function* () {
  yield takeLatest(CREATE_FUNDRAISER, createFundraiser);
  yield takeLatest(EDIT_FUNDRAISER, editFundraiser);
  yield takeLatest(GET_FUNDRAISERS, getFundraisers);
  yield takeLatest(GET_FUNDRAISERS_SEARCHING, getFundraisersSearching);
  yield takeLatest(GET_MY_FUNDRAISERS, getMyFundraisers);
  yield takeLatest(GET_FUNDRAISER_BY_ID, getFundraiserById);
  yield takeLatest(CHANGE_FUNDRAISER_STATUS, changeFundraiserStatus);
  yield takeLatest(DELETE_FUNDRAISER, deleteFundraiser);
  yield takeLatest(COMPLETED_FUNDRAISER, completedFundraiser);
};
