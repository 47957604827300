import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { ButtonMain, InputMain } from "components";
import { CheckEmail } from "./checkEmail";
import { authActions, httpRequestsOnSuccessActions } from "store";
import { EmailValidator, FindError, FindSuccess, useModal } from "utils";
import { Svg } from "assets";
import { AuthModalHeaders, IfRemember } from "./fragments";

const ACTION_TYPE = "GET_RECOVERY_LINK";

export const ForgotPassword = () => {
  const dispatch = useDispatch();
  const { openModal, close } = useModal();
  const recoverySuccess = FindSuccess(ACTION_TYPE);
  const recoveryError = FindError(ACTION_TYPE);

  const { handleSubmit, control, setError } = useForm({
    defaultValues: {
      email: "",
    },
  });

  useEffect(() => {
    if (recoverySuccess) {
      dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
      close();
      openModal(<CheckEmail />);
    }
  }, [recoverySuccess]);

  useEffect(() => {
    if (recoveryError) {
      setError("email", {
        type: "required",
        message: recoveryError?.error,
      });
    }
  }, [recoveryError]);

  const onSubmit = (data) => {
    dispatch(authActions.getRecoveryLink(data.email));
  };

  return (
    <div className="auth-modals">
      <AuthModalHeaders
        title={"Forgot your Password?"}
        subtitle={
          " Enter your email address  and we'll send you a recovery email to reset your password."
        }
        icon={<Svg.Recover />}
      />
      <form className="form-box" onSubmit={handleSubmit(onSubmit)}>
        <InputMain
          name="email"
          type="email"
          control={control}
          placeholder="Email"
          labelHeader="Email"
          rules={{
            required: "This Field is required",
            pattern: {
              value: EmailValidator,
              message: "Must be valid email",
            },
          }}
          startAdornment={<Svg.Sms />}
        />

        <ButtonMain
          text="Get Recovery Email"
          type="submit"
          actionTye={ACTION_TYPE}
        />

        <IfRemember />
      </form>
    </div>
  );
};
