import axios from "axios";

export const charityService = {
  createCharityService: (body) => axios.post("/charity", body, { auth: true }),

  editCharityService: (id, body) =>
    axios.patch(`/charity/${id}`, body, { auth: true }),

  getCharitiesService: (params) => axios.get("/charity", { params: params }),

  getPendingCharitiesService: (params) =>
    axios.get("/charity_request", { auth: true, params: params }),

  deletePendingCharities: (id) =>
    axios.delete(`/charity_request/${id}`, { auth: true }),

  getCharityByIdService: (id) => axios.get(`/charity/${id}`, { auth: true }),

  deleteCharityService: (id) => axios.delete(`/charity/${id}`, { auth: true }),

  changeCharityStatusService: (id, body) =>
    axios.patch(`/charity/status/${id}`, body, { auth: true }),
};
