export const CELEBRATION = "CELEBRATION";

export const percents = [
  {
    label: "5%",
    value: "5",
  },
  {
    label: "10%",
    value: "10",
  },
  {
    label: "15%",
    value: "15",
  },
  {
    label: "20%",
    value: "20",
  },
];

export const BUSINESSTYPE = "individual";
