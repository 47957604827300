import {
  CONNECT_BANK_ACCOUNT,
  CONNECT_NON_PROFIT,
  DELETE_CONNECT_STRIPE,
  GET_MY_BANK,
  UPDATE_CONNECT_BANK,
} from "./bankAccount.types";

export const connectNonProfit = (body) => {
  return {
    type: CONNECT_NON_PROFIT,
    payload: { body },
  };
};
export const connectBankAccount = (body, charity) => {
  return {
    type: CONNECT_BANK_ACCOUNT,
    payload: { body, charity },
  };
};

export const getMyBank = (id) => {
  return {
    type: GET_MY_BANK,
    payload: { id },
  };
};

export const deleteConnectStripe = (id) => {
  return {
    type: DELETE_CONNECT_STRIPE,
    payload: { id },
  };
};

export const updateConnectBank = (id, body) => {
  return {
    type: UPDATE_CONNECT_BANK,
    payload: { id, body },
  };
};
