import { useLocation, useNavigate } from "react-router";
import { FindLoad, ProfileInfo, SaveParams } from "utils";
import { Svg } from "assets";
import { ButtonMain } from "components";

const ACTION_TYPE = "GET_MY_PROFILE";

export const Stepper = ({ loading, disabled }) => {
  const location = useLocation();
  const navigator = useNavigate();
  const { token } = ProfileInfo();
  const getProfileLoad = FindLoad(ACTION_TYPE);
  const info = location?.state;
  const checkFirstPage = info?.step !== 1 && info?.step;

  const handleBack = () => {
    let backStep = info?.step > 1 ? info?.step - 1 : info?.step;

    if (token && info?.step === 4) {
      backStep = 2;
    }

    // if (info?.step === 6 && myProfile?.pmtAccount?._id) {
    //   backStep = 4;
    // }
    //
    // if (
    //   info?.step === 7 &&
    //   (info?.keepPercent !== "50" || myProfile?.pmtAccount)
    // ) {
    //   backStep = 4;
    // }
    //
    // if (info?.step === 7 && ifAdmin) {
    //   backStep = 4;
    // }

    const params = {
      ...info,
      step: backStep,
    };

    SaveParams(location?.pathname, navigator, params);
  };

  return (
    <div className="in-memory-of-footer-wrapper">
      <div className="in-memory-of-footer-box">
        <div className="in-memory-of-footer-svg_step">
          <p className="in-memory-of-footer-steps">Step {info?.step || 1}/5</p>
        </div>

        {info?.step !== 3 && (
          <div className="in-memory-of-footer-buttons">
            {checkFirstPage && (
              <button
                className="in-memory-of-footer-svg"
                onClick={handleBack}
                disabled={getProfileLoad}
              >
                <Svg.BeckArrow />
              </button>
            )}

            <ButtonMain
              load={loading}
              disabled={disabled || getProfileLoad}
              classNameWrapper="in-memory-footer-button-confirm"
              text={info?.step === 7 ? "Completed" : "Next Step"}
              type="submit"
              endIcon={<Svg.ArrowRight />}
            />
          </div>
        )}
      </div>
    </div>
  );
};
