import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Svg } from "assets";
import { ButtonMain } from "components";
import { fundraiserActions, httpRequestsOnSuccessActions } from "store";
import { FindSuccess, useModal } from "utils";
import { COMPLETED_FUNDRAISER } from "../constants";

export const EndEventModal = ({ id, isMemory, customIcon }) => {
  const dispatch = useDispatch();
  const { close } = useModal();
  const success = FindSuccess(COMPLETED_FUNDRAISER);

  useEffect(() => {
    if (success) {
      close();
      dispatch(
        httpRequestsOnSuccessActions.removeSuccess(COMPLETED_FUNDRAISER)
      );
    }
  }, [success]);

  const handlePublish = () => {
    dispatch(fundraiserActions.completedFundraiser(id));
  };

  return (
    <div
      className="end-event-modal"
      style={{ background: isMemory ? "#0D111D" : "" }}
    >
      {customIcon ? customIcon : <Svg.DeleteCharity />}
      <div>
        <p
          style={{ color: isMemory ? "#E0E1E2" : "" }}
          className="end-event-modal-title"
        >
          Are you sure you want to end the event and disburse the funds?
        </p>
        <p
          style={{ color: isMemory ? "#E0E1E2" : "" }}
          className="end-event-modal-desc"
        >
          This action will finalize the event, and all collected funds will be
          distributed as intended. Please confirm to proceed.
        </p>
      </div>
      <div className="flex-end" style={{ gap: "8px" }}>
        <button className="end-event-modal-no-btn" onClick={close}>
          No
        </button>
        <ButtonMain
          actionTye={COMPLETED_FUNDRAISER}
          text="Yes"
          type="button"
          onClick={handlePublish}
        />
      </div>
    </div>
  );
};
