import {
  CHANGE_CHARITY_STATUS,
  CREATE_CHARITY,
  DELETE_CHARITY,
  DELETE_PENDING_CHARITIES,
  EDIT_CHARITY,
  GET_ALL_PENDING_CHARITIES,
  GET_CHARITIES,
  GET_CHARITY_BY_ID,
  GET_PENDING_CHARITIES,
} from "./charity.types";

export const createCharity = (body) => {
  return {
    type: CREATE_CHARITY,
    payload: { body },
  };
};

export const editCharity = (id, body, load) => {
  return {
    type: EDIT_CHARITY,
    payload: { id, body, load },
  };
};

export const getCharities = (params) => {
  return {
    type: GET_CHARITIES,
    payload: { params },
  };
};
export const getPendingCharities = (params, load) => {
  return {
    type: GET_PENDING_CHARITIES,
    payload: { params, load },
  };
};
export const deletePendingCharities = (id) => {
  return {
    type: DELETE_PENDING_CHARITIES,
    payload: { id },
  };
};

export const getAllPendingCharities = () => {
  return {
    type: GET_ALL_PENDING_CHARITIES,
  };
};

export const getCharityById = (id) => {
  return {
    type: GET_CHARITY_BY_ID,
    payload: { id },
  };
};

export const deleteCharity = (id) => {
  return {
    type: DELETE_CHARITY,
    payload: id,
  };
};
export const changeCharityStatus = (id, body) => {
  return {
    type: CHANGE_CHARITY_STATUS,
    payload: { id, body },
  };
};
