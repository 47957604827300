import * as React from "react";
import { Controller } from "react-hook-form";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const CheckboxesTags = ({
  classNameWrapper,
  labelHeader,
  control,
  name,
  rules,
  placeholder,
  list,
  renderLabel,
  defaultValue,
  disabled,
}) => {
  return (
    <div
      className={`accordion-wrapper ${
        classNameWrapper ? classNameWrapper : ""
      }`}
    >
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({
          field,
          field: { onChange, value },
          formState: { errors },
        }) => {
          return (
            <>
              {labelHeader && (
                <p
                  className={`input-main-label-header ${
                    errors[field?.name]?.message ? "errorLabel" : ""
                  }`}
                >
                  {labelHeader}
                  {rules?.required && (
                    <span className="input-main-label-header-required">*</span>
                  )}
                </p>
              )}
              <Autocomplete
                {...field}
                disabled={disabled}
                multiple
                id="checkboxes-tags-demo"
                error={!!errors[field?.name]?.message}
                sx={{
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderColor: errors[field?.name]?.message
                      ? "#D03325"
                      : "#9D9D9D",
                  },
                }}
                options={list}
                value={value}
                defaultValue={defaultValue}
                disableCloseOnSelect
                getOptionLabel={(option) => renderLabel(option)}
                onChange={(event, item) => {
                  onChange(item);
                }}
                renderOption={(props, option, { selected }) => {
                  return (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8, color: "#808000" }}
                        checked={selected}
                      />
                      {renderLabel(option)}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder={placeholder} />
                )}
              />
              <p className="error-message">{errors[field?.name]?.message}</p>
            </>
          );
        }}
      />
    </div>
  );
};
