/** Types */
export const CREATE_DONATION = "CREATE_DONATION";
export const EDIT_DONATION = "EDIT_DONATION";
export const GET_DONATIONS = "GET_DONATIONS";
export const GET_DONATIONS_SUCCESS = "GET_DONATIONS_SUCCESS";

export const GET_DONATIONS_FUNDRAISER = "GET_DONATIONS_FUNDRAISER";
export const GET_DONATIONS_FUNDRAISER_SUCCESS =
  "GET_DONATIONS_FUNDRAISER_SUCCESS";
export const GET_DONATION_BY_ID = "GET_DONATION_BY_ID";
export const GET_DONATION_BY_ID_SUCCESS = "GET_DONATION_BY_ID_SUCCESS";
