export const settingsTabList = (isAdmin) => {
  if (isAdmin) {
    return [
      { label: "Profile", value: "PROFILE" },
      { label: "Account", value: "ACCOUNT" },
    ];
  } else {
    return [
      { label: "Profile", value: "PROFILE" },
      { label: "Account", value: "ACCOUNT" },
      { label: "Bank Account", value: "BANK_ACCOUNT" },
    ];
  }
};

export const showModals = {
  PERSONAL_DETAILS: "PERSONAL_DETAILS",
  BANK_ACCOUNT: "BANK_ACCOUNT",
};

export const settingsTabEnum = {
  PROFILE: "PROFILE",
  ACCOUNT: "ACCOUNT",
  BANK_ACCOUNT: "BANK_ACCOUNT",
};

export const PROFILE_ACTION_TYPE = "EDIT_USER";
export const ACCOUNT_ACTION_TYPE = "CHANGE_PASSWORD_REQUEST";

export const DELETE_ACCOUNT = "DELETE_ACCOUNT_REQUEST";

export const DELETE_CONNECT_STRIPE = "DELETE_CONNECT_STRIPE";
export const CONNECT_BANK = "CONNECT_BANK_ACCOUNT";
export const CONNECT_NON_PROFIT = "CONNECT_NON_PROFIT";
export const UPDATE_CONNECT_BANK = "UPDATE_CONNECT_BANK";
export const BUSINESSTYPE = "individual";
