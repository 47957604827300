import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { fundraiserActions } from "store";
import { loadStripe } from "@stripe/stripe-js";
import { DonateCheckoutForm } from "./checkoutForm";
import { Elements } from "@stripe/react-stripe-js";
import { PageLoader } from "components";
import { FindLoad } from "utils";
import { FUNDRAISER_ACTION_TYPE } from "./fragments";

export const Donate = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const loader = FindLoad(FUNDRAISER_ACTION_TYPE);

  useEffect(() => {
    dispatch(fundraiserActions.getFundraiserById(params.id));
  }, [dispatch, params.id]);

  const stripePromise = loadStripe(
    "pk_test_51P3bZSKvdgj9j7PZhcOMB4J49oEzspGi1vxOqo5l5jEAogEyOwrHFOprKhwsDxAND5jLIw6153BDBJAvgFKei2uZ00SnPmwlzg"
  );

  if (loader?.length) {
    return <PageLoader />;
  }

  return (
    <div>
      <Elements stripe={stripePromise}>
        <DonateCheckoutForm />
      </Elements>
    </div>
  );
};
