export const tabEnums = {
  NON_PROFIT: "NON_PROFIT",
  PERSONAL_DETAILS: "PERSONAL_DETAILS",
  BANK_ACCOUNT: "BANK_ACCOUNT",
};

export const bankStepperData = [
  {
    label: "Nonprofit Details",
    value: "1",
    enum: tabEnums.NON_PROFIT,
  },

  {
    label: "Personal Details",
    value: "2",
    enum: tabEnums.PERSONAL_DETAILS,
  },

  {
    label: "Bank Account",
    value: "3",
    enum: tabEnums.BANK_ACCOUNT,
  },
];

export const CONNECT_NON_PROFIT = "CONNECT_NON_PROFIT";
export const CONNECT_BANK = "CONNECT_BANK_ACCOUNT";
export const UPDATE_CONNECT_BANK = "UPDATE_CONNECT_BANK";
export const DELETE_CONNECT_STRIPE = "DELETE_CONNECT_STRIPE";
export const BUSINESSTYPE = "non_profit";
