import React from "react";
import { useSelector } from "react-redux";
import { Svg } from "assets";

export const DrawerOpening = ({ onClick, title }) => {
  const { fundraiserById } = useSelector((state) => ({
    fundraiserById: state.fundraiser.fundraiserById,
  }));

  const isMemory = fundraiserById?.type === "MEMORY";

  return (
    <button
      className={`drawer_opening_main_button ${
        isMemory ? "drawer_opening_main_button-memory" : ""
      }`}
      onClick={onClick}
    >
      <p>{title}</p>

      <Svg.ArrowDown />
    </button>
  );
};
