import React, { useState } from "react";

export const ReadMore = ({ text, maxLength, className, isMemory }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const shortText = text.substring(0, maxLength);

  return (
    <div className={`read-more-wrapper ${className ? className : ""}`}>
      {isExpanded ? (
        <>
          <span
            style={{
              color: isMemory ? "#E0E1E2" : "",
            }}
          >
            {text}
          </span>
        </>
      ) : (
        <>
          <span
            style={{
              color: isMemory ? "#E0E1E2" : "",
            }}
          >
            {shortText}
          </span>
          <span
            style={{
              color: isMemory ? "#E0E1E2" : "",
            }}
          >
            {text.length > maxLength && "... "}
          </span>
          {!isExpanded && text.length > maxLength && (
            <button
              style={{
                color: isMemory ? "#E0E1E2" : "",
              }}
              onClick={toggleReadMore}
            >
              Read More
            </button>
          )}
        </>
      )}
      {isExpanded && text.length > maxLength && (
        <button
          style={{
            color: isMemory ? "#E0E1E2" : "",
          }}
          onClick={toggleReadMore}
        >
          Show Less
        </button>
      )}
    </div>
  );
};
