import { GET_TRANSFERS, RE_TRANSFER } from "./transfer.types";

export const getTransfers = (params) => {
  return {
    type: GET_TRANSFERS,
    payload: { params },
  };
};

export const reTransfers = (id) => {
  return {
    type: RE_TRANSFER,
    payload: { id },
  };
};
