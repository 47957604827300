import { GET_MY_PROFILE_SUCCESS, LOG_IN_SUCCESS } from "./auth.types";

const initialState = {
  accessToke: null,
  myProfile: null,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MY_PROFILE_SUCCESS:
      return {
        ...state,
        myProfile: action.payload,
      };

    case LOG_IN_SUCCESS:
      return {
        ...state,
        accessToke: action.payload,
      };

    default:
      return state;
  }
};
