import { Svg } from "assets";
import { formatPhoneNumber, useModal } from "utils";

export const CharityDetails = ({ charity, isMemory }) => {
  const { close } = useModal();

  return (
    <div
      className={`charity-detail-modal ${
        isMemory ? "charity-detail-modal_memory" : ""
      }`}
    >
      <div className="charity-detail-modal-head">
        <div>
          <Svg.FundraiserFrameSvg />
          <p>{charity?.charity?.name}</p>
        </div>

        <button onClick={() => close()} className="charity-detail-modal_close">
          <Svg.Close />
        </button>
      </div>
      <div className="charity-detail-modal-body">
        {charity?.charity?.description && (
          <div className="charity-detail-modal-desc">
            <p className="charity-desc-title">Description</p>
            <p className="charity-desc-text">{charity?.charity?.description}</p>
          </div>
        )}
        {!!charity?.charity?.images?.length && (
          <div className="charity-detail-modal-images">
            {charity?.charity?.images?.map((item, index) => (
              <div className="charity-detail-modal-images_block" key={index}>
                <img src={item?.url} alt="charity" />
              </div>
            ))}
          </div>
        )}
      </div>
      {charity?.charity?.phone ||
        (charity?.charity?.email && (
          <div className="charity-detail-modal-footer">
            {charity?.charity?.phoneNumber && (
              <div>
                <Svg.PhoneWhiteSvg />
                <a href="tel:12061562849">
                  <b>Call:</b>{" "}
                  {formatPhoneNumber(charity?.charity?.phoneNumber)}
                </a>
              </div>
            )}
            {charity?.charity?.email && (
              <div>
                <Svg.EmailWhite />
                <a href={`mailto:${charity?.charity?.email}`}>
                  <b>Mail:</b> {charity?.charity?.email}
                </a>
              </div>
            )}
          </div>
        ))}
    </div>
  );
};
