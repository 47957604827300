import React from "react";
import { ButtonMain } from "components";

export const JoinMovementCard = ({
  item,
  classNameWrapper,
  stickerLive,
  allowButtons,
  showFirstButton,
  onClickFirstButton,
  onClickSecondButton,
  onClick,
}) => {
  const classes = ["join-movement-card-wrapper"];

  if (classNameWrapper) classes.push(classNameWrapper);

  return (
    <div className={classes.join(" ")} onClick={onClick}>
      <div className="sticker-img-wrapper">
        <div className="join-movement-card-img-block">
          <img src={item?.img} alt="movement" />
        </div>

        {stickerLive && (
          <p className="join-movement-sticker">{stickerLive || "Live"}</p>
        )}
      </div>

      <div className="join-movement-card_content">
        {(item?.title || item?.description) && (
          <p className="join-movement-card-title">
            {item?.title}
            {item?.title && item?.description && ":"} {item?.description}
          </p>
        )}

        {item?.secondDescription && (
          <p className="join-movement-card-desc">{item?.secondDescription}</p>
        )}

        {(item?.price || item?.percentage) && (
          <div className="price-percentage-wrapper">
            <p className="price">
              <span style={{ color: "#555768" }}>Total Amount: </span>
              {item?.price}
            </p>

            {/*<div className="percentage-wrapper">*/}
            {/*  <div*/}
            {/*    className="percentage-block"*/}
            {/*    style={{ width: item?.percentage }}*/}
            {/*  />*/}
            {/*</div>*/}
          </div>
        )}

        {allowButtons && (
          <div className="buttons-block">
            {showFirstButton && (
              <ButtonMain
                classNameWrapper="donate-now-button"
                text="Donate now"
                type="button"
                onClick={onClickFirstButton}
              />
            )}

            <ButtonMain
              classNameWrapper="see-detail"
              text="See Details"
              type="button"
              onClick={onClickSecondButton}
            />
          </div>
        )}
      </div>
    </div>
  );
};
