import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FindSuccess } from "utils";
import { httpRequestsOnSuccessActions } from "store";
import { FundraiserInfo } from "./fundraiserInfo";
import { EditFundraiser } from "./editFundraiser";
import { EDIT_FUNDRAISER_ACTION } from "./constants";

export const FundraiserDetailScreen = ({ isMemory }) => {
  const [editMode, setEditMode] = useState(false);
  const success = FindSuccess(EDIT_FUNDRAISER_ACTION);
  const dispatch = useDispatch();

  useEffect(() => {
    if (success) {
      setEditMode(false);
      dispatch(
        httpRequestsOnSuccessActions.removeSuccess(EDIT_FUNDRAISER_ACTION)
      );
    }
  }, [success]);

  return (
    <div>
      {editMode ? (
        <EditFundraiser setEditMode={setEditMode} isMemory={isMemory} />
      ) : (
        <FundraiserInfo setEditMode={setEditMode} isMemory={isMemory} />
      )}
    </div>
  );
};
