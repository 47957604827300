import {
  DateRow,
  ImageAndName,
  MobileCardBody,
  MobileCardHead,
  PriceRow,
  TextRow,
} from "components";
import { Svg } from "assets";

export const charityDetailBreadcrumb = [
  { title: "Charities", to: "/charities" },
  { title: "Charities Details" },
];
export const charityTabEnum = {
  DETAILS: "DETAILS",
  BANK_ACCOUNT: "BANK_ACCOUNT",
  FUNDRAISES: "FUNDRAISES",
};

export const charityDetailTabs = [
  { label: "Details", value: charityTabEnum.DETAILS },
  { label: "Bank Account", value: charityTabEnum.BANK_ACCOUNT },
  { label: "Event", value: charityTabEnum.FUNDRAISES },
];

export const EDIT_CHARITY_ACTION = "EDIT_CHARITY";

export const CHARITY_BY_ID = "GET_CHARITY_BY_ID";

export const PUBLISH_ACTION = "CHANGE_FUNDRAISER_STATUS";

export const CHANGE_CHARITY_STATUS = "CHANGE_CHARITY_STATUS";
export const GET_MY_BANK = "GET_MY_BANK";
export const CHARITY_FUNDRAISER_TABLE_ACTION_TYPE = "GET_FUNDRAISERS";

export const charityFundraisersHead = (width) => {
  return width > 1279
    ? [
        { title: "Event Name", sortType: "name" },
        { title: "Created by", sortType: "owner" },
        { title: "End Date", sortType: "endDate" },
        { title: "Category", sortType: "category" },
        { title: "Balance", sortType: "balance" },
      ]
    : [
        { title: "Event Name", sortType: "name" },
        { title: "End Date", sortType: "endDate" },
        { title: "Balance", sortType: "balance" },
      ];
};

export const charityFundraisersBody = (width) => {
  return width > 1279
    ? [
        {
          rowText: (item) => (
            <ImageAndName
              text={item?.name}
              src={
                item?.images?.length
                  ? item?.images[item?.mainImage || 0]?.url
                  : null
              }
              noSrc={<Svg.NoFundraiserImage />}
            />
          ),
        },
        {
          rowText: (item) => <TextRow text={item?.owner?.username} />,
        },
        {
          rowText: (item) => <DateRow date={item?.endDate} />,
        },
        {
          rowText: () => (
            <div>
              <h1>a</h1>
            </div>
          ),
        },
        {
          rowText: (item) => <PriceRow info={item?.balance} />,
        },
      ]
    : [
        {
          rowText: (item) => (
            <ImageAndName
              text={item?.name}
              src={
                item?.images?.length
                  ? item?.images[item?.mainImage || 0]?.url
                  : null
              }
              noSrc={<Svg.NoFundraiserImage />}
            />
          ),
        },
        {
          rowText: (item) => <DateRow date={item?.endDate} />,
        },
        {
          rowText: (item) => <PriceRow info={item?.balance} />,
        },
      ];
};

export const fundraiserMobileCard = [
  {
    rowText: (item) => (
      <MobileCardHead
        src={
          item?.images?.length ? item?.images[item?.mainImage || 0]?.url : null
        }
        noSrc={<Svg.NoFundraiserImage />}
        value={item?.name}
      />
    ),
  },
  {
    rowText: (item) => (
      <MobileCardBody
        title="Created by"
        value={<TextRow text={item?.owner?.username} />}
      />
    ),
  },
  {
    rowText: (item) => (
      <MobileCardBody
        title="Category"
        value={<TextRow text={item?.category} />}
      />
    ),
  },
  {
    rowText: (item) => (
      <MobileCardBody
        title="End Date"
        value={<DateRow date={item?.endDate} />}
      />
    ),
  },
  {
    rowText: (item) => (
      <MobileCardBody
        title="Balance"
        value={<PriceRow info={item?.balance} />}
      />
    ),
  },
];
