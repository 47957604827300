import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Tooltip } from "@mui/material";
import { toast } from "react-toastify";
import { DeleteModal } from "components";
import { transferActions } from "store";
import { FindError, useModal } from "utils";
import { Svg } from "assets";
import { ActionType, TransferStatus, TransferStatusError } from "./constants";

export const TransferStatusesRow = ({ item }) => {
  const [saveStatus, setSavedStatus] = useState("");
  const dispatch = useDispatch();
  const { openModal, close } = useModal();
  const beckError = FindError(ActionType);

  useEffect(() => {
    if (beckError) {
      close();
      toast.error(TransferStatusError[saveStatus]);
      setSavedStatus("");
    }
  }, [beckError]);

  const text =
    item?.status === TransferStatus.NO_ACCOUNT
      ? "No Account"
      : item?.status === TransferStatus.ACCOUNT_TRANSFER_ERROR
      ? "Error to Account"
      : item?.status === TransferStatus.BANK_TRANSFER_ERROR
      ? "Error to Bank"
      : "Success";

  const classes =
    item?.status === TransferStatus.NO_ACCOUNT
      ? "no-account"
      : item?.status === TransferStatus.ACCOUNT_TRANSFER_ERROR ||
        item?.status === TransferStatus.BANK_TRANSFER_ERROR
      ? "error-account"
      : "success-message";

  const toolTipText = TransferStatusError[item?.status];

  return (
    <div className="align-center" style={{ gap: 8 }}>
      {item?.status === TransferStatus.SUCCESS ? (
        <div className={`transfer-status-box-messages ${classes}`}>
          <Svg.Ellipse />
          <p>{text}</p>
        </div>
      ) : (
        <div className={`transfer-status-box-messages ${classes}`}>
          <Svg.Ellipse />
          <Tooltip title={toolTipText} arrow placement="left">
            <p>{text}</p>
          </Tooltip>
        </div>
      )}
      {item?.status !== TransferStatus.SUCCESS && (
        <button
          className="reset-btn"
          onClick={(e) => {
            e.stopPropagation();
            openModal(
              <DeleteModal
                buttonMainStyles={{ background: "#34A853" }}
                customIcon={<Svg.AssetSvg />}
                title={"Are you sure you want to retransfer this transaction?"}
                description={"This action cannot be undone."}
                handleDelete={() => {
                  setSavedStatus(item?.status);
                  dispatch(transferActions.reTransfers(item?.id));
                }}
                actionType={ActionType}
              />,
              {
                padding: "noPaddings",
                noShowCloseBrn: true,
              }
            );
          }}
        >
          <Svg.ConvertCardGreen />
        </button>
      )}
    </div>
  );
};
