import React from "react";

export const Percentage = ({
  price,
  // percentage,
  // backgroundPercentage,
  // background,
  color,
}) => {
  return (
    <div className="main-percentage-wrapper">
      {price && (
        <p className="main-price" style={{ color: color ? color : "" }}>
          <span>Total Contributions: </span> {price}
        </p>
      )}

      {/*<div className="percentage-box" style={{ background }}>*/}
      {/*  <div*/}
      {/*    className="percentage-block"*/}
      {/*    style={{ width: percentage, background: backgroundPercentage }}*/}
      {/*  />*/}
      {/*</div>*/}
    </div>
  );
};
