import {
  CREATE_DONATION,
  EDIT_DONATION,
  GET_DONATION_BY_ID,
  GET_DONATIONS,
  GET_DONATIONS_FUNDRAISER,
} from "./donation.types";

export const createDonations = (body) => {
  return {
    type: CREATE_DONATION,
    payload: { body },
  };
};

export const editDonations = (id, body) => {
  return {
    type: EDIT_DONATION,
    payload: { id, body },
  };
};

export const getDonations = (params) => {
  return {
    type: GET_DONATIONS,
    payload: { params },
  };
};

export const getDonationsFundraiser = (params) => {
  return {
    type: GET_DONATIONS_FUNDRAISER,
    payload: { params },
  };
};

export const getDonationById = (id) => {
  return {
    type: GET_DONATION_BY_ID,
    payload: id,
  };
};
