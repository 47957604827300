import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import moment from "moment/moment";
import {
  ButtonMain,
  FullTable,
  FullTableAccordionMain,
  PriceConvertor,
  ReadMore,
  SearchInput,
  ShareBtn,
} from "components";
import { Percentage } from "fragments";
import { CharityDetails } from "./charityDetails";
import { fundraiserStatuses } from "../../fundraisers";
import { useModal, useWindowDimensions } from "utils";
import { Images, Svg } from "assets";
import { CHARITY_TABLE_ACTION_TYPE } from "../../charities/fragments";
import {
  contributionsAccordionDetails,
  contributionsBody,
  contributionsHead,
} from "./constants";
import { ContributionCard } from "./contributionCard";

export const EventInfo = () => {
  const [showMoreCharities, setShowMoreCharities] = useState(false);
  const { fundraiserById, donationFundraiser } = useSelector((state) => ({
    fundraiserById: state.fundraiser.fundraiserById,
    donationFundraiser: state.donation.donationFundraiser,
  }));

  const navigate = useNavigate();
  const { openModal } = useModal();
  const { width } = useWindowDimensions();
  const isMemory = fundraiserById?.type === "MEMORY";

  const showCharity = fundraiserById?.charities?.length
    ? fundraiserById?.charities.slice(0, 2)
    : [];

  const showedAnotherCharity = fundraiserById?.charities?.length
    ? fundraiserById?.charities.slice(2)
    : [];

  return (
    <>
      <div className="event-details-info-box-desktop">
        <div className="event-details-info-box">
          <div className="event-details_img-charities">
            <div className="event-details-image">
              <img
                src={
                  fundraiserById?.images?.length
                    ? fundraiserById?.images[
                        fundraiserById?.mainImage
                          ? fundraiserById?.mainImage
                          : 0
                      ]?.url
                    : isMemory
                    ? Images.needCardFrame
                    : Images.needCardFrameWhite
                }
                alt="compaign"
              />
            </div>
            {!!showCharity?.length && (
              <div
                className="event-details-charities-wrapper"
                style={{ background: isMemory ? "#1C212D" : "" }}
              >
                <h3
                  className="event-details-charities-title"
                  style={{ color: isMemory ? "#E0E1E2" : "" }}
                >
                  Charities
                </h3>
                <div>
                  {!!showCharity.length && (
                    <div className="event-details-charities-box">
                      {showCharity?.map((item, index) => (
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            openModal(
                              <CharityDetails
                                charity={item}
                                isMemory={isMemory}
                              />,
                              {
                                padding: "noPaddings",
                                noShowCloseBrn: true,
                              }
                            );
                          }}
                          type={"button"}
                          className="event-details-charity"
                          key={index}
                          style={{ background: isMemory ? "#1C212D" : "" }}
                        >
                          <div className="event-details-charities_svg">
                            <Svg.FundraiserFrameSvg />
                          </div>

                          <p style={{ color: isMemory ? "#E0E1E2" : "" }}>
                            {item?.charity?.name}
                          </p>
                        </button>
                      ))}
                    </div>
                  )}

                  {!!showedAnotherCharity.length && (
                    <div>
                      {showMoreCharities && (
                        <div
                          className="event-details-charities-box"
                          style={{ marginTop: "4px" }}
                        >
                          {showedAnotherCharity?.map((item, index) => (
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                openModal(
                                  <CharityDetails
                                    charity={item}
                                    isMemory={isMemory}
                                  />,
                                  {
                                    padding: "noPaddings",
                                    noShowCloseBrn: true,
                                  }
                                );
                              }}
                              type={"button"}
                              className="event-details-charity"
                              key={index}
                            >
                              <div className="event-details-charities_svg">
                                <Svg.FundraiserFrameSvg />
                              </div>

                              <p>{item?.charity?.name}</p>
                            </button>
                          ))}
                        </div>
                      )}

                      <button
                        onClick={() =>
                          setShowMoreCharities((prevState) => !prevState)
                        }
                        className="event-details-charities_see-button"
                      >
                        {showMoreCharities
                          ? "Hide More Charities"
                          : "See More Charities"}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="event-details_info-organizer">
            <div
              className="event-details-info-section"
              style={{ background: isMemory ? "#1C212D" : "" }}
            >
              <div className="event-details-info_type-displayId">
                <p className="event-details-info-type">
                  {isMemory ? "In Memory of" : "In Celebration"}
                </p>

                {fundraiserById?.displayId && (
                  <p className="event-details-info-displayId">
                    {fundraiserById?.displayId}
                  </p>
                )}
              </div>

              <p
                className="event-details-name"
                style={{ color: isMemory ? "#E0E1E2" : "" }}
              >
                {fundraiserById?.name}
              </p>

              <div className="event-details-info_date">
                <div className="event-details-names-block">
                  {fundraiserById?.firstName && (
                    <p
                      className="event-details-firstName"
                      style={{ color: isMemory ? "#E0E1E2" : "" }}
                    >
                      {fundraiserById?.firstName}{" "}
                    </p>
                  )}
                  {fundraiserById?.lastName && (
                    <p
                      className="event-details-firstName"
                      style={{ color: isMemory ? "#E0E1E2" : "" }}
                    >
                      {fundraiserById?.lastName}{" "}
                    </p>
                  )}
                  {fundraiserById?.middleName && (
                    <p
                      className="event-details-firstName"
                      style={{ color: isMemory ? "#E0E1E2" : "" }}
                    >
                      {fundraiserById?.middleName}{" "}
                    </p>
                  )}
                </div>
                {fundraiserById?.celebrationDate && (
                  <p className="date-off-celebration">
                    Date of Celebration:{" "}
                    {moment(fundraiserById?.celebrationDate).format(
                      "MM/DD/YYYY"
                    )}
                  </p>
                )}

                {fundraiserById?.birthDate && (
                  <p
                    className="date-off-celebration"
                    style={{ color: isMemory ? "#E0E1E2" : "" }}
                  >
                    Date of Birth:{" "}
                    {moment(fundraiserById?.birthDate).format("MM/DD/YYYY")}
                  </p>
                )}

                {fundraiserById?.deathDate && (
                  <p
                    className="date-off-celebration"
                    style={{ color: isMemory ? "#E0E1E2" : "" }}
                  >
                    Date of Died:{" "}
                    {moment(fundraiserById?.deathDate).format("MM/DD/YYYY")}
                  </p>
                )}
              </div>

              {fundraiserById?.description && (
                // <div className="event-details-description-box">
                //   <p
                //     className="event-details-description-text"
                //     style={{ color: isMemory ? "#E0E1E2" : "" }}
                //   >
                //     {fundraiserById?.description}
                //   </p>
                // </div>

                <ReadMore
                  isMemory={isMemory}
                  className="reade-more-donation"
                  maxLength={180}
                  text={fundraiserById?.description}
                />
              )}

              <Percentage
                price={PriceConvertor({ price: fundraiserById?.balance })}
                percentage="59%"
                backgroundPercentage="#FFD736"
                background={isMemory ? "#0D111D" : ""}
                color={isMemory ? "#E0E1E2" : ""}
              />

              <div
                className="event-details-donate-and-share"
                style={{ background: isMemory ? "#1C212D" : "" }}
              >
                {fundraiserById?.status === fundraiserStatuses.LIVE && (
                  <ButtonMain
                    classNameWrapper={`event-details-donate-btn ${
                      isMemory ? "event-details-donate-btn-memory" : ""
                    }`}
                    startIcon={<Svg.HeartSvg />}
                    text="Donate now"
                    type="button"
                    onClick={() => navigate(`/donate/${fundraiserById?.id}`)}
                  />
                )}
                <ShareBtn background={"#F4F4F4"} />
              </div>
            </div>

            <div
              className="event-details-organizer"
              style={{ background: isMemory ? "#1C212D" : "" }}
            >
              <p
                className="event-details-organizer-title"
                style={{ color: isMemory ? "#E0E1E2" : "" }}
              >
                Created by
              </p>
              <div className="event-details-organizer-box">
                <div>
                  {fundraiserById?.owner?.avatar?.url ? (
                    <img src={fundraiserById?.owner?.avatar?.url} alt="org" />
                  ) : (
                    <Svg.noCreatorSvg />
                  )}
                </div>

                <div className="event-details-organizer-info">
                  {fundraiserById?.owner?.firstName &&
                    fundraiserById?.owner?.lastName && (
                      <p
                        style={{ color: isMemory ? "#E0E1E2" : "" }}
                      >{`${fundraiserById?.owner?.firstName} ${fundraiserById?.owner?.lastName}`}</p>
                    )}
                  <span style={{ color: isMemory ? "#E0E1E2" : "" }}>
                    Created{" "}
                    {moment(fundraiserById?.createdAt).format("MM/DD/YYYY")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!!donationFundraiser?.donations?.length && (
          <div
            className="contributions-wrapper"
            style={{ background: isMemory ? "#1C212D" : "" }}
          >
            <h2
              className="contributions-title"
              style={{ color: isMemory ? "#E0E1E2" : "" }}
            >
              Contributions
            </h2>

            <FullTable
              classNameWrapper={`contributions-full-table ${
                isMemory ? "contributions-full-table_isMemory" : ""
              }`}
              head={contributionsHead(width)}
              body={contributionsBody(width)}
              loadingType={CHARITY_TABLE_ACTION_TYPE}
              list={donationFundraiser?.donations}
            />
          </div>
        )}
      </div>

      <div className="event-details-info-box-mobile">
        <div className="event-details-info-box">
          <div className="event-details-image">
            <img
              src={
                fundraiserById?.images?.length
                  ? fundraiserById?.images[
                      fundraiserById?.mainImage ? fundraiserById?.mainImage : 0
                    ]?.url
                  : isMemory
                  ? Images.needCardFrame
                  : Images.needCardFrameWhite
              }
              alt="compaign"
            />
          </div>
          <div
            className="event-details-info-section"
            style={{ background: isMemory ? "#1C212D" : "" }}
          >
            <div className="event-details-info_type-displayId">
              <p className="event-details-info-type">
                {isMemory ? "In Memory of" : "In Celebration"}
              </p>

              {fundraiserById?.displayId && (
                <p className="event-details-info-displayId">
                  {fundraiserById?.displayId}
                </p>
              )}
            </div>

            <p
              className="event-details-name"
              style={{ color: isMemory ? "#E0E1E2" : "" }}
            >
              {fundraiserById?.name}
            </p>

            <div className="event-details-info_date">
              <div className="event-details-names-block">
                {fundraiserById?.firstName && (
                  <p
                    className="event-details-firstName"
                    style={{ color: isMemory ? "#E0E1E2" : "" }}
                  >
                    {fundraiserById?.firstName}{" "}
                  </p>
                )}
                {fundraiserById?.lastName && (
                  <p
                    className="event-details-firstName"
                    style={{ color: isMemory ? "#E0E1E2" : "" }}
                  >
                    {fundraiserById?.lastName}{" "}
                  </p>
                )}
                {fundraiserById?.middleName && (
                  <p
                    className="event-details-firstName"
                    style={{ color: isMemory ? "#E0E1E2" : "" }}
                  >
                    {fundraiserById?.middleName}{" "}
                  </p>
                )}
              </div>
              {fundraiserById?.celebrationDate && (
                <p className="date-off-celebration">
                  Date of Celebration:{" "}
                  {moment(fundraiserById?.celebrationDate).format("MM/DD/YYYY")}
                </p>
              )}

              {fundraiserById?.birthDate && (
                <p
                  className="date-off-celebration"
                  style={{ color: isMemory ? "#E0E1E2" : "" }}
                >
                  Date of Birth:{" "}
                  {moment(fundraiserById?.birthDate).format("MM/DD/YYYY")}
                </p>
              )}

              {fundraiserById?.deathDate && (
                <p
                  className="date-off-celebration"
                  style={{ color: isMemory ? "#E0E1E2" : "" }}
                >
                  Date of Died:{" "}
                  {moment(fundraiserById?.deathDate).format("MM/DD/YYYY")}
                </p>
              )}
            </div>

            {fundraiserById?.description && (
              <ReadMore
                isMemory={isMemory}
                className="reade-more-donation"
                maxLength={200}
                text={fundraiserById?.description}
              />
            )}

            <Percentage
              price={PriceConvertor({ price: fundraiserById?.balance })}
              percentage="59%"
              backgroundPercentage="#FFD736"
              background={isMemory ? "#0D111D" : ""}
            />

            <div
              className="event-details-donate-and-share"
              style={{ background: isMemory ? "#1C212D" : "" }}
            >
              {fundraiserById?.status === fundraiserStatuses.LIVE && (
                <ButtonMain
                  classNameWrapper={`event-details-donate-btn ${
                    isMemory ? "event-details-donate-btn-memory" : ""
                  }`}
                  startIcon={<Svg.HeartSvg />}
                  text="Donate now"
                  type="button"
                  onClick={() => navigate(`/donate/${fundraiserById?.id}`)}
                />
              )}
              <ShareBtn background={"#F4F4F4"} />
            </div>
          </div>

          {!!showCharity?.length && (
            <div
              className="event-details-charities-wrapper"
              style={{ background: isMemory ? "#1C212D" : "" }}
            >
              <h3
                className="event-details-charities-title"
                style={{ color: isMemory ? "#E0E1E2" : "" }}
              >
                Charities
              </h3>
              <div>
                {!!showCharity.length && (
                  <div className="event-details-charities-box">
                    {showCharity?.map((item, index) => (
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          openModal(
                            <CharityDetails
                              charity={item}
                              isMemory={isMemory}
                            />,
                            {
                              padding: "noPaddings",
                              noShowCloseBrn: true,
                            }
                          );
                        }}
                        type={"button"}
                        className="event-details-charity"
                        key={index}
                        style={{ background: isMemory ? "#1C212D" : "" }}
                      >
                        <div className="event-details-charities_svg">
                          <Svg.FundraiserFrameSvg />
                        </div>

                        <p style={{ color: isMemory ? "#E0E1E2" : "" }}>
                          {item?.charity?.name}
                        </p>
                      </button>
                    ))}
                  </div>
                )}

                {!!showedAnotherCharity.length && (
                  <div>
                    {showMoreCharities && (
                      <div
                        className="event-details-charities-box"
                        style={{ marginTop: "4px" }}
                      >
                        {showedAnotherCharity?.map((item, index) => (
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              openModal(
                                <CharityDetails
                                  charity={item}
                                  isMemory={isMemory}
                                />,
                                {
                                  padding: "noPaddings",
                                  noShowCloseBrn: true,
                                }
                              );
                            }}
                            type={"button"}
                            className="event-details-charity"
                            key={index}
                          >
                            <div className="event-details-charities_svg">
                              <Svg.FundraiserFrameSvg />
                            </div>

                            <p>{item?.charity?.name}</p>
                          </button>
                        ))}
                      </div>
                    )}

                    <button
                      onClick={() =>
                        setShowMoreCharities((prevState) => !prevState)
                      }
                      className="event-details-charities_see-button"
                    >
                      {showMoreCharities
                        ? "Hide More Charities"
                        : "See More Charities"}
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}

          <div
            className="event-details-organizer"
            style={{ background: isMemory ? "#1C212D" : "" }}
          >
            <p
              className="event-details-organizer-title"
              style={{ color: isMemory ? "#E0E1E2" : "" }}
            >
              Created by
            </p>
            <div className="event-details-organizer-box">
              <div>
                {fundraiserById?.owner?.avatar?.url ? (
                  <img src={fundraiserById?.owner?.avatar?.url} alt="org" />
                ) : (
                  <Svg.noCreatorSvg />
                )}
              </div>

              <div className="event-details-organizer-info">
                {fundraiserById?.owner?.firstName &&
                  fundraiserById?.owner?.lastName && (
                    <p
                      style={{ color: isMemory ? "#E0E1E2" : "" }}
                    >{`${fundraiserById?.owner?.firstName} ${fundraiserById?.owner?.lastName}`}</p>
                  )}
                <span style={{ color: isMemory ? "#E0E1E2" : "" }}>
                  Created{" "}
                  {moment(fundraiserById?.createdAt).format("MM/DD/YYYY")}
                </span>
              </div>
            </div>
          </div>
          {!!donationFundraiser?.donations?.length && (
            <div
              className="contributions-wrapper"
              style={{ background: isMemory ? "#1C212D" : "" }}
            >
              <h2
                className="contributions-title"
                style={{ color: isMemory ? "#E0E1E2" : "" }}
              >
                Contributions
              </h2>

              <SearchInput
                placeholder={"Search..."}
                searchKey={"name"}
                className={`contributions-search-input ${
                  isMemory ? "contributions-search-input_memory" : ""
                }`}
              />

              {width > 767 ? (
                <FullTableAccordionMain
                  classNameWrapper={`contributions-accordion ${
                    isMemory ? "contributions-accordion_memory" : ""
                  }`}
                  head={contributionsHead(width)}
                  body={contributionsBody(width)}
                  accordionDetails={contributionsAccordionDetails}
                  list={donationFundraiser?.donations}
                />
              ) : (
                <div
                  className={`contribution-cards-block ${
                    isMemory ? "contribution-cards-block-memory" : ""
                  }`}
                >
                  {donationFundraiser?.donations.map((item, index) => (
                    <ContributionCard item={item} key={index} />
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
