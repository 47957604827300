import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { PersonalDetails } from "./personalDetails";
import { BankAccountUser } from "./bankAccountUser";
import { authActions, httpRequestsOnSuccessActions } from "store";
import { CONNECT_NON_PROFIT, showModals } from "../constants";
import { FindSuccess } from "utils";

export const ConnectBankAccount = () => {
  const [currentModal, setCurrentModal] = useState(showModals.PERSONAL_DETAILS);
  const dispatch = useDispatch();
  const success = FindSuccess(CONNECT_NON_PROFIT);

  useEffect(() => {
    dispatch(authActions.getMyProfile());
  }, [dispatch, currentModal]);

  useEffect(() => {
    if (success) {
      setCurrentModal(showModals.BANK_ACCOUNT);
      dispatch(httpRequestsOnSuccessActions.removeSuccess(CONNECT_NON_PROFIT));
    }
  }, [success]);

  return (
    <div>
      {currentModal === showModals.PERSONAL_DETAILS ? (
        <PersonalDetails />
      ) : (
        <BankAccountUser />
      )}
    </div>
  );
};
