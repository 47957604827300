import * as React from "react";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Radio } from "@mui/material";

export const SelectRadio = ({
  labelHeader,
  control,
  name,
  rules,
  placeholder,
  list,
  renderLabel,
  defaultValue,
}) => {
  return (
    <div className="accordion-wrapper">
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field, field: { onChange }, formState: { errors } }) => {
          return (
            <>
              {labelHeader && (
                <p
                  className={`input-main-label-header ${
                    errors[field?.name]?.message ? "errorLabel" : ""
                  }`}
                >
                  {labelHeader}
                  {rules?.required && (
                    <span className="input-main-label-header-required">*</span>
                  )}
                </p>
              )}
              <Autocomplete
                {...field}
                error={!!errors[field?.name]?.message}
                sx={{
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderColor: errors[field?.name]?.message
                      ? "#D03325"
                      : "#9D9D9D",
                  },
                }}
                id="checkboxes-tags-demo"
                options={list}
                value={field.value}
                defaultValue={defaultValue}
                disableCloseOnSelect
                getOptionLabel={(option) => renderLabel(option)}
                onChange={(event, item) => {
                  onChange(item);
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Radio
                      style={{ marginRight: 8, color: "#808000" }}
                      checked={selected}
                    />
                    {renderLabel(option)}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} placeholder={placeholder} />
                )}
              />
              <p className="error-message">{errors[field?.name]?.message}</p>
            </>
          );
        }}
      />
    </div>
  );
};
