import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { httpRequestsOnSuccessActions } from "store";
import { FindSuccess } from "utils";
import { CharityInfo } from "./charityInfo";
import { EditCharity } from "./editCharity";
import { EDIT_CHARITY_ACTION } from "./constants";

export const CharityDetailsScreen = () => {
  const [editMode, setEditMode] = useState(false);
  const success = FindSuccess(EDIT_CHARITY_ACTION);
  const dispatch = useDispatch();

  useEffect(() => {
    if (success) {
      setEditMode(false);
      dispatch(httpRequestsOnSuccessActions.removeSuccess(EDIT_CHARITY_ACTION));
    }
  }, [success]);

  return (
    <div>
      {editMode ? (
        <EditCharity setEditMode={setEditMode} />
      ) : (
        <CharityInfo setEditMode={setEditMode} />
      )}
    </div>
  );
};
