import React from "react";
import { ButtonMain } from "components";
import { useModal } from "utils";
import { Svg } from "assets";
import { AuthModalHeaders, IfRemember } from "./fragments";
import { ForgotPassword } from "./forgotPassword";

export const CheckEmail = () => {
  const { openModal } = useModal();

  const handleSubmit = () => {
    openModal(<ForgotPassword />);
  };

  return (
    <div className="auth-modals">
      <AuthModalHeaders
        title={"Check your email"}
        subtitle={
          "We have sent a password recover instructions to your email.If You did not receive the e-mail check your spam filter, or try another email address."
        }
        icon={<Svg.CheckEmail />}
      />
      <div className="form-box">
        <ButtonMain text="Try Again" type="submit" onClick={handleSubmit} />
        <IfRemember />
      </div>
    </div>
  );
};
