import axios from "axios";

export const donationService = {
  createDonationService: (body) => axios.post("/donation", body),

  editDonationService: (id, body) =>
    axios.patch(`/donation/${id}`, body, { auth: true }),

  getDonationsService: (params) => axios.get("/donation", { params: params }),

  getDonationsFundraiserService: (params) =>
    axios.get("/donation/fundraiser", { params: params }),

  getDonationByIdService: (id) => axios.get(`/donation/${id}`, { auth: true }),
};
