import React from "react";
import { ButtonMain } from "components";
import { useDispatch } from "react-redux";
import { Svg } from "assets";
import { FindLoad, useModal } from "utils";
import { bankAccountActions } from "store";
import { DELETE_CONNECT_STRIPE } from "./constants";

export const UpdateAccountDetails = ({ id }) => {
  const dispatch = useDispatch();
  const { close } = useModal();
  const loading = FindLoad(DELETE_CONNECT_STRIPE);

  const handleDelete = () => {
    dispatch(bankAccountActions.deleteConnectStripe(id));
  };

  return (
    <div className="update-account-details-wrapper">
      <Svg.AccountDetailsSvg className="update-account-details-svg" />
      <h3 className="update-account-details_title">Update Account Details</h3>
      <div className="update-account-details_content-block">
        <p className="update-account-details_content-title">Warning:</p>
        <p className="update-account-details_content">
          Updating your account details will delete the old information and
          replace it with new details. This action is irreversible.
          <br />
          <span style={{ fontWeight: 700 }}>
            Are you sure you want to proceed?
          </span>
        </p>
      </div>
      <div className="update-account-details-btn_block">
        <ButtonMain
          classNameWrapper="update-account-details-btns update-account-details-cancel_btn"
          text="Cancel"
          onClick={() => close()}
        />
        <ButtonMain
          classNameWrapper="update-account-details-btns"
          load={loading}
          onClick={handleDelete}
          text="Continue"
        />
      </div>
    </div>
  );
};
