import React from "react";
import { Svg } from "assets";

export const Requirements = ({ errorMessage }) => {
  return (
    <div className="requirements-wrapper">
      <Svg.CloseErrorSvg className="requirements-svg" />
      <p className="requirements-error-message">
        <span>Error: </span>
        {errorMessage}
      </p>
    </div>
  );
};
