import * as React from "react";
import Box from "@mui/material/Box";
import { DateRow, HeaderDrawerMain, TextRow } from "components";
import { Svg } from "assets";

export const DetailModal = ({ item }) => {
  return (
    <Box>
      <HeaderDrawerMain title="Transfer Details" />
      <div className="drawer-detail-box">
        <div className="drawer-details-head">
          <span>Event Name</span>
          <TextRow text={item?.fundraiser?.name} />
        </div>
        <div className="drawer-details-body">
          <div className="detail-box">
            <span>Date:</span>
            <DateRow date={item?.createdAt} />
          </div>
          <div className="detail-box">
            <span>Stripe transfer ID:</span>
            <TextRow text={item?.accountTransferId} />
          </div>
          <div className="detail-box">
            <span>Bank transfer ID:</span>
            <TextRow text={item?.bankTransferId} />
          </div>
        </div>
      </div>

      <div className="drawer-detail-footer">
        {item?.status !== "SUCCESS" && item?.status !== "PENDING" && (
          <div className="drawer-detail-error-box">
            <Svg.ErrorCircle />
            <p>
              {item?.status === "NO_ACCOUNT"
                ? "No Account"
                : "Error: Transfer to Connected Account Failed"}
            </p>
          </div>
        )}
        {/*{item?.status !== "SUCCESS" &&*/}
        {/*  item?.status !== "NO_ACCOUNT" &&*/}
        {/*  ifAdmin && (*/}
        {/*    <ButtonMain startIcon={<Svg.ConvertCard />} text={"Retransfer"} />*/}
        {/*  )}*/}
      </div>
    </Box>
  );
};
