import { DateRow, PriceRow, TextRow } from "components";

export const ACTION_TYPE = "GET_FUNDRAISER_BY_ID";
export const ACTION_TYPE_DONATION = "GET_DONATIONS_FUNDRAISER";
export const ACTION_TYPE_DONATION_CREATE = "CREATE_DONATION";

export const contributionsHead = (width) => {
  return width > 1279
    ? [
        { title: "Name", sortType: "donorName" },
        { title: "Date", sortType: "createdAt" },
        { title: "Amount", sortType: "amount" },
        { title: "Message" },
      ]
    : [
        { title: "Name", sortType: "donorName" },
        { title: "Date", sortType: "createdAt" },
        { title: "Amount", sortType: "amount" },
      ];
};

export const contributionsBody = (width) => {
  return width > 1279
    ? [
        {
          rowText: (item) => <TextRow text={item?.donorName} />,
        },
        {
          rowText: (item) => <DateRow date={item?.createdAt} />,
        },
        {
          rowText: (item) => <PriceRow info={item?.amount} />,
        },
        {
          rowText: (item) => <TextRow text={item?.description} />,
        },
      ]
    : [
        {
          rowText: (item) => <TextRow text={item?.donorName} />,
          width: "33%",
        },
        {
          rowText: (item) => <DateRow date={item?.createdAt} />,
          width: "33%",
        },
        {
          rowText: (item) => <PriceRow info={item?.amount} />,
          width: "33%",
        },
      ];
};

export const contributionsAccordionDetails = (item) => {
  return <TextRow text={item?.description} />;
};

export const fakeDate = [
  {
    id: "1",
    name: "45454",
    createdAt: "01/01/07",
    message: "adasasdsadsa",
  },
  {
    id: "1",
    name: "45454",
    createdAt: "01/01/07",
    message: "adasasdsadsa",
  },
];
