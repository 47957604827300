import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { fundraiserActions } from "store";
import { SimpleTabs, TitleAndAction, SearchInput, FullTable } from "components";
import { Svg } from "assets";
import {
  tabList,
  fundraiserStatuses,
  FUNDRAISER_TABLE_ACTION_TYPE,
  fundraisersBody,
  fundraisersHead,
  fundraiserMobileCard,
} from "./fragments";
import { useWindowDimensions } from "utils";

export const Fundraisers = () => {
  const { myFundraisers } = useSelector((state) => ({
    myFundraisers: state.fundraiser.myFundraisers,
  }));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const info = location?.state;
  const { width } = useWindowDimensions();

  useEffect(() => {
    dispatch(fundraiserActions.resetFundraiserById());
  }, [dispatch]);

  useEffect(() => {
    const params = {
      ...info,
      limit: 10,
    };
    params.status = info?.tab ? info?.tab : fundraiserStatuses.LIVE;
    params.skip = info?.skip ? info?.skip : 0;
    delete params.page;
    delete params.tab;
    dispatch(fundraiserActions.getMyFundraisers(params));
  }, [dispatch, info]);

  return (
    <div className="fundraiser-page">
      <TitleAndAction
        title="Event"
        handleClick={() => navigate("/start-event")}
        btnText="Create Event"
        startIcon={<Svg.AddWhiteCircle />}
        mobileBtnText="notShow"
      />
      <div className="tab-and-search">
        <SimpleTabs list={tabList} defaultTab={fundraiserStatuses.LIVE} />
        <SearchInput placeholder={"Search by name..."} searchKey={"name"} />
      </div>

      <FullTable
        head={fundraisersHead(width)}
        body={fundraisersBody(width)}
        mobileCard={fundraiserMobileCard}
        loadingType={FUNDRAISER_TABLE_ACTION_TYPE}
        list={myFundraisers?.fundraisers}
        listCount={myFundraisers?.count}
        handleClick={(item) => {
          navigate(`/events/${item?.id}`, {
            state: {
              eventType: item?.type,
            },
          });
        }}
      />
    </div>
  );
};
