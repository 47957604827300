import axios from "axios";

export const authService = {
  signUp: (body) => axios.post("/user", body),

  signIn: (body) => axios.post("/auth/signin", body),

  logOut: () => axios.post(`/auth/logout`, {}, { auth: true }),

  getLink: (email) => axios.get(`/auth/forgotPassword/${email}`),

  resetPass: (info) =>
    axios.post(
      "/auth/resetPassword",
      { ...info },
      { headers: { "reset-token": info.token } }
    ),

  changePasswordService: (data) =>
    axios.post("/auth/changePassword", data, { auth: true }),

  myProfileService: () => axios.get(`/user/myProfile`, { auth: true }),

  editAccountService: (data) => axios.patch(`/user`, data, { auth: true }),

  deleteAccountService: (id) => axios.delete(`/user/${id}`, { auth: true }),

  inviteUserService: (params) =>
    axios.patch(`/users/invite`, params, { auth: true }),

  searchService: (params) =>
    axios.post(`/search`, null, { params: { ...params } }),
};
