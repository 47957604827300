import { Svg } from "assets";

export const EditBtn = ({ handleClick }) => {
  return (
    <button className="edit-btn-box" onClick={handleClick}>
      <Svg.EditBlueSvg />
      <p>Edit</p>
    </button>
  );
};
