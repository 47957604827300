import {
  GET_DONATION_BY_ID_SUCCESS,
  GET_DONATIONS_FUNDRAISER_SUCCESS,
  GET_DONATIONS_SUCCESS,
} from "./donation.types";

const initialState = {
  donations: null,
  donationById: null,
  donationFundraiser: null,
};

export const donationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DONATIONS_SUCCESS:
      return {
        ...state,
        donations: action.payload,
      };

    case GET_DONATION_BY_ID_SUCCESS:
      return {
        ...state,
        donationById: action.payload,
      };

    case GET_DONATIONS_FUNDRAISER_SUCCESS:
      return {
        ...state,
        donationFundraiser: action.payload,
      };

    default:
      return state;
  }
};
