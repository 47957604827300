import { Svg } from "assets";

export const NotYet = ({ height, isMemory }) => {
  return (
    <div className="not-yet-section" style={height ? { height: height } : {}}>
      {isMemory ? (
        <Svg.NotYetMemory className="not-yet-section-svg" />
      ) : (
        <Svg.NotYet className="not-yet-section-svg" />
      )}
      <h1 style={{ color: isMemory ? "#E0E1E2" : "" }}>No Data Available!</h1>
      <p style={{ color: isMemory ? "#E0E1E2" : "" }}>
        {"It looks like you don't have any data yet."}
      </p>
    </div>
  );
};
