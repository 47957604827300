import * as React from "react";
import Popper from "@mui/material/Popper";
import { Svg } from "assets";
import { Box } from "@mui/material";
import { FindLoad } from "../../utils";
import { MinLoader } from "../loaders";

export function StatusPopper({ status, handleSelect, actionTye }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const loader = FindLoad(actionTye);
  const handleClick = (event) => {
    if (handleSelect) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectBtn = (selectedStatus) => {
    setAnchorEl(null);
    handleSelect(selectedStatus);
  };

  return (
    <div className="status-popper-wrapper">
      <button
        aria-describedby={id}
        onClick={handleClick}
        className={`status-active-inactive ${
          status === "ACTIVE" ? "active-btn" : "inactive-btn"
        }`}
      >
        {loader ? (
          <MinLoader color={"#000624"} margin={"0"} />
        ) : (
          <>
            <Svg.Ellipse />
            <p> {status === "ACTIVE" ? "Active" : "Inactive"}</p>
          </>
        )}
      </button>

      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className={"status-select-paper-box"}
        style={{ position: "absolute", zIndex: 9 }}
      >
        <Box>
          <button
            onClick={() =>
              handleSelectBtn(status === "ACTIVE" ? "INACTIVE" : "ACTIVE")
            }
            className={`status-active-inactive ${
              status === "ACTIVE" ? "inactive-btn" : "active-btn"
            }`}
          >
            <Svg.Ellipse />
            <p> {status === "ACTIVE" ? "Inactive" : "Active"}</p>
          </button>
        </Box>
      </Popper>

      {open && <div className="beck-drop" onClick={() => setAnchorEl(null)} />}
    </div>
  );
}
