import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import {
  ChooseCharity,
  DeceasedInformation,
  DistributeFunds,
  EventInfo,
  Stepper,
} from "./fragments";
import { LogIn } from "fragments";
import { SignUp } from "../../fragments/auth";
import { authActions } from "store";
import { ProfileInfo, SaveParams } from "utils";
import { Svg } from "assets";

export const InMemoryOf = () => {
  const [loginAccount, setLogin] = useState(true);
  const dispatch = useDispatch();
  const { token } = ProfileInfo();
  const navigator = useNavigate();
  const location = useLocation();
  const info = location?.state;
  const currentStep = info?.step;
  const dontRequestMyProfStep = [1, 2, 3, 7];

  useEffect(() => {
    if (token && !dontRequestMyProfStep.includes(info?.step)) {
      dispatch(authActions.getMyProfile());
    }
  }, [dispatch, token, info?.step]);

  useEffect(() => {
    if (token && currentStep === 3) {
      const params = {
        ...info,
        step: 4,
      };
      SaveParams(location?.pathname, navigator, params);
    }
  }, [token]);

  if (currentStep === 3 && !token) {
    return (
      <div className="in-memory-of-page">
        <div className="in-celebration-box">
          {currentStep === 3 && !token && (
            <div className="in-celebration-content">
              <div className="container">
                <div className="in-celebration-form-wrapper in-celebration-form-login">
                  {loginAccount ? (
                    <div className="create-account-wrapper-in-celebration">
                      <div className="in-celebration-form-header">
                        <p className="in-celebration-form-header-title">
                          Create Account
                        </p>
                      </div>
                      <SignUp
                        onLoginAccount={() => setLogin(false)}
                        classNameWrapper="in-celebration-login-wrapper"
                        classNameContent="in-celebration-form-content"
                      />
                    </div>
                  ) : (
                    <LogIn
                      onCreateAccount={() => setLogin(true)}
                      classNameWrapper="in-celebration-login-wrapper"
                      classNameContent="in-celebration-form-content"
                      classNameInput="celebration-input"
                      classNameButton="celebration-button"
                      icon={<Svg.SignInMemory />}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
          <Stepper />
        </div>
      </div>
    );
  }

  return (
    <div className="in-memory-of-page">
      {(currentStep === 1 || !currentStep) && <DeceasedInformation />}
      {currentStep === 2 && <EventInfo />}
      {currentStep === 4 && <DistributeFunds />}

      {/*{!myProfile?.pmtAccount?._id && currentStep === 5 && !ifAdmin && (*/}
      {/*  <PersonalDetails />*/}
      {/*)}*/}
      {/*{!myProfile?.pmtAccount?.bankId && currentStep === 6 && !ifAdmin && (*/}
      {/*  <ConnectBankAccount />*/}
      {/*)}*/}

      {currentStep === 5 && <ChooseCharity />}
    </div>
  );
};
