import { GET_MY_BANK_SUCCESS } from "./bankAccount.types";

const initialState = {
  bankAccount: null,
};

export const bankAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MY_BANK_SUCCESS:
      return {
        ...state,
        bankAccount: action.payload,
      };

    default:
      return state;
  }
};
