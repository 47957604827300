import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import { EmailValidator, FindLoad, FindSuccess, SaveParams } from "utils";
import { AddressInput, ButtonMain, InputMain, PhoneInput } from "components";
import { charityRequestActions, httpRequestsOnSuccessActions } from "store";
import { CharityCreated } from "./charityCreated";

const ACTION_TYPE = "CREATE_CHARITY_REQUEST";

export const CharityDetails = ({
  toggleShowing,
  showCharityDetailsAndSuccess,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigator = useNavigate();
  const info = location?.state;
  const { handleSubmit, control, reset } = useForm();
  const successCreating = FindSuccess(ACTION_TYPE);
  const loadCreating = FindLoad(ACTION_TYPE);

  useEffect(() => {
    if (info?.newCharity) {
      reset(info?.newCharity);
    }
  }, [info]);

  useEffect(() => {
    if (successCreating?.type) {
      toggleShowing((prev) => ({
        ...prev,
        successCratingCharity: true,
      }));
      dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
    }
  }, [successCreating]);

  const goBack = () => {
    toggleShowing((prev) => ({
      ...prev,
      showCharityDetails: false,
    }));
  };

  const handleCreate = (data) => {
    const sendData = {
      ...data,
      phoneNumber: parseInt(data.phoneNumber.replace(/[^0-9]/g, "")),
    };
    const params = {
      ...info,
      newCharity: data,
    };

    SaveParams(location?.pathname, navigator, params);
    dispatch(charityRequestActions.createCharityRequest(sendData));
  };

  return (
    <>
      {!showCharityDetailsAndSuccess.successCratingCharity ? (
        <form
          className="in-celebration-box"
          onSubmit={handleSubmit(handleCreate)}
        >
          <div className="in-celebration-content">
            <div className="container">
              <div className="in-celebration-form-wrapper charity-details-in-celebration-wrapper">
                <div className="in-celebration-form-header">
                  <p className="in-celebration-form-header-title">
                    Charity Details
                  </p>

                  <p className="in-celebration-form-header-desc">
                    Please provide the requested charity information to send for
                    review by the admin.
                  </p>
                </div>

                <div className="in-celebration-form-content">
                  <InputMain
                    className="memory-input"
                    name="name"
                    control={control}
                    placeholder="ex. Save the Children"
                    labelHeader="Legal Name"
                    rules={{
                      required: "This Field is required",
                    }}
                  />

                  <InputMain
                    className="memory-input"
                    name="email"
                    type="email"
                    control={control}
                    placeholder="Enter your personal email address"
                    labelHeader="Email Address"
                    rules={{
                      required: "This Field is required",
                      pattern: {
                        value: EmailValidator,
                        message: "Must be valid email",
                      },
                    }}
                  />

                  <PhoneInput
                    className="memory-input"
                    placeholder="Enter your contact number"
                    name="phoneNumber"
                    control={control}
                    labelHeader="Phone Number"
                    rules={{
                      required: "Phone Number is required",
                    }}
                  />

                  <AddressInput
                    className="memory-input"
                    name="address"
                    control={control}
                    labelHeader="Address"
                    placeholder="Address"
                    rules={{
                      required: "Address is required",
                    }}
                  />
                </div>

                <div className="charity-details-buttons-block">
                  <ButtonMain
                    classNameWrapper="charity-details-cancel"
                    type="button"
                    text="Cancel"
                    onClick={goBack}
                  />
                  <ButtonMain
                    classNameWrapper="charity-details-submit"
                    type="submit"
                    text="Send Request"
                    load={loadCreating}
                    loadingColor="#ffffff"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      ) : (
        <CharityCreated toggleShowing={toggleShowing} />
      )}
    </>
  );
};
