import {
  CHANGE_FUNDRAISER_STATUS,
  COMPLETED_FUNDRAISER,
  CREATE_FUNDRAISER,
  DELETE_FUNDRAISER,
  EDIT_FUNDRAISER,
  GET_FUNDRAISER_BY_ID,
  GET_FUNDRAISERS,
  GET_FUNDRAISERS_SEARCHING,
  GET_MY_FUNDRAISERS,
  RESET_FUNDRAISER_BY_ID,
} from "./fundraiser.types";

export const createFundraiser = (body) => {
  return {
    type: CREATE_FUNDRAISER,
    payload: { body },
  };
};

export const editFundraiser = (id, body, load) => {
  return {
    type: EDIT_FUNDRAISER,
    payload: { id, body, load },
  };
};

export const getFundraisers = (params) => {
  return {
    type: GET_FUNDRAISERS,
    payload: { params },
  };
};

export const getFundraisersSearching = (params) => {
  return {
    type: GET_FUNDRAISERS_SEARCHING,
    payload: { params },
  };
};

export const getMyFundraisers = (params) => {
  return {
    type: GET_MY_FUNDRAISERS,
    payload: { params },
  };
};

export const getFundraiserById = (id) => {
  return {
    type: GET_FUNDRAISER_BY_ID,
    payload: { id },
  };
};
export const resetFundraiserById = () => {
  return {
    type: RESET_FUNDRAISER_BY_ID,
  };
};

export const changeFundraiserStatus = (id, status) => {
  return {
    type: CHANGE_FUNDRAISER_STATUS,
    payload: { id, status },
  };
};

export const deleteFundraiser = (id) => {
  return {
    type: DELETE_FUNDRAISER,
    payload: id,
  };
};

export const completedFundraiser = (id) => {
  return {
    type: COMPLETED_FUNDRAISER,
    payload: id,
  };
};
