import {
  DateRow,
  ImageAndName,
  MobileCardBody,
  MobileCardHead,
  PhoneRow,
  StatusPopper,
  TextRow,
} from "components";
import { Svg } from "assets";
import {
  LinkRow,
  MobileCardFooterRender,
} from "../../../components/table/tableRows";

export const tabList = () => [
  { label: "All", value: "ALL" },
  // { label: "Requested", value: "REQUESTED", count: count },
];

export const charityStatuses = {
  ALL: "ALL",
  REQUESTED: "REQUESTED",
};
export const charitiesHead = (width) => {
  return width > 1279
    ? [
        { title: "Charity Name", sortType: "name" },
        { title: "Creation Date", sortType: "createdAt" },
        { title: "Phone Number", sortType: "phoneNumber" },
        { title: "Email", sortType: "email" },
        { title: "Status" },
      ]
    : [
        { title: "Charity Name", sortType: "name" },
        { title: "Phone Number", sortType: "phoneNumber" },
        { title: "Status" },
      ];
};

export const charitiesBody = (width) => {
  return width > 1279
    ? [
        {
          rowText: (item) => (
            <ImageAndName
              text={item?.name}
              src={
                item?.images?.length ? item?.images[item?.mainImage].url : null
              }
              noSrc={<Svg.NoUserImage />}
            />
          ),
        },
        {
          rowText: (item) => <DateRow date={item?.createdAt} />,
        },
        {
          rowText: (item) => <PhoneRow phone={item?.phoneNumber} />,
        },
        {
          rowText: (item) => <TextRow text={item?.email} />,
        },
        {
          rowText: (item) => <StatusPopper status={item?.status} />,
          notClickable: true,
        },
      ]
    : [
        {
          rowText: (item) => (
            <ImageAndName
              text={item?.name}
              src={
                item?.images?.length ? item?.images[item?.mainImage].url : null
              }
              noSrc={<Svg.NoUserImage />}
            />
          ),
        },
        {
          rowText: (item) => <PhoneRow phone={item?.phoneNumber} />,
        },
        {
          rowText: (item) => <StatusPopper status={item?.status} />,
          notClickable: true,
        },
      ];
};

export const charityMobileCard = [
  {
    rowText: (item) => (
      <MobileCardHead
        src={item?.images?.length ? item?.images[item?.mainImage].url : null}
        noSrc={<Svg.NoUserImage />}
        value={item?.name}
      />
    ),
  },
  {
    rowText: (item) => (
      <MobileCardBody
        title="Phone Number"
        value={<PhoneRow phone={item?.phoneNumber} />}
      />
    ),
  },
  {
    rowText: (item) => (
      <MobileCardBody title="Email" value={<TextRow text={item?.email} />} />
    ),
  },
  {
    rowText: (item) => (
      <MobileCardBody title="Status" value={<TextRow text={item?.status} />} />
    ),
  },
];

export const CHARITY_TABLE_ACTION_TYPE = "GET_CHARITIES";
export const CREATE_ACTION_TYPE = "CREATE_CHARITY";
export const PENDING_CHARITY_TABLE_ACTION_TYPE = "GET_PENDING_CHARITIES";

export const pendingCharitiesHead = [
  { title: "User Name", sortType: "name" },
  { title: "Legal Name", sortType: "createdAt" },
  { title: "Phone Number", sortType: "phoneNumber" },
  { title: "Email", sortType: "email" },
  { title: "Action" },
];

export const pendingCharitiesBody = [
  {
    rowText: (item) => (
      <LinkRow
        text={`${item?.user?.firstName} ${item?.user?.lastName}`}
        link={"/users"}
      />
    ),
  },
  {
    rowText: (item) => <TextRow text={item?.name} />,
  },
  {
    rowText: (item) => <PhoneRow phone={item?.phoneNumber} />,
  },
  {
    rowText: (item) => <TextRow text={item?.email} />,
  },
  {
    button: (fn) => (
      <button onClick={() => fn("delete")} className="delete-btn-box">
        <Svg.RedTrashFill />
        <p>Delete</p>
      </button>
    ),
  },
];

export const pendingCharityMobileCard = [
  {
    rowText: (item) => <MobileCardHead value={item?.name} />,
  },
  {
    rowText: (item) => (
      <MobileCardBody
        title="User"
        value={
          <LinkRow
            text={`${item?.user?.firstName} ${item?.user?.lastName}`}
            link={"/users"}
          />
        }
      />
    ),
  },
  {
    rowText: (item) => (
      <MobileCardBody title="Email" value={<TextRow text={item?.email} />} />
    ),
  },
  {
    rowText: (item) => (
      <MobileCardBody
        title="Phone Number"
        value={<PhoneRow phone={item?.phoneNumber} />}
      />
    ),
  },

  {
    button: (fn) => (
      <MobileCardFooterRender
        value={
          <button
            onClick={() => fn("delete")}
            className="delete-btn-mobile-box"
          >
            <Svg.RedTrashFill />
            <p>Delete</p>
          </button>
        }
      />
    ),
  },
];

export const DELETE_PENDING_CHARITY = "DELETE_PENDING_CHARITIES";
