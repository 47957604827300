import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { JoinMovementCard } from "./joinMovementCard";
import { charityActions } from "store";
import { Images, Svg } from "assets";
import { useModal, useWindowDimensions } from "utils";
import "swiper/swiper-bundle.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { CharityDetails } from "../../eventDetails/fragments";

export const MakingDifference = () => {
  const [isNavigationDisabled, setIsNavigationDisabled] = useState(false);
  const { width } = useWindowDimensions();
  const { openModal } = useModal();
  const swiperRef = useRef(null);
  const dispatch = useDispatch();
  const { charities } = useSelector((state) => ({
    charities: state.charity.charities?.charities,
  }));

  useEffect(() => {
    dispatch(charityActions.getCharities());
  }, [dispatch]);

  const handleSlideChange = () => {
    if (swiperRef.current) {
      setIsNavigationDisabled({
        prev: swiperRef.current.swiper.isBeginning,
        next: swiperRef.current.swiper.isEnd,
      });
    }
  };

  const renderSlidesPerView = () => {
    if (width > 1279) {
      return 2.5;
    } else if (width > 767) {
      return 1.5;
    } else {
      return 1.2;
    }
  };

  return (
    <div className="making-difference-wrapper">
      <div className="container">
        <div className="making-difference-box">
          <div className="making-difference-header">
            <p className="green-text">Our Charities</p>
            <h2 className="making-difference-title">
              Making a Difference Together
            </h2>

            <div className="making-difference-desc-and-button">
              <p className="making-difference-desc">
                Discover the amazing work being done by the charities you can
                support through our platform. Here are a few examples of the
                impact your donations can make:
              </p>

              <div>
                <button
                  className="arrow-buttons"
                  onClick={() => swiperRef.current?.swiper.slidePrev()}
                  disabled={isNavigationDisabled.prev}
                >
                  <Svg.ArrowRightBlack className="arrow-buttons-svg arrow-buttons-svg-left" />
                </button>

                <button
                  className="arrow-buttons"
                  onClick={() => swiperRef.current?.swiper.slideNext()}
                  disabled={isNavigationDisabled.next}
                >
                  <Svg.ArrowRightBlack className="arrow-buttons-svg" />
                </button>
              </div>
            </div>
          </div>

          <Swiper
            ref={swiperRef}
            className="making-difference-carousel-wrapper"
            slidesPerView={renderSlidesPerView()}
            onSlideChange={handleSlideChange}
          >
            {!!charities?.length &&
              charities.map((item, index) => {
                const renderedParams = { ...item };
                delete renderedParams?.description;
                return (
                  <SwiperSlide key={index} virtualIndex={index}>
                    <JoinMovementCard
                      onClick={() =>
                        openModal(
                          <CharityDetails charity={{ charity: item }} />,
                          {
                            padding: "noPaddings",
                            noShowCloseBrn: true,
                          }
                        )
                      }
                      key={index}
                      classNameWrapper="making-difference-carousel-card"
                      item={{
                        ...renderedParams,
                        img: item?.images?.length
                          ? item?.images[item?.mainImage]?.url
                          : Images.joinImage,
                        title: item?.name,
                      }}
                      stickerLive={false}
                    />
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>
      </div>
    </div>
  );
};
