import { fundraiserStatuses } from "../../fundraisers";

export const ACTION_TYPES = "GET_FUNDRAISERS_SEARCHING";

export const renderStatus = (status) => {
  if (status === fundraiserStatuses.COMPETED) {
    return <div className="completed-status">Completed</div>;
  }
  if (status === fundraiserStatuses.LIVE) {
    return <div className="live-status">Live</div>;
  }
};
