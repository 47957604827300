import React from "react";
import { useNavigate } from "react-router";
import { ButtonMain } from "components";
import { Svg } from "assets";

export const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="not-found-wrapper">
      <div className="not-found-box">
        <Svg.NotFoundSvg className="not-found-svg" />
        <p className="not-found-title">Oops! Page Not Found</p>
        <p className="not-found-desc">
          We can't seem to find the page you're looking for. <br />
          But don't worry, here are a few options to get you back on track
        </p>

        <ButtonMain text="Go to Homepage" onClick={() => navigate("/")} />
      </div>
    </div>
  );
};
