export const SavePage = (navigator, info, anotherInfo, page) => {
  navigator(page, {
    state: {
      limit: 10,
      skip: info?.skip ? info?.skip : 0,
      page: info?.page ? info?.page : 1,
      ...anotherInfo,
    },
  });
};

export const SaveParams = (link, navigator, params) => {
  navigator(link, {
    state: params,
  });
};

export const SendPageSave = (navigate, number, info, page) => {
  const _skip = getSkipCount(number, 10);
  const pushInfo = { ...info };
  pushInfo.page = number;
  pushInfo.skip = _skip;

  navigate(page, {
    state: pushInfo,
  });
};

export const getSkipCount = (pageNumber = 0, limitNumber = 0) => {
  return pageNumber <= 1 ? 0 : (pageNumber - 1) * limitNumber;
};
