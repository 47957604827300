import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  bankAccountActions,
  charityActions,
  httpRequestsOnSuccessActions,
} from "store";
import { FindSuccess } from "utils";
import {
  BUSINESSTYPE,
  CONNECT_NON_PROFIT,
  DELETE_CONNECT_STRIPE,
  tabEnums,
} from "./constants";
import { NonProfit } from "./nonProfit";
import { PersonalDetails } from "./personalDetails";
import { BankAccount } from "./bankAccount";

export const ConnectBankAccount = ({ item }) => {
  const [sendInfo, setSendInfo] = useState(null);
  const [tab, setTab] = useState(tabEnums.NON_PROFIT);
  const dispatch = useDispatch();
  const successDeleting = FindSuccess(DELETE_CONNECT_STRIPE);
  const successCreatingStripe = FindSuccess(CONNECT_NON_PROFIT);
  // const { charityById } = useSelector((state) => ({
  //   charityById: state.charity.charityById,
  // }));

  useEffect(() => {
    if (successCreatingStripe) {
      dispatch(charityActions.getCharityById(item?.id));
      setTab(tabEnums.BANK_ACCOUNT);
      dispatch(httpRequestsOnSuccessActions.removeSuccess(CONNECT_NON_PROFIT));
    }
  }, [successCreatingStripe]);

  useEffect(() => {
    if (successDeleting) {
      setTab(tabEnums.NON_PROFIT);
      dispatch(httpRequestsOnSuccessActions.removeSuccess("GET_CHARITY_BY_ID"));
    }
  }, [successDeleting]);

  const handleSetInfo = (data, currentTab, selectedTab) => {
    const params = {
      email: item?.email,
      businessType: BUSINESSTYPE,
      ...sendInfo,
    };

    if (currentTab === tabEnums.NON_PROFIT) {
      const nonProfit = {
        ...data,
        charityId: item?.id,
      };
      nonProfit.businessPhone = parseInt(
        data?.businessPhone.replace(/[^0-9]/g, "")
      );

      params.nonProfit = nonProfit;
    }

    if (currentTab === tabEnums.PERSONAL_DETAILS) {
      const person = {
        ...data,
      };
      const dob = new Date(data.dateOfBirth);

      person.dob = {
        day: dob.getDate(),
        month: dob.getMonth() + 1,
        year: dob.getFullYear(),
      };
      delete person.dateOfBirth;
      person.phone = parseInt(data?.phone.replace(/[^0-9]/g, ""));

      params.nonProfit.person = person;
    }

    setSendInfo(params);
    setTab(selectedTab);

    if (currentTab === tabEnums.PERSONAL_DETAILS) {
      dispatch(bankAccountActions.connectNonProfit(params));
    }
  };

  return (
    <div className="settings-connect-bank-account-box">
      <div className="settings-connect-bank-account-header">
        {tab === tabEnums.NON_PROFIT && (
          <h3 className="settings-connect-bank-account-title">
            Nonprofit Details
          </h3>
        )}
        {tab === tabEnums.PERSONAL_DETAILS && (
          <h3 className="settings-connect-bank-account-title">
            Personal Details
          </h3>
        )}
        {tab === tabEnums.BANK_ACCOUNT && (
          <h3 className="settings-connect-bank-account-title">
            Connect Your Bank Account{" "}
          </h3>
        )}

        <div className="connect-bank-account-steps-wrapper">
          <div className="connect-bank-account-single-step-block">
            <div className="single-step_and_dots">
              <div
                className={`connect-bank-account-single-step ${
                  tab === tabEnums.NON_PROFIT ||
                  sendInfo?.nonProfit?.businessName
                    ? "active-tab"
                    : ""
                }`}
                // onClick={() => setTab(tabEnums.NON_PROFIT)}
              >
                1
              </div>
              <div className="connect-bank-account-step-dots" />
            </div>

            <p className="connect-bank-account-single-step-text">
              Account Details
            </p>
          </div>

          <div className="connect-bank-account-single-step-block">
            <div className="single-step_and_dots">
              <div
                className={`connect-bank-account-single-step ${
                  tab === tabEnums.PERSONAL_DETAILS ||
                  sendInfo?.nonProfit?.person
                    ? "active-tab"
                    : ""
                }`}
                // onClick={() => setTab(tabEnums.PERSONAL_DETAILS)}
              >
                2
              </div>
              <div className="connect-bank-account-step-dots" />
            </div>

            <p className="connect-bank-account-single-step-text">
              Personal Account
            </p>
          </div>

          <div className="connect-bank-account-single-step-block last-step">
            <div className="single-step_and_dots">
              <div
                className={`connect-bank-account-single-step ${
                  tab === tabEnums.BANK_ACCOUNT ? "active-tab" : ""
                }`}
                // onClick={() => setTab(tabEnums.BANK_ACCOUNT)}
              >
                3
              </div>
            </div>

            <p className="connect-bank-account-single-step-text">
              Bank Account
            </p>
          </div>
        </div>
      </div>

      {tab === tabEnums.NON_PROFIT && (
        <NonProfit handleSetInfo={handleSetInfo} />
      )}
      {tab === tabEnums.PERSONAL_DETAILS && (
        <PersonalDetails handleSetInfo={handleSetInfo} />
      )}
      {tab === tabEnums.BANK_ACCOUNT && (
        <BankAccount id={item?.id} handleSetInfo={handleSetInfo} />
      )}
    </div>
  );
};
