import React from "react";
import {
  HowItWorks,
  JoinMovement,
  JoinWith,
  MakingDifference,
  Transform,
} from "./fragments";
import { bannerCards } from "./fragments/constatnts";
import { ReadyCard } from "./fragments/readyCard";

export const Home = () => {
  return (
    <section className="home-page">
      <Transform />

      <div className="container">
        <div className="banner-cards-wrapper">
          {bannerCards.map(
            ({ title, items, buttonText, backColor, link }, index) => (
              <ReadyCard
                key={index}
                classNameWrapper="banner-card-item"
                title={title}
                items={items}
                backColor={backColor}
                link={link}
                buttonText={buttonText}
              />
            )
          )}
        </div>
      </div>

      <HowItWorks />

      <JoinMovement />

      <JoinWith />

      <MakingDifference />
    </section>
  );
};
