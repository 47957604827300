import React from "react";
// import { ButtonMain } from "components";
import { Svg } from "assets";
import { useNavigate } from "react-router";

export const ReadyCard = ({
  title,
  classNameWrapper,
  items = [],
  // buttonText,
  backColor,
  link,
}) => {
  const navigate = useNavigate();
  const className = classNameWrapper
    ? `ready-card-wrapper ${classNameWrapper}`
    : "ready-card-wrapper";

  const handleCreate = () => {
    navigate(link);
  };

  return (
    <div
      className={className}
      style={{ backgroundColor: backColor }}
      onClick={handleCreate}
    >
      <div className="ready-card_title-svg">
        <h3 className="ready-card-title">{title}</h3>
        <Svg.ArrowRightBlack className="ready-card_svg" />
      </div>

      <div className="ready-card-items-wrapper">
        {items?.map(({ text }, index) => (
          <div className="ready-card-item" key={index}>
            <Svg.CheckMarkSvg className="ready-card-svg" />
            <p className="ready-card-item-text">{text}</p>
          </div>
        ))}
      </div>

      {/*<ButtonMain*/}
      {/*  classNameWrapper="ready-card-button-wrapper"*/}
      {/*  ClassName="ready-card-button"*/}
      {/*  text={buttonText}*/}
      {/*  onClick={handleCreate}*/}
      {/*  endIcon={<Svg.ArrowRightBlack />}*/}
      {/*/>*/}
    </div>
  );
};
