import { CloseBtn } from "../buttons";

export const ModalHeader = ({ title }) => {
  return (
    <div className="modal-header-component">
      <h1 className="modal-title">{title}</h1>
      <CloseBtn />
    </div>
  );
};
