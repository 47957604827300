/** Actions */
import axios from "axios";

export { store } from "./store";
export { httpRequestsOnSuccessActions } from "./http_requests_on_success";
export { httpRequestsOnErrorsActions } from "./http_requests_on_errors";
export { httpRequestsOnLoadActions } from "./http_requests_on_load";

export { authActions } from "./auth";
export { fundraiserActions } from "./fundraiser";
export { userActions } from "./user";
export { charityActions } from "./charities";
export { charityRequestActions } from "./charityRequest";
export { donationActions } from "./donations";
export { bankAccountActions } from "./bankAccount";
export { transferActions } from "./transfers";

export const UploadServices = {
  UploadImage: (formData) =>
    axios.post("/files/upload?includeThumbnail=false", formData, {
      auth: true,
      params: "",
    }),

  UploadImageThumb: (formData) =>
    axios.post("/files/upload?includeThumbnail=true", formData, {
      auth: true,
      params: "",
    }),
};
