import { call, put, takeLatest } from "redux-saga/effects";
import { userService } from "./user.service";
import { EDIT_USER, GET_USERS, GET_USERS_SUCCESS } from "./user.types";
import { httpRequestsOnErrorsActions } from "../http_requests_on_errors";
import { httpRequestsOnLoadActions } from "../http_requests_on_load";
import { httpRequestsOnSuccessActions } from "../http_requests_on_success";
import { GET_MY_PROFILE } from "../auth/auth.types";

function* getUsers({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    const res = yield call(userService.getFundraisersService, payload?.params);
    yield put({
      type: GET_USERS_SUCCESS,
      payload: res.data,
    });
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.appendError(type, err?.data.message));
  } finally {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* editUser({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    yield call(userService.editUserService, payload?.id, payload?.body);
    yield put({
      type: GET_MY_PROFILE,
    });
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.appendError(type, err?.data.message));
  } finally {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

export const watchUser = function* () {
  yield takeLatest(GET_USERS, getUsers);
  yield takeLatest(EDIT_USER, editUser);
};
