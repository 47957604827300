import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  DetailPageWrapper,
  InputMain,
  ModalActions,
  PhoneInput,
  TextAreaMain,
} from "components";
import { charityActions } from "store";
import { EDIT_CHARITY_ACTION } from "./constants";

export const EditCharity = ({ setEditMode }) => {
  const { charityById } = useSelector((state) => ({
    charityById: state.charity.charityById,
  }));
  const dispatch = useDispatch();
  const { handleSubmit, control, reset } = useForm({});

  useEffect(() => {
    if (charityById) {
      reset({
        name: charityById?.name,
        phoneNumber: charityById?.phoneNumber,
        email: charityById?.email,
        description: charityById?.description,
      });
    }
  }, [charityById]);
  const handleReset = () => {
    setEditMode(false);
  };

  const onSubmit = (data) => {
    const params = {
      ...data,
    };
    if (data?.phoneNumber) {
      params.phoneNumber = data?.phoneNumber.replace(/\D/g, "");
    }
    dispatch(charityActions.editCharity(charityById?.id, params));
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DetailPageWrapper
          head={
            <>
              <p className="detail-page-title">Basic Information</p>
            </>
          }
          body={
            <>
              <div className="edit-fundraiser-inputs-box">
                <InputMain
                  name="name"
                  type="text"
                  control={control}
                  placeholder="Enter Charity Name"
                  labelHeader="Charity Name"
                  rules={{
                    required: "This Field is required",
                  }}
                />
                <PhoneInput
                  placeholder={"ex (000) 000-0000"}
                  name="phoneNumber"
                  control={control}
                  labelHeader="Phone Number"
                  rules={{
                    required: "This Field is required",
                  }}
                />
                <InputMain
                  name="email"
                  type="text"
                  control={control}
                  placeholder="example@gmail.com"
                  labelHeader="Email"
                  rules={{
                    required: "This Field is required",
                  }}
                />
              </div>
              <TextAreaMain
                name="description"
                type="text"
                control={control}
                placeholder="Write Description..."
                labelHeader="Description"
              />
            </>
          }
          footer={
            <div className="edit-fundraiser-save-cancel">
              <ModalActions
                closeFunction={handleReset}
                defaultStyle={true}
                type="submit"
                loadAction={EDIT_CHARITY_ACTION}
                saveTitle="Save"
              />
            </div>
          }
        />
      </form>
    </div>
  );
};
