import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  SimpleTabs,
  SearchInput,
  FullTable,
  FullTableAccordionMain,
} from "components";
import {
  tabList,
  transfersBody,
  transfersHead,
  transfersMobileCard,
  transferStatuses,
  GET_TRANSFERS,
} from "./fragments";
import { ProfileInfo, useWindowDimensions } from "utils";
import { transferActions } from "store";
import { transferAccordionDetails } from "./fragments/constants";

export const Transfers = () => {
  const { transfers } = useSelector((state) => ({
    transfers: state.transfer.transfers,
  }));
  const dispatch = useDispatch();
  const location = useLocation();
  const info = location?.state;
  const { ifAdmin } = ProfileInfo();
  const { width } = useWindowDimensions();

  useEffect(() => {
    const params = {
      ...info,
      limit: 10,
    };
    if (ifAdmin) {
      params.type = info?.tab ? info?.tab : transferStatuses.CHARITY;
    } else {
      params.type = transferStatuses.USER;
    }
    params.skip = info?.skip ? info?.skip : 0;
    delete params.page;
    delete params.tab;
    dispatch(transferActions.getTransfers(params));
  }, [dispatch, info]);

  return (
    <div className="transfers-page">
      <div className="tab-and-search">
        {ifAdmin && (
          <SimpleTabs list={tabList} defaultTab={transferStatuses.CHARITY} />
        )}
        {ifAdmin && (
          <SearchInput
            placeholder={"Search by charity..."}
            searchKey={"charityName"}
          />
        )}
      </div>

      {width > 767 && width < 1279 ? (
        <FullTableAccordionMain
          head={transfersHead(width, ifAdmin, info?.tab)}
          body={transfersBody(width, ifAdmin, "user")}
          accordionDetails={transferAccordionDetails}
          loadingType={GET_TRANSFERS}
          list={transfers?.transfers}
          listCount={transfers?.count}
        />
      ) : (
        <FullTable
          head={transfersHead(width, ifAdmin, info?.tab)}
          body={transfersBody(width, ifAdmin, info?.tab)}
          mobileCard={transfersMobileCard}
          loadingType={GET_TRANSFERS}
          list={transfers?.transfers}
          listCount={transfers?.count}
        />
      )}
    </div>
  );
};
