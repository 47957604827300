import { call, put, takeLatest } from "redux-saga/effects";
import {
  CHANGE_CHARITY_STATUS,
  CREATE_CHARITY,
  DELETE_CHARITY,
  DELETE_PENDING_CHARITIES,
  EDIT_CHARITY,
  GET_ALL_PENDING_CHARITIES,
  GET_ALL_PENDING_CHARITIES_SUCCESS,
  GET_CHARITIES,
  GET_CHARITIES_SUCCESS,
  GET_CHARITY_BY_ID,
  GET_CHARITY_BY_ID_SUCCESS,
  GET_PENDING_CHARITIES,
  GET_PENDING_CHARITIES_SUCCESS,
} from "./charity.types";
import { httpRequestsOnErrorsActions } from "../http_requests_on_errors";
import { httpRequestsOnLoadActions } from "../http_requests_on_load";
import { httpRequestsOnSuccessActions } from "../http_requests_on_success";
import { charityService } from "./charity.service";
import { Notify } from "utils";

function* createCharity({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    yield call(charityService.createCharityService, payload?.body);
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(
      httpRequestsOnErrorsActions.appendError(type, err?.data?.message)
    );
  } finally {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* editCharity({ payload, type }) {
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    yield call(charityService.editCharityService, payload?.id, payload?.body);
    Notify("Charity edited successfully");
    yield put({
      type: GET_CHARITY_BY_ID,
      payload: { id: payload?.id, load: payload?.load },
    });
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(
      httpRequestsOnErrorsActions.appendError(type, err?.data?.message)
    );
  } finally {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* getCharities({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    const res = yield call(charityService.getCharitiesService, payload?.params);
    yield put({
      type: GET_CHARITIES_SUCCESS,
      payload: res.data,
    });
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(
      httpRequestsOnErrorsActions.appendError(type, err?.data?.message)
    );
  } finally {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* getPendingCharities({ payload, type }) {
  if (payload?.load !== "noLoad") {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
  }
  yield put(httpRequestsOnErrorsActions.removeError(type));
  try {
    const res = yield call(
      charityService.getPendingCharitiesService,
      payload?.params
    );
    yield put({
      type: GET_PENDING_CHARITIES_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    yield put(
      httpRequestsOnErrorsActions.appendError(type, err?.data?.message)
    );
  } finally {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}
function* getAllPendingCharities({ payload, type }) {
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    const res = yield call(
      charityService.getPendingCharitiesService,
      payload?.params
    );
    yield put({
      type: GET_ALL_PENDING_CHARITIES_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    yield put(
      httpRequestsOnErrorsActions.appendError(type, err?.data?.message)
    );
  } finally {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}
function* deletePendingCharities({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));

  try {
    yield call(charityService.deletePendingCharities, payload?.id);
    yield put({
      type: GET_ALL_PENDING_CHARITIES,
    });
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(
      httpRequestsOnErrorsActions.appendError(type, err?.data?.message)
    );
  } finally {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* getCharityById({ payload, type }) {
  if (payload?.load !== "noLoad") {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
  }
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    const res = yield call(charityService.getCharityByIdService, payload?.id);
    yield put({
      type: GET_CHARITY_BY_ID_SUCCESS,
      payload: res.data,
    });
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.appendError(type, err?.data.message));
  } finally {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* deleteCharity({ payload, type }) {
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    yield call(charityService.deleteCharityService, payload);
    window.location.replace("/charities");
  } finally {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* changeCharityStatus({ payload, type }) {
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    yield call(
      charityService.changeCharityStatusService,
      payload?.id,
      payload?.body
    );
    Notify("Charity edited successfully");
    yield put({
      type: GET_CHARITY_BY_ID,
      payload: { id: payload?.id, load: "noLoad" },
    });
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(
      httpRequestsOnErrorsActions.appendError(type, err?.data?.message)
    );
  } finally {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

export const watchCharity = function* () {
  yield takeLatest(CREATE_CHARITY, createCharity);
  yield takeLatest(EDIT_CHARITY, editCharity);
  yield takeLatest(GET_CHARITIES, getCharities);
  yield takeLatest(GET_PENDING_CHARITIES, getPendingCharities);
  yield takeLatest(GET_ALL_PENDING_CHARITIES, getAllPendingCharities);
  yield takeLatest(DELETE_PENDING_CHARITIES, deletePendingCharities);
  yield takeLatest(GET_CHARITY_BY_ID, getCharityById);
  yield takeLatest(DELETE_CHARITY, deleteCharity);
  yield takeLatest(CHANGE_CHARITY_STATUS, changeCharityStatus);
};
