import React, { useState } from "react";
import { howItWorkTabs } from "./constatnts";

export const HowItWorks = () => {
  const [currentTab, setCurrentTab] = useState(howItWorkTabs[0].title);

  const currentTabItems = howItWorkTabs.find(
    ({ title }) => title === currentTab
  );
  return (
    <div className="how-it-works-wrapper">
      <div className="container">
        <div className="how-it-works-box">
          <h2 className="how-it-works-title">How It works</h2>

          <div className="how-it-works-tabs">
            {howItWorkTabs.map(({ title }, index) => (
              <div
                className={`how-it-works-tab ${
                  currentTab === title ? "active-tab" : ""
                }`}
                key={index}
                onClick={() => setCurrentTab(title)}
              >
                {title}
              </div>
            ))}
          </div>

          <div className="how-it-works-current-items-desktop">
            <div className="current-items-desktop-header">
              {currentTabItems.items.map(({ Icon }, j) => (
                <div
                  className={`border-svg-block ${
                    j % 2 !== 1 ? "width-fit-content" : ""
                  }`}
                  key={j}
                >
                  {j % 2 === 1 && (
                    <div className="current-desktop-item-border" />
                  )}

                  <div className="current-desktop-items-svg-block">
                    <Icon className="current-desktop-items-svg" />
                  </div>

                  {j % 2 === 1 && (
                    <div className="current-desktop-item-border" />
                  )}
                </div>
              ))}
            </div>

            <div className="current-items-desktop-content">
              {currentTabItems.items.map(({ title, description }, j) => (
                <div className="current-item-desktop-title_desc" key={j}>
                  <p className="how-it-works-current-desktop-item-title">
                    {title}
                  </p>

                  <p className="how-it-works-current-desktop-item-desc">
                    {description}
                  </p>
                </div>
              ))}
            </div>
          </div>

          <div className="how-it-works-current-items">
            {currentTabItems.items.map(
              ({ title, description, Icon }, index) => (
                <div
                  key={index}
                  className={`how-it-works-current-item-wrapper 
                  ${index === 0 ? "item-right-back" : ""} 
                  ${index === 1 ? "item-left-back" : ""} 
                  `}
                >
                  <div className="how-it-works-current">
                    <div className="how-it-works-current-item-svg-block">
                      <Icon className="how-it-works-current-item-svg" />
                    </div>

                    {index !== currentTabItems.items.length - 1 && (
                      <div className=" how-it-works-current-item-border" />
                    )}
                  </div>

                  <div className="current-item-title_desc">
                    <p className="how-it-works-current-item-title">{title}</p>

                    <p className="how-it-works-current-item-desc">
                      {description}
                    </p>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
