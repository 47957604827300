import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import {
  InputMain,
  ModalActions,
  ModalHeader,
  PhoneInput,
  TextAreaMain,
} from "components";
import { charityActions, httpRequestsOnSuccessActions } from "store";
import { EmailValidator, FindSuccess, SaveParams, useModal } from "utils";
import { CREATE_ACTION_TYPE } from "./fragments";

export const CreateCharity = () => {
  const dispatch = useDispatch();
  const { close } = useModal();
  const { handleSubmit, control } = useForm({});
  const success = FindSuccess(CREATE_ACTION_TYPE);
  const navigator = useNavigate();
  const location = useLocation();
  const info = location?.state;

  useEffect(() => {
    if (success?.type === CREATE_ACTION_TYPE) {
      dispatch(httpRequestsOnSuccessActions.removeSuccess(CREATE_ACTION_TYPE));
      close();
      SaveParams(location?.pathname, navigator, { ...info });
    }
  }, [success]);

  const onSubmit = (data) => {
    const params = {
      ...data,
    };
    if (data?.phoneNumber) {
      params.phoneNumber = data?.phoneNumber.replace(/\D/g, "");
    }
    dispatch(charityActions.createCharity(params));
  };

  return (
    <div className="create-charity-modal">
      <ModalHeader title="Create Charity" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-form-box">
          <InputMain
            name="name"
            type="text"
            control={control}
            placeholder="Enter Charity Name"
            labelHeader="Charity Name"
            rules={{
              required: "This Field is required",
            }}
          />
          <InputMain
            name="email"
            type="email"
            control={control}
            placeholder="example@gmail.com"
            labelHeader="Email"
            rules={{
              required: "This Field is required",
              pattern: {
                value: EmailValidator,
                message: "Must be valid email",
              },
            }}
          />
          <PhoneInput
            placeholder={"ex (000) 000-0000"}
            name="phoneNumber"
            control={control}
            labelHeader="Phone Number"
            rules={{
              required: "This Field is required",
            }}
          />
          <TextAreaMain
            name="description"
            type="text"
            control={control}
            placeholder="Write Description..."
            labelHeader="Description"
          />
        </div>
        <ModalActions
          type="submit"
          loadAction={CREATE_ACTION_TYPE}
          saveTitle="Save"
        />
      </form>
    </div>
  );
};
