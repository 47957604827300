import {
  connectBankAccount,
  connectNonProfit,
  getMyBank,
  deleteConnectStripe,
  updateConnectBank,
} from "./bankAccount.action";

export { bankAccountReducer } from "./bankAccount.reducer";
export { watchBankAccount } from "./bankAccount.saga";

export const bankAccountActions = {
  /** Bank Account Actions */
  connectNonProfit,
  connectBankAccount,
  getMyBank,
  deleteConnectStripe,
  updateConnectBank,
};
