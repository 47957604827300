import React from "react";
import { useForm } from "react-hook-form";
import {
  AddressInput,
  ButtonMain,
  CancelBtn,
  InputMain,
  PhoneInput,
} from "components";
import { tabEnums } from "./constants";
import { EmailValidator, useModal } from "utils";

export const NonProfit = ({ handleSetInfo }) => {
  const { close } = useModal();
  const { handleSubmit, control } = useForm({});

  const onSubmit = (data) => {
    handleSetInfo(data, tabEnums.NON_PROFIT, tabEnums.PERSONAL_DETAILS);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="settings-connect-modal-wrapper"
    >
      <div className="settings-connect-modal-body">
        <InputMain
          name="name"
          type="text"
          control={control}
          placeholder="ex. John"
          labelHeader="Company Name"
          rules={{
            required: "This Field is required",
          }}
        />
        <InputMain
          name="businessName"
          type="text"
          control={control}
          placeholder="Enter the commonly known name"
          labelHeader="Nonprofit Name"
          rules={{
            required: "This Field is required",
          }}
        />
        <InputMain
          name="businessEmail"
          type="email"
          control={control}
          placeholder="Provide your nonprofit email address"
          labelHeader="Nonprofit Email"
          rules={{
            required: "This Field is required",
            pattern: {
              value: EmailValidator,
              message: "Must be valid email",
            },
          }}
        />
        <PhoneInput
          placeholder={"Add your nonprofit contact number"}
          name="businessPhone"
          control={control}
          labelHeader="Nonprofit Phone"
          rules={{
            required: "Nonprofit Phone Number is required",
            minLength: {
              value: 10,
              message: "Please enter a valid 10 digit number.",
            },
            validate: (value) => {
              return (
                value?.replace(/[^0-9]/g, "").length === 10 ||
                "Please enter a valid 10 digit number."
              );
            },
          }}
        />

        <AddressInput
          className="celebration-input"
          name="address"
          control={control}
          labelHeader="Address"
          placeholder="Address"
          rules={{
            required: "Address is required",
          }}
        />
        <InputMain
          name="taxID"
          type="number"
          control={control}
          placeholder="Enter your nonprofit’s tax ID number"
          labelHeader="Tax ID"
          rules={{
            required: "This Field is required",
          }}
        />
      </div>

      <div className="settings-connect-modal-footer">
        <CancelBtn
          text={"Cancel"}
          closeFunction={() => {
            close();
          }}
        />
        <ButtonMain type={"submit"} text={"Next"} />
      </div>
    </form>
  );
};
