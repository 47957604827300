export const TermsAndConditions = () => {
  return (
    <div className="terms-and-conditions-page">
      <p className="terms-and-conditions-title">Terms & Conditions</p>
      <div className="terms-and-conditions-box">
        <p>
          Please read these terms and conditions carefully before using Our
          Service.
        </p>
        <p>
          <b>Introduction:</b>
          These Terms and Conditions govern your use of our truck management
          software. By using our software, you agree to be bound by these Terms
          and Conditions. If you do not agree to these Terms and Conditions, you
          must not use our software.
        </p>
        <p>
          <b>Use of Software: </b>
          Our software and its entire contents, features, and functionality are
          owned by us or our licensors and are protected by copyright,
          trademark, and other intellectual property or proprietary rights laws.
        </p>
        <p>
          <b>Disclaimer of Warranties: </b>
          We do not guarantee that our software will be uninterrupted,
          error-free, or free from viruses or other harmful components. We make
          no warranties or representations about the accuracy or completeness of
          the content provided through our software.
        </p>
        <p>
          <b>Limitation of Liability: </b>
          In no event shall we be liable for any indirect, incidental, special,
          or consequential damages arising out of or in connection with your use
          of our software.
        </p>
        <p>
          <b>Governing Law: </b>
          These Terms and Conditions shall be governed by and construed in
          accordance with the laws of [state/country], without giving effect to
          any choice of law or conflict of law provisions.
        </p>
        <p>
          <b>Termination: </b>
          We may terminate or suspend your access to our software immediately,
          without prior notice or liability, for any reason whatsoever,
          including without limitation if you breach these Terms and Conditions.
        </p>
      </div>
    </div>
  );
};
