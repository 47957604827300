import { Svg, Images } from "assets";

export const SocialLinks = [
  // { icon: <Svg.Twitter />, link: "/" },
  { icon: <Svg.Instagram />, link: "/" },
  { icon: <Svg.Facebook />, link: "/" },
  { icon: <Svg.EnvelopeSvg />, link: "/" },
  // { icon: <Svg.Youtube />, link: "/" },
];

export const AboutPages = [
  // { title: "About Us", link: "/" },
  { title: "Events", link: "/find-event" },
  // { title: "Contact Us", link: "/contact-us" },
  { title: "Terms of use", link: "/terms-and-conditions" },
  { title: "Privacy police", link: "/terms-and-conditions" },
];

export const CardAccepted = [
  { link: "/", img: Images.visaImg },
  { link: "/", img: Images.ameriaImg },
  { link: "/", img: Images.masterCardImg },
  { link: "/", img: Images.googlePayImg },
  { link: "/", img: Images.applePayImg },
];
