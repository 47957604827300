import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Svg } from "assets";
import { ButtonMain } from "components";
import { httpRequestsOnSuccessActions } from "store";
import { FindSuccess, useModal } from "utils";

export const DeleteModal = ({
  actionType,
  handleDelete,
  title,
  description,
  afterSuccess,
  customIcon,
  isMemory,
  buttonMainStyles,
}) => {
  const dispatch = useDispatch();
  const { close } = useModal();
  const success = FindSuccess(actionType);

  useEffect(() => {
    if (success) {
      close();
      dispatch(httpRequestsOnSuccessActions.removeSuccess(actionType));
      if (afterSuccess) {
        afterSuccess();
      }
    }
  }, [success]);

  return (
    <div
      className="delete-modal"
      style={{ background: isMemory ? "#0D111D" : "" }}
    >
      {customIcon ? customIcon : <Svg.DeleteCharity />}
      <div>
        <p
          style={{ color: isMemory ? "#E0E1E2" : "" }}
          className="delete-modal-title"
        >
          {title}
        </p>
        <p
          style={{ color: isMemory ? "#E0E1E2" : "" }}
          className="delete-modal-desc"
        >
          {description}
        </p>
      </div>
      <div className="flex-end" style={{ gap: 8 }}>
        <button
          style={{
            background: isMemory ? "#1C212D" : "",
            color: isMemory ? "#E0E1E2" : "",
          }}
          className="delete-modal-no-btn"
          onClick={close}
        >
          No
        </button>
        <ButtonMain
          buttonMainStyles={buttonMainStyles}
          actionTye={actionType}
          text="Yes"
          type="button"
          onClick={handleDelete}
        />
      </div>
    </div>
  );
};
