import React from "react";

export const JoinWithCard = ({ item, classNameWrapper }) => {
  return (
    <div
      className={`join-with-card-wrapper ${
        classNameWrapper ? classNameWrapper : ""
      }`}
    >
      <div className="join-with-card-header">
        <item.Icon className="join-with-card-header-svg" />
        <p className="join-with-card-header-title">{item?.title}</p>
      </div>

      <p className="join-with-card-desc">{item?.description}</p>
    </div>
  );
};
