import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import { DateInput, InputMain, TextAreaMain, Upload } from "components";
import { Stepper } from "./stepper";
import { generateDisplayId, ProfileInfo, SaveParams } from "utils";
import { Svg } from "assets";

export const EventInfo = () => {
  const [images, setImages] = useState([]);
  const [main, setMain] = useState(0);
  const location = useLocation();
  const navigator = useNavigate();
  const { handleSubmit, control, reset } = useForm();
  const { token } = ProfileInfo();
  const info = location?.state;

  useEffect(() => {
    if (info?.celebrationDetails) {
      reset(info?.celebrationDetails);
    }
  }, [info]);

  const handleCreate = (data) => {
    const params = {
      ...info,
      step: token ? 4 : 3,
    };

    params.celebrationDetails = {
      ...data,
      images: images,
      mainImage: main,
    };

    SaveParams(location?.pathname, navigator, params);
  };

  const handleSetMain = (main) => {
    setMain(main);
  };

  const handleUpload = (item) => {
    setImages((prev) => [...prev, item]);
  };

  const handleDeleteImage = (item, index) => {
    const newArr = [...images];
    newArr.splice(index, 1);
    setImages(newArr);
    setMain(0);
  };

  return (
    <form className="in-celebration-box" onSubmit={handleSubmit(handleCreate)}>
      <div className="in-celebration-content">
        <div className="container">
          <div className="in-celebration-form-wrapper event-info-wrapper">
            <div className="in-celebration-form-header">
              <p className="in-celebration-form-header-title">
                Event ID: {generateDisplayId(info.celebration)}
              </p>
            </div>

            <div className="in-celebration-form-content">
              <InputMain
                className="celebration-input"
                name="name"
                control={control}
                placeholder="ex. 20th birthday"
                labelHeader="Event a title"
                rules={{
                  required: "Title is required",
                  validate: {
                    trimmed: (value) =>
                      value.trim() !== "" ||
                      "Title cannot be empty or just spaces",
                  },
                }}
              />

              <DateInput
                className="celebration-input"
                name="endDate"
                control={control}
                placeholder="Choose End Date"
                label="Fundraiser Close Date"
                min={info?.celebration?.celebrationDate}
                rules={{
                  required: "End Date is required",
                }}
              />

              <TextAreaMain
                className="celebration-textarea"
                name="description"
                type="text"
                control={control}
                placeholder="Write Description about your event..."
                labelHeader="Description"
                rules={{
                  required: "Description is required",
                  validate: {
                    trimmed: (value) =>
                      value.trim() !== "" ||
                      "Description cannot be empty or just spaces",
                  },
                }}
              />

              <Upload
                UploadImage={<Svg.UploadImageCelebration />}
                classNameWrapper="in-celebration-form-uploader"
                handleSetMain={handleSetMain}
                handleChange={handleUpload}
                create={true}
                backImages={info?.celebrationDetails?.images}
                handleDelete={handleDeleteImage}
                setDefaultUrls={true}
              />
            </div>
          </div>
        </div>
      </div>

      <Stepper />
    </form>
  );
};
