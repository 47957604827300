import moment from "moment";

export const FundType = {
  CELEBRATION: "CELEBRATION",
  MEMORY: "MEMORY",
};

export const generateDisplayId = (info) => {
  const firstName = info?.firstName.replace(/\s+/g, "");

  const lastName = info.lastName.replace(/\s+/g, "");
  let displayId = firstName + lastName;

  if (info.deathDate) {
    const date = moment.utc(info.deathDate).format("MMDDYYYY");
    displayId += date;
  } else if (info.celebrationDate) {
    const date = moment.utc(info.celebrationDate).format("MMDDYYYY");
    displayId += date;
  }
  return displayId;
};
