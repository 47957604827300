import axios from "axios";

export const fundraiserService = {
  createFundraiserService: (body) =>
    axios.post("/fundraiser", body, { auth: true }),

  editFundraiserService: (id, body) =>
    axios.patch(`/fundraiser/${id}`, body, { auth: true }),

  getFundraisersService: (params) =>
    axios.get("/fundraiser", { params: params }),

  getFundraisersSearchService: (params) =>
    axios.get("/fundraiser/search", { params: params }),

  getMyFundraisersService: (params) =>
    axios.get("/fundraiser/my", { auth: true, params: params }),

  getFundraiserByIdService: (id) => axios.get(`/fundraiser/${id}`),

  changeFundraiserStatusService: (id, status) =>
    axios.patch(`/fundraiser/${id}/status`, { status: status }, { auth: true }),

  deleteFundraiserService: (id) =>
    axios.delete(`/fundraiser/${id}`, { auth: true }),

  completedFundraiserService: (id) =>
    axios.patch(`/fundraiser/${id}/completed`, {}, { auth: true }),
};
