import {
  DateRow,
  ImageAndName,
  MobileCardBody,
  MobileCardHead,
  PriceRow,
  TextRow,
} from "components";
import { Svg } from "assets";

export const tabList = [
  { label: "Live", value: "ACTIVE" },
  { label: "Completed", value: "COMPLETED" },
];

export const fundraiserStatuses = {
  LIVE: "ACTIVE",
  COMPETED: "COMPLETED",
  DRAFT: "DRAFT",
};

export const fundraisersHead = (width) => {
  return width > 1279
    ? [
        { title: "Event Name", sortType: "name" },
        { title: "Created by", sortType: "owner" },
        { title: "Created Date", sortType: "createdAt" },
        { title: "Type" },
        { title: "Balance", sortType: "balance" },
      ]
    : [
        { title: "Event Name", sortType: "name" },
        { title: "Created by", sortType: "owner" },
        { title: "Balance", sortType: "balance" },
      ];
};

export const fundraisersBody = (width) => {
  return width > 1279
    ? [
        {
          rowText: (item) => (
            <ImageAndName
              text={item?.name}
              src={
                item?.images?.length > 0
                  ? item?.images[item?.mainImage]?.url
                  : null
              }
              noSrc={<Svg.NoFundraiserImage />}
            />
          ),
        },
        {
          rowText: (item) => <TextRow text={item?.owner?.username} />,
        },
        {
          rowText: (item) => <DateRow date={item?.createdAt} />,
        },
        {
          rowText: (item) => (
            <TextRow
              text={
                item?.type === "CELEBRATION"
                  ? "In Celebration "
                  : "In Memory of"
              }
            />
          ),
        },
        {
          rowText: (item) => <PriceRow info={item?.balance} />,
        },
      ]
    : [
        {
          rowText: (item) => (
            <ImageAndName
              text={item?.name}
              src={
                item?.images?.length > 0
                  ? item?.images[item?.mainImage]?.url
                  : null
              }
              noSrc={<Svg.NoFundraiserImage />}
            />
          ),
        },
        {
          rowText: (item) => <TextRow text={item?.owner?.username} />,
        },
        {
          rowText: (item) => <PriceRow info={item?.balance} />,
        },
      ];
};

export const FUNDRAISER_TABLE_ACTION_TYPE = "GET_MY_FUNDRAISERS";
export const CREATE_ACTION_TYPE = "CREATE_FUNDRAISER";
export const UPLOAD_FUNDRAISER_IMAGE = "UPLOAD_FUNDRAISER_IMAGE";

export const fundraiserCategories = [
  { title: "Birthday", id: "Birthday" },
  { title: "Holiday", id: "Holiday" },
  { title: "Wedding", id: "Wedding" },
  { title: "Graduation", id: "Graduation" },
  { title: "Other", id: "Other" },
];

export const fundraiserMobileCard = [
  {
    rowText: (item) => (
      <MobileCardHead
        value={item?.name}
        src={
          item?.images?.length > 0 ? item?.images[item?.mainImage]?.url : null
        }
        noSrc={<Svg.NoFundraiserImage />}
      />
    ),
  },
  {
    rowText: (item) => (
      <MobileCardBody
        title="Created Date"
        value={<DateRow date={item?.createdAt} />}
      />
    ),
  },
  {
    rowText: (item) => (
      <MobileCardBody
        title="Type"
        value={
          <TextRow
            text={
              item?.type === "CELEBRATION" ? "In Celebration " : "In Memory of"
            }
          />
        }
      />
    ),
  },
  {
    rowText: (item) => (
      <MobileCardBody
        title="Balance"
        value={<PriceRow info={item?.balance} />}
      />
    ),
  },
];
