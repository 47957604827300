import { call, put, takeLatest } from "redux-saga/effects";
import {
  GET_TRANSFERS,
  GET_TRANSFERS_SUCCESS,
  RE_TRANSFER,
} from "./transfer.types";
import { httpRequestsOnErrorsActions } from "../http_requests_on_errors";
import { httpRequestsOnLoadActions } from "../http_requests_on_load";
import { httpRequestsOnSuccessActions } from "../http_requests_on_success";
import { transferService } from "./transfer.service";

function* getTransfers({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));

  try {
    const res = yield call(
      transferService.getTransfersService,
      payload?.params
    );
    yield put({
      type: GET_TRANSFERS_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(
      httpRequestsOnErrorsActions.appendError(type, err?.data?.message)
    );
  } finally {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* reTransfers({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));

  try {
    yield call(transferService.reTransfersService, payload?.id);
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(
      httpRequestsOnErrorsActions.appendError(type, err?.data?.message)
    );
  } finally {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

export const watchTransfer = function* () {
  yield takeLatest(GET_TRANSFERS, getTransfers);
  yield takeLatest(RE_TRANSFER, reTransfers);
};
