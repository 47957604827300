import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import {
  CheckboxesTags,
  DateInput,
  DetailPageWrapper,
  InputMain,
  ModalActions,
  TextAreaMain,
} from "components";
import { fundraiserActions } from "store";
import { fundraiserStatuses } from "../../fundraisers";
import { EDIT_FUNDRAISER_ACTION } from "./constants";

export const EditFundraiser = ({ setEditMode, isMemory }) => {
  const { charities, fundraiserById } = useSelector((state) => ({
    charities: state.charity.charities,
    fundraiserById: state.fundraiser.fundraiserById,
  }));
  const dispatch = useDispatch();
  const { handleSubmit, control, reset, setError, watch } = useForm({});
  const defaultCharities = fundraiserById?.charities?.map(
    (item) => item?.charity
  );

  const todayDate = moment().format("YYYY-MM-DD");

  defaultCharities?.forEach((obj) => {
    for (let key in obj) {
      if (key === "_id") {
        obj["id"] = obj["_id"];
        delete obj["_id"];
      }
    }
  });

  useEffect(() => {
    if (fundraiserById) {
      const params = {
        name: fundraiserById?.name,
        firstName: fundraiserById?.firstName,
        lastName: fundraiserById?.lastName,
        middleName: fundraiserById?.middleName,
        relationship: fundraiserById?.relationship,
      };
      // fundraiserById?.category
      //   ? (params.category = fundraiserById?.category)
      //   : null;

      params.charities = fundraiserById?.charities?.length
        ? defaultCharities
        : [];

      fundraiserById?.endDate
        ? (params.endDate = fundraiserById?.endDate)
        : null;

      fundraiserById?.celebrationDate
        ? (params.celebrationDate = fundraiserById?.celebrationDate)
        : null;
      fundraiserById?.description
        ? (params.description = fundraiserById?.description)
        : null;
      fundraiserById?.images ? (params.images = fundraiserById?.images) : null;
      fundraiserById?.mainImage
        ? (params.mainImage = fundraiserById?.mainImage)
        : null;

      fundraiserById?.endDate
        ? (params.endDate = moment
            .utc(fundraiserById?.endDate)
            .format("YYYY-MM-DD"))
        : null;

      fundraiserById?.celebrationDate
        ? (params.celebrationDate = moment
            .utc(fundraiserById?.celebrationDate)
            .format("YYYY-MM-DD"))
        : null;

      fundraiserById?.birthDate
        ? (params.birthDate = moment
            .utc(fundraiserById?.birthDate)
            .format("YYYY-MM-DD"))
        : null;

      fundraiserById?.deathDate
        ? (params.deathDate = moment
            .utc(fundraiserById?.deathDate)
            .format("YYYY-MM-DD"))
        : null;

      reset(params);
    }
  }, [fundraiserById]);

  const handleReset = () => {
    setEditMode(false);
  };

  const onSubmit = (data) => {
    const params = {
      ...data,
    };

    if (data?.charities?.length > 0) {
      params.charities = data.charities.map((item) => item.id || item._id);
    } else {
      params.charities = [];
    }
    // if (data?.category) {
    //   params.category = data.category.id || data.category;
    // }

    if (data?.endDate) {
      const endDate = new Date(data.endDate);
      endDate.setHours(23, 59, 59, 999);
      params.endDate = endDate;
    } else {
      delete params.endDate;
    }

    if (fundraiserById?.status === fundraiserStatuses.DRAFT) {
      if (data?.name) {
        params.status = fundraiserStatuses.DRAFT;
        dispatch(fundraiserActions.editFundraiser(fundraiserById?.id, params));
      } else {
        setError("name", {
          type: "manual",
          message: "This Field is required",
        });
      }
    }

    if (
      fundraiserById?.status === fundraiserStatuses.COMPETED ||
      fundraiserById?.status === fundraiserStatuses.LIVE
    ) {
      dispatch(fundraiserActions.editFundraiser(fundraiserById?.id, params));
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DetailPageWrapper
          className={isMemory ? "detail-page-wrapper-memory" : ""}
          head={
            <>
              <p className="detail-page-title">Basic Information</p>
            </>
          }
          body={
            <>
              <div className="edit-fundraiser-inputs-box">
                <InputMain
                  className="memory-input"
                  name="name"
                  type="text"
                  control={control}
                  placeholder="Write Event Name"
                  labelHeader="Event Name"
                  rules={{
                    required: "Event Name is required",
                    validate: {
                      trimmed: (value) =>
                        value.trim() !== "" ||
                        "Event Name cannot be empty or just spaces",
                    },
                  }}
                />

                <InputMain
                  className="memory-input"
                  name="firstName"
                  type="text"
                  control={control}
                  placeholder="Write First Name"
                  labelHeader="First Name"
                  rules={{
                    required: "First name is required",
                    validate: {
                      trimmed: (value) =>
                        value.trim() !== "" ||
                        "First name cannot be empty or just spaces",
                    },
                  }}
                />
              </div>

              <div className="edit-fundraiser-inputs-desktop-box">
                <InputMain
                  className="memory-input"
                  name="lastName"
                  type="text"
                  control={control}
                  placeholder="Write Last Name"
                  labelHeader="Last Name"
                  rules={{
                    required: "Last name is required",
                    validate: {
                      trimmed: (value) =>
                        value.trim() !== "" ||
                        "Last name cannot be empty or just spaces",
                    },
                  }}
                />

                <InputMain
                  className="memory-input"
                  name="middleName"
                  type="text"
                  control={control}
                  placeholder="Write Middle Name"
                  labelHeader="Middle Name"
                  rules={{
                    required: "Middle name is required",
                    validate: {
                      trimmed: (value) =>
                        value.trim() !== "" ||
                        "Middle name cannot be empty or just spaces",
                    },
                  }}
                />

                <InputMain
                  className="memory-input"
                  name="relationship"
                  type="text"
                  control={control}
                  placeholder="ex. son"
                  labelHeader={
                    isMemory
                      ? "Relationship with Deceased"
                      : "Relationship with jubilarian"
                  }
                  rules={{
                    required: isMemory
                      ? "Relationship with Deceased is required"
                      : false,
                    validate: {
                      trimmed: (value) =>
                        value.trim() !== "" ||
                        "Relationship with Deceased cannot be empty or just spaces",
                    },
                  }}
                />
              </div>

              <div className="edit-fundraiser-inputs-box">
                {/*<SelectRadio*/}
                {/*  list={fundraiserCategories}*/}
                {/*  defaultValue={*/}
                {/*    fundraiserById?.category*/}
                {/*      ? fundraiserCategories?.find(*/}
                {/*          (i) => i.id === fundraiserById?.category*/}
                {/*        )*/}
                {/*      : null*/}
                {/*  }*/}
                {/*  renderLabel={(option) => option?.title}*/}
                {/*  name="category"*/}
                {/*  type="text"*/}
                {/*  control={control}*/}
                {/*  placeholder="Select Type"*/}
                {/*  labelHeader="Type"*/}
                {/*/>*/}
                {/*<InputMain*/}
                {/*  name="endDate"*/}
                {/*  type="date"*/}
                {/*  control={control}*/}
                {/*  placeholder="Write Fundraiser Name"*/}
                {/*  labelHeader="End Date"*/}
                {/*  min={new Date()}*/}
                {/*/>*/}
              </div>

              <div className="edit-fundraiser-inputs-box">
                <DateInput
                  className="memory-input"
                  name="endDate"
                  control={control}
                  placeholder="Write Event Name"
                  label="Event End Date"
                  min={new Date()}
                  rules={{
                    required: "End Date is required",
                  }}
                />
                {fundraiserById?.celebrationDate && (
                  <DateInput
                    className="memory-input"
                    name="celebrationDate"
                    control={control}
                    placeholder="Write Date of Celebration"
                    label="Date of Celebration"
                    rules={{
                      required: "Date of celebration is required",
                    }}
                  />
                )}

                {fundraiserById?.birthDate && (
                  <DateInput
                    className="memory-input"
                    name="birthDate"
                    label="Date of Birth"
                    placeholder="Choose Date"
                    max={todayDate}
                    control={control}
                    rules={{
                      required: "Date of birth is required",
                    }}
                  />
                )}

                {fundraiserById?.deathDate && (
                  <DateInput
                    className="memory-input"
                    name="deathDate"
                    control={control}
                    placeholder="Choose Date"
                    label="Date of Died"
                    min={watch("birthDate")}
                    max={todayDate}
                    rules={{
                      required: "Date of beath is required",
                    }}
                  />
                )}
              </div>

              <CheckboxesTags
                classNameWrapper="memory-autocomplete"
                list={charities?.charities || []}
                renderLabel={(option) => option?.name}
                defaultValue={defaultCharities}
                name="charities"
                type="text"
                control={control}
                placeholder="Select Charities"
                labelHeader="Assigned Charities"
                disabled={true}
              />

              <TextAreaMain
                classNameWrapper="memory-textarea"
                name="description"
                type="text"
                control={control}
                placeholder="Write Description..."
                labelHeader="Description"
                rules={{
                  required: "Description is required",
                }}
              />
            </>
          }
          footer={
            <div className="edit-fundraiser-save-cancel">
              <ModalActions
                closeFunction={handleReset}
                defaultStyle={true}
                type="submit"
                loadAction={EDIT_FUNDRAISER_ACTION}
                saveTitle="Save"
                isMemory={isMemory}
              />
            </div>
          }
        />
      </form>
    </div>
  );
};
