import * as React from "react";
import Popper from "@mui/material/Popper";
import { Svg } from "assets";
import { Box } from "@mui/material";
import { Notify } from "utils";

export function ShareBtn({
  background,
  fill,
  buttonText,
  textColor,
  classNameButton,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const copyLink = () => {
    navigator.clipboard.writeText("Copy this text to clipboard");
    Notify("Link copied");
  };

  return (
    <div className="share-popper-wrapper">
      <button
        style={{ backgroundColor: background }}
        aria-describedby={id}
        onClick={handleClick}
        className={`share-btn ${classNameButton ? classNameButton : ""}`}
      >
        <Svg.Share fill={fill} />
        {buttonText && <p style={{ color: textColor }}>{buttonText}</p>}
      </button>

      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className={"share-select-paper-box"}
        style={{ position: "absolute", zIndex: 9 }}
      >
        <Box>
          <div className="share-popper-box">
            <p className="share-title">Share</p>
            <div className="share-social-wrapper">
              <div className="share-social-box">
                <div className="social-box">
                  <Svg.TwitterShare />
                  <p>Twitter</p>
                </div>
                <div className="social-box">
                  <Svg.LinkedinShare />
                  <p>Linkedin</p>
                </div>
              </div>
              <div className="share-social-box">
                <div className="social-box">
                  <Svg.FacebookShare />
                  <p>Facebook</p>
                </div>
                <div className="social-box">
                  <Svg.InstagramShare />
                  <p>Instagram</p>
                </div>
              </div>
            </div>

            <div className="share-and-copy-link">
              <div className="share-link">
                <input
                  value={
                    "https://dribbble.com/shots/24200104-FloSlo-LOGO-redesign-client-s-work-F-S-FLOW"
                  }
                  type="text"
                  readOnly={true}
                />
              </div>
              <button onClick={copyLink} className="copy-link-btn">
                <Svg.CopyLink />
              </button>
            </div>
          </div>
        </Box>
      </Popper>

      {open && <div className="beck-drop" onClick={() => setAnchorEl(null)} />}
    </div>
  );
}
