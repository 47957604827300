import { GET_USERS_SUCCESS } from "./user.types";

const initialState = {
  users: null,
  pmtAccount: null,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
      };

    default:
      return state;
  }
};
