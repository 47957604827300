import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  DateRow,
  DetailPageWrapper,
  PageLoader,
  PhoneRow,
  TitleValue,
} from "components";
import { UpdateAccountDetails } from "fragments";
import { NoConnectedBank } from "./noConnectedBank";
import { Requirements } from "./requirements";
import { bankAccountActions, httpRequestsOnSuccessActions } from "store";
import { GET_MY_BANK } from "./constants";
import { FindLoad, FindSuccess, useModal } from "utils";
import { Svg } from "assets";
import { ConnectBankAccount } from "./modals";
import { DELETE_CONNECT_STRIPE } from "./modals/connectBankAccount/constants";
import { BankAccount } from "./modals/connectBankAccount";

export const CharityBankAccount = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { openModal, close } = useModal();
  const { charityById, bankAccount } = useSelector((state) => ({
    charityById: state.charity.charityById,
    bankAccount: state.bankAccount.bankAccount,
  }));
  const loader = FindLoad(GET_MY_BANK);
  const successDeleting = FindSuccess(DELETE_CONNECT_STRIPE);

  useEffect(() => {
    if (successDeleting) {
      close();
      dispatch(bankAccountActions.getMyBank(charityById?.pmtAccount?._id));
      dispatch(
        httpRequestsOnSuccessActions.removeSuccess(DELETE_CONNECT_STRIPE)
      );
    }
  }, [successDeleting]);

  useEffect(() => {
    if (charityById?.pmtAccount?._id) {
      dispatch(bankAccountActions.getMyBank(charityById?.pmtAccount?._id));
    }
  }, [dispatch, charityById?.pmtAccount?._id]);

  if (loader) {
    return <PageLoader />;
  }

  return (
    <div className="charity-bank-account-wrapper">
      {bankAccount?.accountId && charityById?.pmtAccount?._id ? (
        <>
          <DetailPageWrapper
            head={
              <>
                <p className="detail-page-title">Bank Account</p>
                <div className="fundraiser-detail-actions desktop">
                  <button
                    className="fundraiser-detail-refresh_button"
                    onClick={() =>
                      openModal(
                        <UpdateAccountDetails
                          id={charityById?.pmtAccount?._id}
                        />
                      )
                    }
                  >
                    <Svg.Refresh3Svg /> Update Account Details
                  </button>
                </div>
              </>
            }
            body={
              <div className="nonprofit-details">
                <div className="nonprofit-details-wrapper">
                  <div className="nonprofit-details-box">
                    <p className="nonprofit-details_title">Nonprofit Details</p>
                    <TitleValue
                      title="Company name"
                      value={bankAccount?.nonProfit?.name}
                    />
                    <TitleValue
                      title="Nonprofit Name"
                      value={bankAccount?.nonProfit?.businessName}
                    />
                    <TitleValue
                      title="Nonprofit Email"
                      value={bankAccount?.nonProfit?.businessEmail}
                    />

                    <TitleValue
                      title="Nonprofit Phone"
                      value={
                        <PhoneRow
                          phone={bankAccount?.nonProfit?.businessPhone}
                        />
                      }
                    />

                    <TitleValue
                      title="Nonprofit Address"
                      value={
                        bankAccount?.nonProfit?.address.includes("null")
                          ? "-"
                          : bankAccount?.nonProfit?.address
                      }
                    />

                    <TitleValue
                      title="Tax ID"
                      value={bankAccount?.nonProfit?.taxID}
                    />
                  </div>

                  <div className="nonprofit-details-box">
                    <p className="nonprofit-details_title">Personal Details</p>
                    <TitleValue
                      title="First Name"
                      value={bankAccount?.nonProfit?.person?.firstName}
                    />
                    <TitleValue
                      title="Last Name"
                      value={bankAccount?.nonProfit?.person?.lastName}
                    />
                    <TitleValue
                      title="Date of Birthday"
                      value={
                        <DateRow
                          date={`${bankAccount?.nonProfit?.person?.dob?.month}.${bankAccount?.nonProfit?.person?.dob?.day}.${bankAccount?.nonProfit?.person?.dob?.year}`}
                        />
                      }
                    />
                    <TitleValue
                      title="Phone Number"
                      value={
                        <PhoneRow
                          phone={bankAccount?.nonProfit?.person?.phone}
                        />
                      }
                    />

                    <TitleValue
                      title=" Address"
                      value={
                        bankAccount?.nonProfit?.person?.address.includes("null")
                          ? "-"
                          : bankAccount?.nonProfit?.person?.address
                      }
                    />
                  </div>
                </div>

                <div className="fundraiser-detail-actions mobile">
                  <button
                    className="fundraiser-detail-refresh_button"
                    onClick={() =>
                      openModal(
                        <UpdateAccountDetails
                          id={charityById?.pmtAccount?._id}
                        />
                      )
                    }
                  >
                    <Svg.Refresh3Svg /> Update Account Details
                  </button>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  {bankAccount?.requirements.map((item, i) => (
                    <Requirements errorMessage={item} key={i} />
                  ))}
                </div>
              </div>
            }
          />

          <div>
            <DetailPageWrapper
              body={
                <div>
                  <p className="detail-page-title">Connected Bank Account</p>
                  <div className="connected-bank-wrapper">
                    <div className="bank-information-box">
                      <Svg.ConnectedBank />
                      <div>
                        <p className="bank-name">
                          {bankAccount?.bankAccount?.bankName || "-"}
                        </p>
                        <p className="bank-current-account">
                          Bank Current Account{" "}
                        </p>
                        <p className="bank-routing">
                          **** ****{" "}
                          {bankAccount?.bankAccount?.routingNumber
                            ?.toString()
                            ?.slice(-4)}
                        </p>
                      </div>
                    </div>

                    <div className="connect_bank_buttons">
                      {/*<button className="connect_bank_delete-button">*/}
                      {/*  <Svg.RedTrashFill />*/}
                      {/*  Delete*/}
                      {/*</button>*/}

                      <button
                        className="connect_bank_update-button"
                        onClick={() =>
                          openModal(
                            <BankAccount
                              info={
                                bankAccount?.bankAccount
                                  ? {
                                      routingNumber:
                                        bankAccount?.bankAccount?.routingNumber,
                                    }
                                  : null
                              }
                              id={params?.id}
                            />,
                            {
                              padding: "noPaddings",
                              noShowCloseBrn: true,
                            }
                          )
                        }
                      >
                        <Svg.Refresh3Svg />
                        {bankAccount?.bankAccount ? "Update" : "Create"}
                      </button>
                    </div>
                  </div>
                </div>
              }
            />
          </div>
        </>
      ) : (
        <NoConnectedBank
          onClick={() =>
            openModal(<ConnectBankAccount item={charityById} />, {
              padding: "noPaddings",
              noShowCloseBrn: true,
            })
          }
        />
      )}
    </div>
  );
};
