import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Svg } from "assets";
import { authActions, httpRequestsOnSuccessActions } from "store";
import { ButtonMain, InputMain } from "components";
import { AuthModalHeaders, IfRemember } from "./fragments";
import { FindError, FindSuccess, Notify, useModal } from "utils";

const ACTION_TYPE = "RESET_PASSWORD";
export const ResetPassword = ({ token }) => {
  const [showPasswords, setShowPassword] = useState({
    newPassword: false,
    confirmation: false,
  });
  const dispatch = useDispatch();
  const success = FindSuccess(ACTION_TYPE);
  const backError = FindError(ACTION_TYPE);
  const { close } = useModal();

  const { handleSubmit, control, setError } = useForm({
    defaultValues: {
      newPassword: "",
      confirmation: "",
    },
  });

  useEffect(() => {
    if (backError?.error) {
      Notify(backError?.error);
      close();
    }
  }, [backError]);

  useEffect(() => {
    if (success) {
      close();
      dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
    }
  }, [success]);
  const showingPassword = (path) => {
    setShowPassword((prev) => ({
      ...prev,
      [path]: !prev[path],
    }));
  };

  const onSubmit = (data) => {
    const { newPassword, confirmation } = data;

    if (newPassword === confirmation) {
      const params = {
        token: token,
        newPassword,
        confirmation,
      };
      dispatch(authActions.resetPassword(params));
    } else {
      setError("confirmation", {
        type: "required",
        message: "Passwords do not match",
      });
    }
  };

  return (
    <div className="auth-modals">
      <AuthModalHeaders title={"Reset Password"} icon={<Svg.Recover />} />
      <form className="form-box" onSubmit={handleSubmit(onSubmit)}>
        <InputMain
          name="newPassword"
          type="password"
          showPassword={showPasswords.newPassword}
          control={control}
          placeholder="Password"
          labelHeader="New Password"
          rules={{
            required: "This Field is required",
          }}
          onClickIconEnd={() => showingPassword("newPassword")}
          startAdornment={<Svg.Lock />}
        />

        <InputMain
          name="confirmation"
          type="password"
          showPassword={showPasswords.confirmation}
          control={control}
          placeholder="Password"
          labelHeader="Confirm Password"
          rules={{
            required: "This Field is required",
          }}
          onClickIconEnd={() => showingPassword("confirmation")}
          startAdornment={<Svg.Lock />}
        />
        <ButtonMain actionTye={ACTION_TYPE} text="Reset" type="submit" />
        <IfRemember />
      </form>
    </div>
  );
};
