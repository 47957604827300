import { Svg } from "assets";
import { ButtonMain } from "../buttons";
import React from "react";
import { useLocation, useNavigate } from "react-router";

export const PaymentSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const info = location?.state;
  const checkMemory = info?.isMemory;

  const colorStyles = {
    color: checkMemory ? "white" : "#000",
  };

  return (
    <div className="payment-success-wrapper">
      <div
        className="payment-success-box"
        style={{ background: checkMemory ? "#1C212D" : "white" }}
      >
        <Svg.PaymentSuccess />
        <div>
          <p style={colorStyles} className="payment-success-title">
            Payment Successful!
          </p>
          <p style={colorStyles} className="payment-success-desc">
            Your donation has been successfully done! Thank you for your
            generous contribution.Together, we are creating a positive impact.{" "}
          </p>
        </div>
        <ButtonMain
          buttonMainStyles={{
            background: checkMemory ? "white" : "#34A853",
          }}
          textStyles={{
            color: checkMemory ? "#000" : "white",
          }}
          text="Go to Homepage"
          onClick={() => navigate("/")}
        />
      </div>
    </div>
  );
};
