import React from "react";
import { ButtonMain } from "components";
import { Svg } from "assets";

export const CharityCreated = ({ toggleShowing }) => {
  return (
    <div className="in-celebration-box">
      <div className="in-celebration-content">
        <div className="container">
          <div className="in-celebration-form-wrapper see-your-event-wrapper charity-created-wrapper">
            <Svg.PaperPlaneCelebrationSvg />
            <div className="see-your-event-title_desc">
              <p className="see-your-event-title">
                Your charity have been sent for review!
              </p>
              <p
                className="see-your-event-desc"
                style={{ fontWeight: 700, marginBottom: "4px" }}
              >
                The Process may take up to two weeks.
              </p>
              <p className="see-your-event-desc">
                If you prefer not to wait, you can Go back and choose a charity
                from the list that provides our system.
              </p>
            </div>

            <div className="charity-created-buttons_block">
              <ButtonMain
                classNameWrapper="celebration-button"
                text={"Done"}
                type="button"
                onClick={() =>
                  toggleShowing((prev) => ({
                    ...prev,
                    showCharityDetails: false,
                    successCratingEvent: false,
                    successCratingCharity: false,
                  }))
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
