import React, { forwardRef } from "react";
import { Controller } from "react-hook-form";

export const TextAreaMain = forwardRef(
  (
    {
      control,
      name,
      classNameWrapper,
      className,
      rules,
      placeholder,
      labelHeader,
      ...anotherProps
    },
    ref
  ) => {
    return (
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field, formState: { errors } }) => (
          <div
            className={`main-textarea-wrapper ${
              classNameWrapper ? classNameWrapper : ""
            }`}
          >
            {labelHeader && (
              <p
                className={`textarea-main-label-header ${
                  errors[field?.name]?.message ? "errorLabel" : ""
                }`}
              >
                {labelHeader}
                {rules?.required && (
                  <span className="textarea-main-label-header-required">*</span>
                )}
              </p>
            )}
            <textarea
              ref={ref}
              className={`textarea ${className ? className : ""}
                             ${
                               errors[field?.name]?.message
                                 ? "textarea-error"
                                 : ""
                             }`}
              {...field}
              {...anotherProps}
              value={field.value}
              placeholder={placeholder}
            />

            <p className="error-message">{errors[field?.name]?.message}</p>
          </div>
        )}
      />
    );
  }
);

TextAreaMain.displayName = "TextAreaMain";
