import React from "react";
import { LogIn } from "../logIn";
import { useModal } from "utils";

export const IfRemember = ({ onLoginAccount }) => {
  const { openModal } = useModal();

  const openLogIn = () => {
    onLoginAccount ? onLoginAccount() : openModal(<LogIn />);
  };

  return (
    <div className="reset-or-log" style={{ margin: "24px 0 0 0" }}>
      <p> If you remember you can </p>
      <button type="button" onClick={openLogIn} className="forgot-btn">
        Log In
      </button>
    </div>
  );
};
