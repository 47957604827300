import { NavLink } from "react-router-dom";
import { Svg } from "assets";

export const Breadcrumb = ({ list, onClick }) => {
  return (
    <div className="breadcrumb-wrapper">
      {list?.map((i, j) =>
        i?.to ? (
          <div key={j} className="breadcrumb-link-icon">
            <NavLink to={i?.to} onClick={onClick ? () => onClick(i) : () => {}}>
              {i?.title}
            </NavLink>
            <Svg.ChevronRight />
          </div>
        ) : (
          <p key={j}>{i?.title}</p>
        )
      )}
    </div>
  );
};
