import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";

export const CheckboxMain = ({
  checked,
  onChange,
  className,
  label,
  styles,
}) => {
  return (
    <div className={`checkbox-box ${className ? className : ""}`}>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={onChange}
            sx={{
              color: styles?.color,
              "&.Mui-checked": {
                color: styles?.checkedColor,
              },
              ...styles,
            }}
          />
        }
        label={label}
        sx={{
          "& .MuiFormControlLabel-label": {
            color: styles?.labelColor,
          },
        }}
      />
    </div>
  );
};
