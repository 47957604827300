import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import moment from "moment";
import {
  AddressInput,
  ButtonMain,
  CancelBtn,
  DateInput,
  InputMain,
  PhoneInput,
} from "components";
import { httpRequestsOnErrorsActions } from "store";
import { CONNECT_NON_PROFIT, tabEnums } from "./constants";
import { EmailValidator, FindError, useModal } from "utils";

export const PersonalDetails = ({ handleSetInfo }) => {
  const dispatch = useDispatch();
  const { close } = useModal();
  const findError = FindError(CONNECT_NON_PROFIT);
  const { handleSubmit, control, setError } = useForm({});

  const onSubmit = (data) => {
    handleSetInfo(data, tabEnums.PERSONAL_DETAILS, tabEnums.PERSONAL_DETAILS);
  };

  useEffect(() => {
    return () => {
      dispatch(httpRequestsOnErrorsActions.removeError(CONNECT_NON_PROFIT));
    };
  }, []);

  useEffect(() => {
    if (findError?.error === "Invalid address format or location not found.") {
      setError("address", {
        message: "Invalid address format or location not found.",
      });
    }

    if (findError?.error === "Something went wrong while converting address") {
      setError("address", {
        message: "Something went wrong while converting address.",
      });

      dispatch(httpRequestsOnErrorsActions.removeError(CONNECT_NON_PROFIT));
    }
  }, [findError]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="settings-connect-modal-wrapper"
    >
      <div className="settings-connect-modal-body">
        <InputMain
          name="firstName"
          type="text"
          control={control}
          placeholder="Enter your first name as on ID"
          labelHeader="First Name"
          rules={{
            required: "This Field is required",
            validate: {
              trimmed: (value) =>
                value.trim() !== "" ||
                "First name cannot be empty or just spaces",
            },
          }}
        />
        <InputMain
          name="lastName"
          type="text"
          control={control}
          placeholder="Enter your last name"
          labelHeader="Last Name"
          rules={{
            required: "This Field is required",
            validate: {
              trimmed: (value) =>
                value.trim() !== "" ||
                "First name cannot be empty or just spaces",
            },
          }}
        />
        <DateInput
          name="dateOfBirth"
          control={control}
          placeholder="Provide your nonprofit email address"
          label="Date of Birth"
          rules={{
            required: "Date of Birth is required",
            validate: (value) => {
              const selectedDate = moment(value);
              const currentDate = moment();
              const age = currentDate.diff(selectedDate, "years");
              return (
                age >= 13 || "You must be at least 13 years old to use Stripes"
              );
            },
          }}
        />
        <InputMain
          name="email"
          type="text"
          control={control}
          placeholder="Enter your personal email address"
          labelHeader="Email Address"
          rules={{
            required: "Email Address is required",
            pattern: {
              value: EmailValidator,
              message: "Must be valid email",
            },
          }}
        />
        <PhoneInput
          placeholder={"Enter your contact number"}
          name="phone"
          control={control}
          labelHeader="Phone Number"
          rules={{
            required: "Phone Number is required",
            minLength: {
              value: 10,
              message: "Please enter a valid 10 digit number.",
            },
            validate: (value) => {
              return (
                value?.replace(/[^0-9]/g, "").length === 10 ||
                "Please enter a valid 10 digit number."
              );
            },
          }}
        />

        <AddressInput
          className="celebration-input"
          name="address"
          control={control}
          labelHeader="Address"
          placeholder="Address"
          rules={{
            required: "Address is required",
          }}
        />

        <InputMain
          name="idNumber"
          type="text"
          control={control}
          placeholder="Enter government-issued ID number"
          labelHeader="ID Number"
          maxLength={9}
          rules={{
            required: "ID Number is required",
            minLength: {
              value: 9,
              message: "ID numbers must be 9 characters long",
            },
            maxLength: {
              value: 9,
              message: "ID numbers must be 9 characters long",
            },
          }}
        />
        <InputMain
          name="ssnLast4"
          type="text"
          control={control}
          placeholder="Enter last 4 digits of your SSN"
          labelHeader="Last 4 Didits of SSN"
          maxLength={4}
          rules={{
            required: "This Field is required",
            minLength: {
              value: 4,
              message: "SSN last 4 numbers must be 4 characters long",
            },
            maxLength: {
              value: 4,
              message: "SSN last 4 numbers must be 4 characters long",
            },
          }}
        />

        {findError?.error &&
          findError?.error !==
            "Invalid address format or location not found." &&
          findError?.error !==
            "Something went wrong while converting address" && (
            <p className="error-message" style={{ fontSize: 14 }}>
              {findError?.error}
            </p>
          )}
      </div>

      <div className="settings-connect-modal-footer">
        <CancelBtn
          text={"Cancel"}
          closeFunction={() => {
            close();
          }}
        />
        <ButtonMain
          actionTye={CONNECT_NON_PROFIT}
          type={"submit"}
          text={"Next"}
        />
      </div>
    </form>
  );
};
